import { useEffect, useState } from 'react';
import {  useTranslation } from 'react-i18next';
import  {MwSelectorProduct,MwInputText, MwTextArea, MwSelector} from '../ui'
import InputQty from '../ui/InputQty';
import {TiDeleteOutline} from 'react-icons/ti' 
import {LangArrayToObjKeyValue} from '../../utils/global.js';
import { useCurrent, useFetch, useInvoice } from '../../hooks';
import {amountFormat} from '../../utils/invoceHelper.js'
import { BiMessageSquareDetail } from "react-icons/bi";
import DraggableFormRow from './../../utils/utils/DraggableFormRow';
import DraggableLabel from './../../utils/utils/DraggableLabel.jsx';

const InvFormListItem = ({
    index,
    moveRow,
    reloadProductList,
    showModalProductHandle,
    productList,
    setProductList,
    formErrors,
    SETTING_PRICE_INCLUDE_VAT,
    SETTING_INV_QTY_DAYS,
    SETTING_INV_UOM_SHOW,
    SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION,
    priceListItems ,
    taxesData, // أضف هذا
    taxesLoading, // أضف هذا
    setVat,
    invoiceSettings,
    taxesList, setTaxesList

    }) => {
    const {currentLangId} = useCurrent();
    const [t,i18n] = useTranslation('global')
    const [pricelist,setPricelist]= useState({}); 
     const currentLanguage = i18n.language;
    const {SETTING_INVOICE, toFloat } = useInvoice();
    const [isProductDesc,setIsProductDesc] = useState(false);
    const isDisabledPriceUnit =false;
    const [haveUom,setHaveUom] = useState();
    const {data:uomData,loading:uomLoading,fetchData} = useFetch(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/uom/${haveUom}`,false);
    const [uomList,setUomList] = useState([]);
    console.log("uomListuomList",uomList)
    const [uomSaleSelected,setUomSaleSelected] = useState();
    const [labelDescription, setLabelDescription] = useState('');
    const { currentTenantId, currentCompanyId, currentBranchId } = useCurrent();
    const [selectedTax, setSelectedTax] = useState();
console.log("taxesList",taxesList)
    console.log('productListfrominvitems',productList)
    console.log("osamaSETTING_INVOICE.INV_VAT_PERCENT",SETTING_INVOICE.INV_VAT_PERCENT)


    useEffect(() => {
        if(uomSaleSelected){
            console.log('uomSaleSelected',index,uomSaleSelected)
            if(!!productList[index]){
                productList[index].uom = uomSaleSelected.value;
                setBalanceRow(index)
            }
        }
    },[uomSaleSelected]);
    useEffect(() =>{
        if(haveUom){
            fetchData()
        }
    },[haveUom])
    useEffect(() => {
        console.log('setUomList',uomData);
                if(uomData  ){
                    let itemList = [];
                    uomData?.data?.uom_master?.uom_details.map((el)=>itemList?.push({label:el?.short_name?.trim(),value:el?.id,lang:currentLangId,data:el}));
                    setUomList(itemList)
                    console.log('setUomList',itemList)
                    
                }
            },[uomData]);
    const descItem = () => {
        setIsProductDesc(!isProductDesc);
    }
  

    const deleteItem = () => {
        console.log('Deleting item at index:', index);
        
        const newList = [...productList];
        
        newList.splice(index, 1);
        
        const updatedList = newList.map((item, i) => ({
            ...item,
            index: i
        }));
        
        console.log('Updated list after deletion:', updatedList);
        setProductList(updatedList);
    }

    const onChangeProduct = (value) =>  {
        console.log('onChangeProduct---', value);
        
        // Check if this product has a special price in the price list
        let productPrice = null;
        let productId = value?._id;
        
        if (priceListItems && priceListItems.length > 0 && productId) {
            // Trim whitespace from product_id in price list items for comparison
            const priceListItem = priceListItems.find(
                item => item.product_id.trim() === productId
            );
            
            if (priceListItem) {
                // Use the price from the price list
                productPrice = parseFloat(priceListItem.product_sale_price);
                console.log('Found price in price list:', productPrice);
            }
        }
        
        // Continue with existing functionality
        value?.uomSale && setUomSaleSelected({
            label: value?.uomSale?.short_name?.trim(),
            value: value?.uomSale.id,
            lang: currentLangId,
            data: value?.uomSale
        });
        
        if (!value?._id) {
            productList[index] = {
                index,
                productId: '0',
                productName: value,
                productDesc: '',
                qty: 1,
                qtyDays: 1,
                price: 0,
                priceUnit: 0,
                productNetTotal: 0,
                productVat: 0,
                productNetTotalWithVat: 0,
                productDiscount: "0",
            };
            setPricelist({...pricelist});
            setBalanceRow(index);
            return;
        }
        
        // Prioritize pricing in this order:
        // 1. Price list price (if available)
        // 2. Original product price
        // 3. Default to 0
        const newPrice = productPrice !== null 
            ? (SETTING_PRICE_INCLUDE_VAT === 'true' ? (productPrice * 100/115) : productPrice)
            : (SETTING_PRICE_INCLUDE_VAT === 'true' ? (value?.defaultPrice || value?.price * 100/115) : (value?.defaultPrice || value?.price));
        
        pricelist[`${value?._id}`] = newPrice;
        setPricelist({...pricelist});
        
        productList[index] = {
            index,
            productId: value?._id,
            productName: LangArrayToObjKeyValue(value?.productName)?.[currentLangId] || value?.productName[1]?.text || value?.productName[0]?.text,
            productDesc: LangArrayToObjKeyValue(value?.productName)?.[currentLangId] === value?.productName[0]?.text ? value?.productName[1]?.text : value?.productName[0]?.text,
            qty: 1,
            qtyDays: value?.qtyDays || 1,
            price: newPrice,
            priceUnit: newPrice,
            originalPriceUnit: value?.price,  // Store the original price
            uomTemplateId: value?.uomTemplate?.id,
            productDiscount: "0",
        };
        
        setUomList([]);
        setHaveUom(value?.uomTemplate?.id);
        setProductList([...productList]);
        setBalanceRow(index);
        console.log('productList =>', productList);
    };

    const incressQty = (index) => {
        if(!!productList[index] && parseInt(productList[index].qty) > 0){
            const newValue = parseInt(productList[index].qty)+ 1
            productList[index].qty = newValue;
            onChangeQty(newValue,index)
            setBalanceRow(index)
        }
    }
    const incressQtyDays = (index) => {
        if(!!productList[index] && parseInt(productList[index].qtyDays) > 0){
            const newValue = parseInt(productList[index].qtyDays)+ 1
            productList[index].qtyDays = newValue;
            onChangeQtyDays(newValue,index)
            setBalanceRow(index)
        }
        
    }
    const decressQty = (index) => {
        if(!!productList[index] && parseInt(productList[index].qty) > 1){
            const newValue = parseInt(productList[index].qty)- 1
            productList[index].qty = newValue;
            onChangeQty(newValue,index)
          //  setBalanceRow(index)
        }
        
    }
    const decressQtyDays = (index) => {
        if(!!productList[index] && parseInt(productList[index].qtyDays) > 1){
            const newValue = parseInt(productList[index].qtyDays)- 1
            productList[index].qtyDays = newValue;
            onChangeQtyDays(newValue,index)
          //  setBalanceRow(index)
        }
        
    }
    const onChangeQty = (value, index) => {
        const numValue = parseInt(value) || 0;
        
        productList[index].qty = numValue;
        
        if (productList[index]?.productId === '0') {
            // في حالة الإدخال اليدوي، تأكد من وجود سعر الوحدة
            const priceUnit = productList[index].priceUnit || 0;
            productList[index].price = numValue * priceUnit * (productList[index].qtyDays || 1);
            // حساب باقي القيم
            setBalanceRow(index);
        } else {
            const priceOne = productList[index].priceUnit;
            if (priceOne) {
                productList[index].price = numValue * priceOne * (productList[index].qtyDays || 1);
            }
            setBalanceRow(index);
        }
        
        setProductList([...productList]);
    }
    const onChangeQtyDays = (value, index) => {
        const numValue = parseInt(value) || 0;
        
        productList[index].qtyDays = numValue;
        
        if (productList[index]?.productId === '0') {
            // في حالة الإدخال اليدوي، تأكد من وجود سعر الوحدة
            const priceUnit = productList[index].priceUnit || 0;
            productList[index].price = (productList[index].qty || 1) * priceUnit * numValue;
            // حساب باقي القيم
            setBalanceRow(index);
        } else {
            const priceOne = productList[index].priceUnit;
            if (priceOne) {
                productList[index].price = (productList[index].qty || 1) * priceOne * numValue;
            }
            setBalanceRow(index);
        }
        
        setProductList([...productList]);
    }

    const onChangePrice = (value,index) =>{
        productList[index].price = value ;
        setProductList([...productList]); 
    }
    const onChangeProductDesc = (value,index) =>{
        productList[index].productDesc = value ;
        setProductList([...productList]); 
    }
    const onChangeSection = (value,index) =>{
        productList[index].productName = value ;
        setProductList([...productList]); 
    }

    const onChangePriceUnit = (value, index) => {
        const numValue = parseFloat(value) || 0;
        productList[index].priceUnit = numValue;
        
        // حساب السعر الإجمالي
        if (productList[index].qty) {
            productList[index].price = productList[index].qty * numValue * (productList[index].qtyDays || 1);
        }
        
        // تحديث باقي الحسابات
        setBalanceRow(index);
        setProductList([...productList]);
    }
    const onChangeProductDiscount = (value, index) => {
          let inputValue = value.trim();
        
         if (inputValue?.includes('%')) {
             const percentValue = parseFloat(inputValue.replace('%', '')) || 0;
            
             const discountAmount = toFloat(toFloat(productList[index].price, false) * percentValue / 100);
            
            productList[index].productDiscount = discountAmount.toString();
        } 
        else {
            productList[index].productDiscount = inputValue;
        }
        
         const numValue = parseFloat(productList[index].productDiscount);
        if (!isNaN(numValue) && numValue > parseFloat(productList[index].price || 0)) {
            productList[index].productDiscount = productList[index].price.toString();
        }
        
         if (productList[index].price) {
            setBalanceRow(index);
        }
        
        setProductList([...productList]);
    }
    // const setBalanceRow = (index) => {

    //     if( (productList[index].productNetTotal || 0) > productList[index].price ){
    //         productList[index].productDiscount = 0 ;
    //     }
    //     if(productList[index]?.productDiscount && productList[index]?.productDiscount?.includes('%')){
    //         productList[index].productNetTotal =  toFloat(productList[index].price,false) -( toFloat(productList[index].price,false) * productList[index].productDiscount.replace('%', '') /100 );
            
    //     }else{
    //         productList[index].productNetTotal = productList[index].price - (productList[index].productDiscount || 0);
    //     }

    //     const taxRate = productList[index].tax_rate || (selectedTax?.rate || SETTING_INVOICE.INV_VAT_PERCENT);
    //     productList[index].productVat = (productList[index].productNetTotal || 0) * taxRate  /100
    //     productList[index].productNetTotalWithVat = productList[index].productVat + (productList[index].productNetTotal || 0) ;
    //   // Calculate total VAT by summing VAT of all rows
    //   const totalVat = productList.reduce((sum, product) => sum + (product.productVat || 0), 0);
    
    //   // Ensure setVat is called with the total VAT
    //   setVat(toFloat(totalVat, false));
    //     setProductList([...productList]); 
    //     console.log('item numbers => ',productList[index].productNetTotal,productList[index].productVat,productList[index].productNetTotalWithVat)
    // }

    useEffect(()=>{
        if(productList){
            
        }
    },[productList]);
  

    useEffect(()=>{
        console.log(SETTING_INVOICE)
        
    },[]);

    // add vat 
//    useEffect(() => {
//     if (taxesData?.data) {
       
//       const formattedTaxes = taxesData.data.map(tax => ({
//         label: currentLanguage === 'ar' 
//         ? `${tax.tax_name_ar || tax.tax_name_en || ''} ${tax.tax_value}%`
//         : `${tax.tax_name_en || tax.tax_name_ar || ''} ${tax.tax_value}%`,
//         value: tax.id.toString(),
//         rate: tax.tax_value
//       }));
      
//       setTaxesList(formattedTaxes);
  
//        const defaultTax = {
//         label: `${invoiceSettings.INV_VAT_PERCENT}%`,
//         value: '0',
//         rate: invoiceSettings.INV_VAT_PERCENT
//       };
      
//       if (productList[index] && !productList[index].tax_id) {
//          productList[index].tax_id = '0';
//         productList[index].tax_rate = invoiceSettings.INV_VAT_PERCENT;
        
//         setSelectedTax(defaultTax);
        
//          productList[index].productVat = (productList[index].productNetTotal || 0) * invoiceSettings.INV_VAT_PERCENT / 100;
//         productList[index].productNetTotalWithVat = 
//           productList[index].productVat + (productList[index].productNetTotal || 0);
        
//         setProductList([...productList]);
//       }
//     }
// }, [taxesData, index, productList,currentLanguage]);
useEffect(() => {
    if (taxesData?.data) {
        const formattedTaxes = taxesData.data.map(tax => ({
            label: currentLanguage === 'ar' 
                ? `${tax.tax_name_ar || tax.tax_name_en || ''} ${tax.tax_value}%`
                : `${tax.tax_name_en || tax.tax_name_ar || ''} ${tax.tax_value}%`,
            value: tax.id.toString(),
            rate: tax.tax_value
        }));
        
        setTaxesList(formattedTaxes);

        const defaultTax = {
            label: `${invoiceSettings.INV_VAT_PERCENT}%`,
            value: '0',
            rate: invoiceSettings.INV_VAT_PERCENT
        };

        if (productList[index]) {

            if (productList[index].tax_id === undefined || productList[index].tax_id === null) {
                const updatedProductList = [...productList];
                updatedProductList[index] = {
                    ...updatedProductList[index],
                    tax_id: '0', 
                    
                    tax_rate: invoiceSettings.INV_VAT_PERCENT
                };

                setSelectedTax(defaultTax);
                setProductList(updatedProductList);
            } else {

                const existingTax = formattedTaxes.find(tax => 
                    tax.value === productList[index].tax_id.toString()
                );

                if (existingTax) {
                    setSelectedTax(existingTax);
                } else {
                    setSelectedTax(defaultTax);
                }
            }
        }
    }
}, [taxesData, index, productList.length, currentLanguage, invoiceSettings]);
// const onChangeTax = (taxItem) => {
//   if (taxItem && productList[index]) {
//      if (taxItem.value !== '0') {
//       productList[index].tax_id = taxItem.value;
//       productList[index].tax_rate = taxItem.rate;
//     }
    
//     setSelectedTax(taxItem);
    
//      productList[index].productVat = (productList[index].productNetTotal || 0) * taxItem.rate / 100;
//     productList[index].productNetTotalWithVat = 
//       productList[index].productVat + (productList[index].productNetTotal || 0);
    
//     setProductList([...productList]);
//   }
// };

const setBalanceRow = (index) => {
    if ((productList[index].productNetTotal || 0) > productList[index].price) {
        productList[index].productDiscount = 0;
    }

    if (productList[index]?.productDiscount?.includes('%')) {
        productList[index].productNetTotal = toFloat(productList[index].price, false) - 
            (toFloat(productList[index].price, false) * parseFloat(productList[index].productDiscount.replace('%', '')) / 100);
    } else {
        productList[index].productNetTotal = productList[index].price - (productList[index].productDiscount || 0);
    }

    let taxRate = 0;
    if (productList[index].tax_rate !== undefined) {
        taxRate = productList[index].tax_rate;
    } else if (selectedTax?.rate) {
        taxRate = selectedTax.rate;
    } else {
        taxRate = invoiceSettings.INV_VAT_PERCENT;
    }

    productList[index].productVat = taxRate === 0 
        ? 0 
        : (productList[index].productNetTotal || 0) * taxRate / 100;
    
    productList[index].productNetTotalWithVat = 
        (productList[index].productNetTotal || 0) + productList[index].productVat;

    const totalVat = productList.reduce((sum, product) => {
        return product.productId !== 'section' && product.tax_rate !== 0 
            ? sum + (product.productVat || 0) 
            : sum;
    }, 0);

    setVat((prevVat) => {
        console.log('Previous VAT:', prevVat);
        console.log('New Total VAT:', toFloat(totalVat, false));
        return toFloat(totalVat, false);
    });

    setProductList([...productList]);
}

const onChangeTax = (taxItem) => {
    if (taxItem && productList[index]) {
        productList[index].tax_id = taxItem.value;
        productList[index].tax_rate = taxItem.rate;
        
        setSelectedTax(taxItem);
        setBalanceRow(index);
    }
};

 const isFirstProductItem = () => {
    return index === (productList.findIndex(item => item?.productId !== "section") || 0);
  };

 

  const isFirstItemAfterSection = () => {
    return index > 0 && 
           productList[index-1]?.productId === "section" && 
           (index === productList.findIndex((item, idx) => idx > index-1 && item?.productId !== "section"));
  };
  
       
    return (
        <div className="w-full">
         <DraggableFormRow 
                index={index} 
                moveRow={moveRow} 
                id={`row-${index}`}
                productList={productList}
            >

         
{productList[index]?.productName !== 'Deleted' && productList[index]?.productId === "section" ? (
    <div className='flex border-b items-center py-2 w-full overflow-hidden'>          
          <DraggableLabel
                    productName={productList[index]?.productName}
                    onChangeSection={(value) => onChangeSection(value, index)}
                    deleteItem={() => deleteItem()}
                    t={t}
                />
            </div>
        ) 

            :
            <div className='flex w-full bg-slate-100 rounded-lg p-2 my-1 mx-2'>
    <div className='flex items-center gap-2 w-full '>
        <div className='flex items-center flex-1'>
                            <div className="flex flex-1 flex-col ">
                                <MwSelectorProduct 
                                    invalid = {!!formErrors?.productList}
                                   withLabel={index === (productList.findIndex(item => item?.productId !== "section") || 0) && true}
                                   hideSearchAndAdd={true} 
                                    initalValue = {productList[index]?.productName}
                                    reloadProductList={reloadProductList} 
                                    onCreateProduct={showModalProductHandle} 
                                    onChangeCallback ={onChangeProduct} />
                            </div>
                        <div className="  w-20    ">
                            <MwInputText
                                label={ isFirstProductItem()&& t("invoices.unitPrice")}
                                disabled = {isDisabledPriceUnit}
                                id='priceUnit' 
                                value={ isDisabledPriceUnit ?amountFormat(productList[index]?.priceUnit) : productList[index]?.priceUnit || ''} 
                                onChange={(e)=>onChangePriceUnit(e.target.value,index)} />
                        </div>
                        {SETTING_INV_QTY_DAYS === 'true' && <div className={`flex flex-col me-1 ${isFirstProductItem() && 'mt-1'} ${isFirstItemAfterSection() && 'mt-1'}`}>
                            <InputQty 
                                label= {isFirstProductItem() && t('invoice.quantity_days') }
                                id='qty_days' 
                                value={productList[index]?.qtyDays || ''} 
                                onChange={(e)=>onChangeQtyDays(e.target.value,index)}
                                onIncress={(e)=>incressQtyDays(index)}
                                onDecress={(e)=>decressQtyDays(index)}

                                // disabled={true}
                                />
                                
                                
                        </div>}
                        <div className="me-1 flex flex-row w-24">
                        <div className={`flex flex-col   ${isFirstProductItem() && 'mt-1'} ${isFirstItemAfterSection() && 'mt-1'}`}>
                            <InputQty 
                                label={isFirstProductItem() && t('invoice.quantity')}
                                id='qty' 
                                value={productList[index]?.qty || ''} 
                                onChange={(e)=>onChangeQty(e.target.value,index)}
                                onIncress={(e)=>incressQty(index)}
                                onDecress={(e)=>decressQty(index)}
                                width={(SETTING_INV_UOM_SHOW === 'true' && uomList.length === 0) ? '38' : '24'}
                            />
                        </div>
                       {SETTING_INV_UOM_SHOW === 'true' && uomList.length > 0 && 
                        <div className=" ">
                            <MwSelector
                                initalValue={t("invoices.unit")}
                                dataType='labelValue'
                                _data={uomList}
                                withAddNew={false}
                                selectedItem={uomSaleSelected}
                                setSelectedItem={setUomSaleSelected}
                                className={`w-10  ${index === 0 && 'pt-5'} me-1`}
                                withSearch={false}
                            />
                        </div>
                    }
                        </div>
                        {/* <div className="w-20  ">
                            <MwInputText 
                                label={`${isFirstProductItem()? t('invoice.price')  : ''}`}
                                id='price' 
                                disabled={ true}
                                value={ amountFormat(productList[index]?.priceUnit) || 0} 
                                onChange={(e)=>onChangePrice(e.target.value,index)} />
                        </div> */}
                        <div className=" w-12 ms-4 mx-1 ">
                            <MwInputText 
                                label={isFirstProductItem() && t("invoices.discount")}
                                id='productDiscount' 
                                value={ productList[index]?.productDiscount } 
                                onChange={(e)=>onChangeProductDiscount(e.target.value,index)} />
                        </div>
                        <div className=" w-20   ">
                            <MwInputText 
                                label={isFirstProductItem() && t("invoices.total")}
                                disabled={ true}
                                id='productNetTotal' 
                                value={ amountFormat(productList[index]?.productNetTotal) } 
                                onChange={(e)=>onChangePrice(e.target.value,index)} />
                        </div>
                        <div className="w-44 mx-1 ">
    <MwSelector
            label={isFirstProductItem() 
            &&     t("vat.vat")
        }
        dataType='labelValue'
        _data={taxesList}
        withAddNew={false}
        selectedItem={selectedTax}
        setSelectedItem={onChangeTax}
        // className={`${isFirstProductItem() && 'pt-5'}`}
        withSearch={false}
        loading={taxesLoading}
 
    />
    {/* <div className="text-xs mt-1 text-right">
        {amountFormat(productList[index]?.productVat)}
    </div> */}
</div>
                        <div className="w-20">
                            <MwInputText 
                                disabled={ true}
                                label={isFirstProductItem()&& t("invoice.netTotal")}
                                id='productNetTotalWithVat' 
                                value={ amountFormat(productList[index]?.productNetTotalWithVat) } 
                                onChange={(e)=>onChangePrice(e.target.value,index)} />
                        </div>
                    </div>
                    { SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION === 'true' && isProductDesc &&
                    <div className="w-full">
                        <MwTextArea
                            id='productDescription' 
                            value={ productList[index]?.productDesc } 
                            onChange={(e)=>onChangeProductDesc(e.target.value,index)} />
                    </div>
                    }
                </div>
                { 
                SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION === 'true' &&
                <div className={`flex justify-center items-center w-6 h-6 mx-3 m-1 bg-slate-200 rounded-lg hover:bg-slate-300 transition-colors ${isFirstProductItem() ? 'mt-7' : ''} ${isFirstItemAfterSection() && 'mt-2'}`}>
                <BiMessageSquareDetail onClick={()=>descItem()} size={21} title='تفاصيل المنتج' className='text-slate-800' />
              </div>
                }
                <div className={`flex justify-center items-center w-6 h-6 m-1 bg-red-100 rounded-lg hover:bg-red-200 transition-colors ${isFirstProductItem() ? 'mt-7' : ''} ${isFirstItemAfterSection() && 'mt-2'}`}>
  <TiDeleteOutline onClick={()=>deleteItem()} size={21} title='حذف المنتج' className='text-red-500' />
</div>

            </div>
          
            }
     
        </DraggableFormRow>
        </div>
    )
}

export default InvFormListItem  