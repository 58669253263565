import { useState } from 'react'
import {LuLayoutDashboard} from 'react-icons/lu'
import ProductsNavbar from '../../components/Products/ProductsNavbar';
import ProductsCategories from './ProductsCategories';
import ProductsBrands from './ProductsBrands';
import ProductsTags from './ProductsTags';
import Products from './Products';
import { useTranslation } from 'react-i18next'
import CardHeadeWithActions from '../../components/ui/CardHeadeWithActions';
import OSListsPriceses from './OSListsPriceses';
import { MwButton } from '../../components/ui';
import { MdOutlineAddBox } from 'react-icons/md';

const ProductsHome = () => {
    const [t,i18n ] = useTranslation('global')
    const isRTL = i18n.dir() === 'rtl';
    const [mode,setMode] = useState('List');

  
    const [tabActive,setTabActiive] = useState('Products');

    return (
        <div  >
            <CardHeadeWithActions title={   <div className='flex items-center gap-2'>
            <LuLayoutDashboard size={18} /> {t('product.productsServicesManagement')}
            </div>}>
                                         <MwButton inGroup={true} type='saveBtn' onClick={()=>setMode('Add')}  >
                                            <MdOutlineAddBox size={18}/>
                                            {t('product.createNewProduct')}
                                        </MwButton>
             </CardHeadeWithActions>
            
            <ProductsNavbar tabActive={tabActive} setTabActiive={setTabActiive}   />

            <div id='tab' className='bg-slate-100  rounded-b-lg'>
                <div id='Products'  
                    className={`flex flex-col gap-2 ${tabActive === 'Products' ? 'block' : 'hidden'} `}>
                    <Products setMode={setMode} mode={mode}/>
                </div>
                <div id='Prices'  
                    className={`flex flex-col gap-2 ${tabActive === 'Prices' ? 'block' : 'hidden'} `}>
                    <OSListsPriceses/>
                </div>
                <div id='Categories'  
                    className={`flex flex-col gap-2 ${tabActive === 'Categories' ? 'block' : 'hidden'} `}>
                        <ProductsCategories/>
                </div>
                <div id='Brands'  
                    className={`flex flex-col gap-2 ${tabActive === 'Brands' ? 'block' : 'hidden'} `}>
                        <ProductsBrands/>
                </div>
                <div id='Tags'  
                    className={`flex flex-col gap-2 ${tabActive === 'Tags' ? 'block' : 'hidden'} `}>
                        <ProductsTags/>
                </div>
            </div>
        </div>
    );
}

export default ProductsHome