import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { MwSpinnerButton, NoDataFound } from '../ui'
import { useDelete, useInvoice } from '../../hooks';
import { useTranslation } from 'react-i18next';
import toast, { Toaster } from 'react-hot-toast';
import ActionsDropdown from '../Common/ActionsDropdown';
import i18next from 'i18next';
import { FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import DeleteConfirmationModal from '../Common/OsDeleteConfirmationModal ';

const InvListBasic = ({
    data,
    loading=true,
    links=[],
    paginateHandler = (url)=>{} ,
    withBorder=true,
    withSummary=false,
    hideDate = false
}) => {
    const isRTL = i18next.dir() === 'rtl';
    const navigate = useNavigate();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [localData, setLocalData] = useState(data);

    const { deleteItem,data: deleteResponse,loading: isDeleting } = useDelete();
    
     const handleDeleteClick = (id) => {
        setSelectedItemId(id);
        setDeleteModalOpen(true);
    };

    
    const params = useParams();
    const {formatter} = useInvoice();
    const [t] = useTranslation('global')

    useEffect(() => {
        if (data) {
          setLocalData(data);
        }
      }, [data]);

    useEffect(() => {
        if (deleteResponse && selectedItemId) {
           setLocalData(prevData => prevData.filter(item => item.id !== selectedItemId));
          setSelectedItemId(null);
        }
      }, [deleteResponse, selectedItemId]);
    
      const deleteHandeler = (id) => {
        const url = `${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/quotes/delete/${id}`;
        toast.loading('جاري الحذف...', { id: 'deleteToast' });
        
        deleteItem(url).then(() => {
            toast.success('تم حذف عرض السعر بنجاح', { id: 'deleteToast' });
        }).catch((error) => {
            toast.error('حدث خطأ أثناء الحذف', { id: 'deleteToast' });
            console.log(`Error deleting: ${error}`);
        });
    };

    
    return (
        <>
        <Toaster
  position="top-center"
  containerStyle={{
    top: 60, 
  }}
  toastOptions={{
    duration: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    pauseOnHover: true,
    style: {
      direction: "rtl"
    }
  }}
/>
            {!loading ? 
            <div className={`flex-1 ${ withBorder && ' border rounded-xl ' }` }>
                {localData && localData.length > 0 ? <ul>
                    <li className='flex justify-between p-3 text-xs border-b bg-slate-100'>
                        <div className='w-24 px-1 text-center'>   {t("DashBoard.quoteNumber")} </div>
                        <div className='flex-1'>   {t("DashBoard.customerName")}</div>
                        <div className='w-28 px-1  text-center'>{t("quotes.expirationDate")} </div>

                        <div className='w-28 px-1  text-center'>{t("DashBoard.amount")} </div>
                        {!hideDate && (
                  <div className="w-24 px-1 text-center">{t("DashBoard.date")}</div>
                )}                        <div className='w-12 px-1  text-center'></div>
                    </li>
                 {localData.map((result, index) => (
  <div key={result.id} className={`flex justify-between p-2 text-xs border-b hover:bg-indigo-50 hover:font-bold ${data?.length !== index+1 && ' border-b'}`}>
    <Link className="flex items-center flex-1" to={`/${params.tenant}/quotes/show/${result.id}/${result.company_id}/${result.branch_id}`}>
      <div className='w-24 px-1 text-center  '>{result.inv_no}</div>
      <div className='flex-1  '  >{result.customer_name || t("invoice.GeneralCustomer")}</div>
      <div className='w-28 px-1 text-center  '>{result.inv_date_due.substring(0,10)}</div>
      <div className='w-28 px-1 text-center  '>{formatter.format(result.net_amount)}</div>
      {!hideDate && (
                      <div className="w-24 px-1 text-center">{result.inv_date.substring(0, 10)}</div>
                    )}    </Link>
    <div className='w-12 px-1 text-center'>
    <ActionsDropdown
    isRTL={isRTL}
    itemId={result.id}
    onView={() => navigate(`/${params.tenant}/quotes/show/${result.id}/${result.company_id}/${result.branch_id}`)}
    onEdit={() => navigate(`/${params.tenant}/quotes/edit/${result.id}`)}
    onDelete={() => handleDeleteClick(result.id)}
    viewText={t("common.view")}
    editText={t("common.edit")}
    deleteText={t("common.delete")}
    viewIcon={<FaEye className="inline-block mr-1" />}
    editIcon={<FaEdit className="inline-block mr-1" />}
    deleteIcon={<FaTrash className="inline-block mr-1" />}
/>
    </div>
  </div>
))}
                </ul> : <NoDataFound msg={t("DashBoard.noQuotes")} /> }
            </div> : <div className='flex-1'><MwSpinnerButton withLabel={false} isFullCenter={true} /> </div> }

            <DeleteConfirmationModal
    isOpen={deleteModalOpen}
    onClose={() => setDeleteModalOpen(false)}
    onConfirm={() => {
        deleteHandeler(selectedItemId);
        setDeleteModalOpen(false);
    }}
    loading={isDeleting}
/>
        </>
    )
}

export default InvListBasic