import React,{useState,useEffect,useRef} from 'react'
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header'; 
import List from '@editorjs/list';
import InvFormListItem from '../InvFormListItem'
import { MwButton } from '../../ui';
import MwTabView from '../../ui/tab/MwTabView';
import MwTabViewHead from '../../ui/tab/MwTabViewHead';
import MwTabViewBody from '../../ui/tab/MwTabViewBody';
import { useCurrent, useFetch } from '../../../hooks';
const InvFormBody = ({
    SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION,
    SETTING_INV_QTY_DAYS,
    SETTING_INV_UOM_SHOW,
    initalProductItem,
    reloadProductList,
    showModalProductHandle,
    productList,
    setProductList,
    formErrors,
    SETTING_PRICE_INCLUDE_VAT,
    setInvNotes,
    setInvTerms,
    setVat,
    invoiceSettings,
    taxesList,
    setTaxesList
}) => {
    const [tabActive,setTabActive] = useState("items");
    const { currentTenantId, currentCompanyId, currentBranchId } = useCurrent();
    const baseUrl = `${process.env.REACT_APP_INV_API_SALES_SERVER_BASE_URL}/taxes/${currentTenantId}/${currentCompanyId}/${currentBranchId}`;
    const { data: taxesData, loading: taxesLoading } = useFetch(baseUrl);
    console.log("taxesData_from_body",taxesData)
    console.log("baseUrl",baseUrl)

    const ejInstanceNotes = useRef();

    const initEditorNotes = () => {
        const editor = new EditorJS({
            /**
             * Id of Element that should contain Editor instance
             */
            holder: 'notes-editorjs',
            // placeholder: 'من فضلك ادخل ملاحظاتك علي عرض السعر',
            onReady:()=>{
                ejInstanceNotes.current = editor
            },
            onChange:async()=>{ 
                let content = await editor.saver.save();
                setInvNotes(content)
                console.log(content)
            },
            autofocus: true,
            tools: { 
                header: {
                    class: Header,
                    inlineToolbar: ['link'],
                  }, 
                list: {
                    class: List,
                    inlineToolbar: true,
                  }, 
                }, 
            i18n: {
                /**
                 * Text direction
                 */
                    direction: 'ltr',
                },
            });
    }
    const ejInstanceTerms = useRef();

    const initEditorTerms = () => {
        const editor = new EditorJS({
            /**
             * Id of Element that should contain Editor instance
             */
            holder: 'terms-editorjs',
            // placeholder: 'الشروط والاحكام',
            onReady:()=>{
                ejInstanceTerms.current = editor
            },
            onChange:async()=>{ 
                let content = await editor.saver.save();
                setInvTerms(content)
                console.log(content)
            },
            autofocus: true,
            tools: { 
                header: Header, 
                list: List 
                }, 
            i18n: {
                /**
                 * Text direction
                 */
                    direction: 'ltr',
                },
            });
    }

    useEffect(() => {
        if(ejInstanceNotes.current == null){
             initEditorNotes();
        } 
        if(ejInstanceTerms.current == null){
             initEditorTerms();
        } 
        return () =>{
            ejInstanceNotes?.current?.destroy();
            ejInstanceNotes.current = null;
            ejInstanceTerms?.current?.destroy();
            ejInstanceTerms.current = null;
        }
    },[]);

    const addSectionHandler = (e)=>{
        e.preventDefault()
        setProductList([...productList,{
            index: productList.length,
            productId:'section', 
            productName: '',
            productDesc: '',
            qty:0,
            qtyDays:0,
            price:'0',
            product_discount:0,
            product_net_total:0,
            product_net_total_with_vat:'0',
            product_vat: 0,
            total_price:0,
            unit_id:1,
        }]);
    }
    const addItemHandler = (e)=>{
        e.preventDefault()
        setProductList([...productList,{
            index: productList.length,
            productId:'', 
            productName: '',
            productDesc: '',
            qty:1,
            qtyDays:1,
            price:'0'
        }]);
    }

    const handleMoveRow = (dragIndex, hoverIndex) => {
        const draggedItem = {...productList[dragIndex]};
        const updatedList = [...productList];
        
         const originalDraggedState = {
            productId: draggedItem?.productId,
            productName: draggedItem.productName,
            price: draggedItem.price,
            priceUnit: draggedItem.priceUnit,
            qty: draggedItem.qty,
            qtyDays: draggedItem.qtyDays,
         };
    
         updatedList.splice(dragIndex, 1);
        
         updatedList.splice(hoverIndex, 0, {
            ...draggedItem,
            ...originalDraggedState,
            index: hoverIndex
        });
    
         const finalList = updatedList.map((item, idx) => ({
            ...item,
            index: idx
        }));
    
        setProductList(finalList);
    };
    return (
        <div className='my-3'>
         <MwTabView>
            <MwTabViewHead setTabActive={setTabActive} tabActive={tabActive} tabTitleList={[
                { label:" المنتجات ", value:"items"},
                { label:"الملاحظات", value:"notes"},
                { label:"الشروط والاحكام", value:"terms"}
                ]} />
            
            <MwTabViewBody>
                <div id='inv-body' className={`max-h-[calc(100vh-275px)] min-h-[calc(100vh-400px)]  overflow-y-auto     ${tabActive === 'items' ? 'block' : 'hidden'} ` }   >
                    <div className='  '>
                        { productList.length > 0 && initalProductItem.map((e,index)=>{
                            return <InvFormListItem
                                        key = {index}
                                        moveRow={handleMoveRow}
                                        index = {index}
                                        reloadProductList = {reloadProductList}
                                        showModalProductHandle = {showModalProductHandle}
                                        productList = {productList}
                                        setProductList = {setProductList}
                                        formErrors = {index ===0 && formErrors}
                                        SETTING_INV_QTY_DAYS = {SETTING_INV_QTY_DAYS}
                                        SETTING_INV_UOM_SHOW = {SETTING_INV_UOM_SHOW}
                                        SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION = {SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION}
                                        taxesData={taxesData} // تمرير بيانات الضرائب
                                        taxesLoading={taxesLoading} // تمرير حالة التحميل
                                        setVat={setVat}
                                        invoiceSettings={invoiceSettings}
                                        taxesList={taxesList}
                                        setTaxesList={setTaxesList}
                                        />
                            })
                        }
                    </div>
                    <div className='  flex justify-end gap-1 m-3'>
                    {/* <MwButton  actionType="button" type="secondary" onClick={addSectionHandler} > اضف قسم </MwButton> */}
                    <MwButton size='sm'  actionType="button" type="secondary" onClick={ addItemHandler} > اضف بند </MwButton>
                    </div>
                </div>
                <div id="notes-editorjs" className={`shrink	 p-4 bg-white  rounded-lg ${tabActive === 'notes' ? 'block' : 'hidden'}`} >
                </div>
                <div id="terms-editorjs" className={`shrink	 p-4 bg-white  rounded-lg ${tabActive === 'terms' ? 'block' : 'hidden'}`} >
                </div>
            </MwTabViewBody>
        </MwTabView>
         </div>
    )
}

export default InvFormBody