import { useInvoice,useCurrent } from '../../hooks';
import {LangArrayToObjKeyValue} from '../../utils/global'
import {AiOutlineEdit} from 'react-icons/ai'
import {RiDeleteBin6Line} from 'react-icons/ri'
import { SiSitecore } from "react-icons/si";
import { useTranslation } from 'react-i18next'
   import { useState } from 'react';
import DeleteConfirmationModal from '../OsNewWeight/OsDeleteConfirmationModal ';
import ActionsDropdown from '../Common/ActionsDropdown';
import ActiveIndicator from './../Common/ActiveIndicator';

const ListProductsItems = ({item,editHandeler,deleteHandeler,index}) => {
    const [t,i18n ] = useTranslation('global')
    const isRTL = i18n.dir() === 'rtl';
    const { currentLangId} = useCurrent();
    const {SETTING_INVOICE,formatter} = useInvoice();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
const [selectedItemId, setSelectedItemId] = useState(null);
    const INV_CURRENCY = SETTING_INVOICE?.INV_CURRENCY
    const SETTING_INV_CURRENCY = INV_CURRENCY  || 'SAR'
    const productName = LangArrayToObjKeyValue(item?.productName)?.[currentLangId] || item?.productName[0]?.text || item?.productName[1]?.text
    const productCateg = LangArrayToObjKeyValue(item?.productCategory[0]?.categoryName)?.[currentLangId] || item?.productCategory[0]?.
    categoryName[0]?.text || item?.productCategory[0]?.categoryName[1]?.text
    let productName2 =""
    let productDesc =""
    if(productName === item?.productName[0]?.text){
        productName2 = item?.productName[1]?.text
        productDesc = item?.productDesc[0]?.text
    }else{
        productName2 = item?.productName[0]?.text
        productDesc = item?.productDesc[1]?.text
    }
    return (
        <>
       {index === 0 && 
  <div className='flex gap-3 z-20 sticky top-0 bg-slate-200 font-bold rounded-t-2xl justify-between items-center border-t py-2 px-2 hover:bg-indigo-50 hover:font-bold'>
    <div className='w-3 flex justify-center items-center'>#</div>
    <div className='w-28 flex justify-center items-center'>{t('product.productCode')}</div>
    <div className='flex-1 flex justify-start items-center'>{t('product.product')}</div>
    <div className='w-16 flex justify-center items-center'>{t('product.quantity')}</div>
    <div className='w-24 p-2 flex justify-center items-center'>{t('product.salePrice')}</div>
    <div className='w-8 flex justify-center items-center'>{t('product.status')}</div>
    <div className='w-2 flex justify-center items-center'>{/* عمود الإجراءات */}</div>
  </div>
}

        
<div className='flex gap-3 justify-between items-center border-t py-2 px-2 hover:bg-indigo-50 hover:font-bold'>
  <div className='w-3 flex justify-center items-center'>{index + 1}</div>
  <div className='w-28 flex justify-center items-center'>{item?.productCode || ''}</div>
  <div className='flex-1 flex flex-col justify-start' style={{ cursor: "pointer" }} onClick={() => editHandeler(item._id)}>
    <div>{productName}  
    { productCateg ? (<small className='px-2'>{`(${productCateg})`}</small>) : null }
    </div>
    {productName2 && <div className='text-xs text-slate-400'>{productName2}</div>}
    {productDesc && <div className='text-xs text-slate-400'>{productDesc}</div>}
  </div>
  <div className='w-16 flex justify-center items-center'>{item?.qty || 0}</div>
  <div className='w-24 flex justify-center items-center'>{item?.cost || ''}</div>
  <div className='w-8 flex justify-center items-center'>
    <ActiveIndicator active={item.isActive} />
  </div>
  <div className='w-2 flex justify-center items-center'>
    <ActionsDropdown
      isRTL={isRTL}
      onEdit={() => editHandeler(item._id)}
      onDelete={() => {
        setSelectedItemId(item._id);
        setDeleteModalOpen(true);
      }}
      itemId={item._id}
      editText={t('requisition.edit')}
      deleteText={t('requisition.delete')}
    />
  </div>
</div>


        <DeleteConfirmationModal
            isOpen={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            onConfirm={() => {
                deleteHandeler(selectedItemId);
                setDeleteModalOpen(false);
            }}
            loading={false}
        />
    </>
    )
}

export default ListProductsItems