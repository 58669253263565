import { useState } from 'react';
import OSviewAllListsPrices from './../../components/OsLIstsOfPrices/OSviewAllListsPrices';
import OsCreateListPrice from './../../components/OsLIstsOfPrices/OsCreateListPrice';
import CustomTable from './../../components/OsLIstsOfPrices/OsCustomTable';
import OsEditListName from './../../components/OsLIstsOfPrices/OsEditListName';
import CombinedTableStocking from './../../components/OsLIstsOfPrices/OsViewListsPriceTable';
 
 

const OSListsPriceses = () => {
  const [mode, setMode] = useState('List');
  const [idSelected, setIdSelected] = useState();

  return (
    <div  >
      {mode === 'List' && (
        <OSviewAllListsPrices 
          idSelected={idSelected} 
          setIdSelected={setIdSelected} 
          setMode={setMode} 
        />
      )}
      {mode === 'AddList' && <OsCreateListPrice setMode={setMode}  idSelected={idSelected} 
          setIdSelected={setIdSelected}  />}
      {mode === 'DetailsLists' && idSelected && (
        <CustomTable id={idSelected} setMode={setMode} />
      )}
      {mode === 'EditList' && idSelected && (
        <OsEditListName id={idSelected} setMode={setMode} />
      )}
      {mode === 'ViewList' && idSelected && (
        <CombinedTableStocking id={idSelected} setMode={setMode} />
      )}
    </div>
  );
};

export default OSListsPriceses;
