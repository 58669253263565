import { useState } from 'react';
import ListProducts from '../../components/Products/ListProducts';
import CreateProduct from '../../components/Products/CreateProduct';
import EditProduct from '../../components/Products/EditProduct';


const Products = ({ mode, setMode }) => {
    const [idSelected,setIdSelected] = useState();

    return (
        <>
            <div  >
                { mode === 'List' && <ListProducts idSelected = {idSelected} setIdSelected = {setIdSelected} setMode={setMode} /> }
                { mode === 'Add' && <CreateProduct setMode={setMode} /> }
                { (mode === 'Edit' && idSelected) && <EditProduct id={idSelected} setMode={setMode} /> }
            </div>
        </>
    );
}

export default Products