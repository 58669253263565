 
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const token = localStorage.getItem('token');

// 1. تعريف Thunk لإضافة البيانات (POST)
export const addBoost = createAsyncThunk('boost/addBoost', async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/uom`,data);
        console.log('New UOM:', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// 2. تعريف Thunk للحصول على البيانات (GET)
export const getUOMData = createAsyncThunk(
  'boost/getUOMData',
  async (tenantId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/uom/tenant/${tenantId}`
      );
      console.log('UOM Data:', response.data);
      return response.data.data; // استخدام data من الاستجابة مباشرة
    } catch (error) {
      return rejectWithValue(error.response.data); // لو حصل خطأ
    }
  }
);


 export const getUOMById = createAsyncThunk(
  'boost/getUOMById',
  async (id, { rejectWithValue }) => {
    try {
      console.log('ID:', id);
      const response = await axios.get(
       `${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/uom/${id}`
      );
      console.log('UOM by ID:', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
// 3. تعريف Thunk لتحديث البيانات (PUT)
export const UpdateUnit = createAsyncThunk(
  'boost/getUOMById',
  async (data, { rejectWithValue }) => {
    try {
       const response = await axios.put(
       `${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/uom`,
        data
      );
      console.log('UOM by ID:', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const Delete_Id_Uom_Template = createAsyncThunk(
  'boost/getUOMById',
  async (id_uom_template, { rejectWithValue }) => {
    try {
       const response = await axios.delete(
       `${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/uom/${id_uom_template}` );
      console.log('UOM by ID:', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// 4. إنشاء Slice
const boostSlice = createSlice({
  name: 'boost',
  initialState: {
    loading: false,
    boostData: null,
    uomData: null,
    uomByIdData: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Thunk لإضافة البيانات
      .addCase(addBoost.pending, (state) => {
        state.loading = true;
      })
      .addCase(addBoost.fulfilled, (state, action) => {
        state.loading = false;
        state.boostData = action.payload;
      })
      .addCase(addBoost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Thunk للحصول على البيانات
      .addCase(getUOMData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUOMData.fulfilled, (state, action) => {
        state.loading = false;
        state.uomData = action.payload;
      })
      .addCase(getUOMData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getUOMById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUOMById.fulfilled, (state, action) => {
        state.loading = false;
        state.uomByIdData = action.payload; // حفظ البيانات المسترجعة
      })
      .addCase(getUOMById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // حفظ الخطأ في حالة الفشل
      });
  },
});

// 5. تصدير الـ Reducer
export default boostSlice.reducer;