import React, { useEffect, useState } from 'react'
import {  useTranslation } from 'react-i18next';

import {MwInputText,MwSelector, MwSelectorMulti, TagList} from '../../ui';
import { getDateNow } from './../../../utils/utils/global';
import { useFetch } from '../../../hooks';
import PromoCodeBox from '../PromoCodeBox'; 

const InvFormHeader = ({
    invNo,
    setInvNo,
    invRef,
    invTitle,
    invTitleAr,
    invDate,
    invDateDue,
    invCurrency,
    customers,
    onClickNewCustomer,
    salesmans,
    onClickNewSalesman,
    customerSelected,
    setCustomerSelected,
    salesmanSelected,
    setSalesmanSelected,
    formErrors,
    setPriceListItems,
    priceListItems,
    productList,
    setProductList,
    SETTING_PRICE_INCLUDE_VAT,
    SETTING_INVOICE,
    netTotal  ,
    havePromoCode,
    setHavePromoCode,
    promoCodeHandler ,
    discountOnchange ,
    tagList ,
    setTagList ,
    promoCode,
    setPromoCode ,
    taxesList
    }) => {
        const [t] = useTranslation('global')
        const [titleLang, setTitleLang] = useState('ar')
        const [arValue, setArValue] = useState('');
        const [enValue, setEnValue] = useState('');
        const {
            data: fetchedData,
            loading,
            error,
            refreshHandler
          } = useFetch(
            customerSelected && customerSelected.listPriceId
              ? `${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/list-price-items/${customerSelected.listPriceId}`
              : null,
            false  
          );

          console.log("productList By osamas",productList);
          

        const handleArChange = (e) => {
                    setArValue(e.target.value);
                };
        
                const handleEnChange = (e) => {
                             setEnValue(e.target.value);
                         };

        const date = new Date();
        const today = getDateNow().toLocaleDateString('en-CA'); // Get current date in the format 'YYYY-MM-DD'
        const todayAndTime = getDateNow().toLocaleDateString('en-CA')+' '+date.toLocaleTimeString('it-IT'); // Get current date in the format 'YYYY-MM-DD'
        
        useEffect(() => {
            if (customerSelected && customerSelected.salesmanId) {
                const matchingSalesman = salesmans.find(salesman => salesman._id === customerSelected.salesmanId);
                if (matchingSalesman) {
                    setSalesmanSelected([{
                        label: matchingSalesman?.fullname && matchingSalesman.fullname[1] ? matchingSalesman.fullname[1].text : '',
                        value: matchingSalesman._id,
                        ...matchingSalesman
                    }]);
                }
            } else {
                setSalesmanSelected([]);
            }
        }, [customerSelected, salesmans, setSalesmanSelected]);

        // useEffect(() => {
        //   if (customerSelected && customerSelected.listPriceId) {
        //     console.log("defaultcustomerSelected",customerSelected);
        //     refreshHandler();
        //   } else {
        //      const defaultPriceList = productList.map(product => {
        //       console.log("defaultPriceList out");
        //        if (product.productId === 'section' || !product.productId || product.productId === '0') {
                 
        //         return product;
        //       }
        
        //        const defaultPrice = SETTING_PRICE_INCLUDE_VAT === 'true' 
        //         ? (parseFloat(product.originalPriceUnit) || 0)
        //         : parseFloat(product.originalPriceUnit) || 0;
        
        //       return {
        //         ...product,
        //         priceUnit: defaultPrice,
        //         price: product.qty * defaultPrice * (product.qtyDays || 1),
        //         productNetTotal: product.qty * defaultPrice * (product.qtyDays || 1),
        //         productVat: (product.qty * defaultPrice * (product.qtyDays || 1)) * (SETTING_INVOICE?.INV_VAT_PERCENT || 15) / 100,
        //         productNetTotalWithVat: (product.qty * defaultPrice * (product.qtyDays || 1)) * (1 + (SETTING_INVOICE?.INV_VAT_PERCENT || 15) / 100)
        //       };
        //     });
        
        //     setPriceListItems([]);
        //     setProductList(defaultPriceList);
        //   }
        // }, [customerSelected]);
       
        // ADDD NEW USE EFFECT TO HANDLE NEW TAX
        useEffect(() => {
  if (customerSelected && customerSelected.listPriceId) {
    const defaultPriceList = productList.map(product => {
      if (product.productId === 'section' || !product.productId || product.productId === '0') {
        return product;
      }

      // احسب السعر باستخدام الإعداد الافتراضي للضريبة
      const defaultPrice = SETTING_PRICE_INCLUDE_VAT === 'true' 
        ? (parseFloat(product.originalPriceUnit) || 0)
        : parseFloat(product.originalPriceUnit) || 0;

      // استخدم معدل الضريبة من الإعدادات أو من الضرائب المحددة
      const taxRate = taxesList.length > 0 
        ? (taxesList[0]?.rate || SETTING_INVOICE?.INV_VAT_PERCENT || 15)
        : (SETTING_INVOICE?.INV_VAT_PERCENT || 15);

      return {
        ...product,
        priceUnit: defaultPrice,
        price: product.qty * defaultPrice * (product.qtyDays || 1),
        productNetTotal: product.qty * defaultPrice * (product.qtyDays || 1),
        productVat: (product.qty * defaultPrice * (product.qtyDays || 1)) * taxRate / 100,
        productNetTotalWithVat: (product.qty * defaultPrice * (product.qtyDays || 1)) * (1 + taxRate / 100)
      };
    });

    setPriceListItems([]);
    setProductList(defaultPriceList);
  }
}, [customerSelected, taxesList]);

          useEffect(() => {
            if (fetchedData && fetchedData.data) {
              setPriceListItems(fetchedData.data);
              
               if (productList && productList.length > 0 && customerSelected && customerSelected.listPriceId) {
                const updatedProductList = [...productList];
                
                updatedProductList.forEach((product, idx) => {
                   if (product.productId === 'section' || !product.productId || product.productId === '0') {
                    return;
                  }
                  
                   const priceListItem = fetchedData.data.find(
                    item => item.product_id.trim() === product.productId
                  );
                  
                  if (priceListItem) {
                     const newPrice = SETTING_PRICE_INCLUDE_VAT === 'true' 
                      ? (parseFloat(priceListItem.product_sale_price) ) 
                      : parseFloat(priceListItem.product_sale_price);
                    
                     product.priceUnit = newPrice;
                    
                     product.price = product.qty * newPrice * (product.qtyDays || 1);
                    
                     if (product.productDiscount && product.productDiscount.includes('%')) {
                      product.productNetTotal = parseFloat(product.price) - (parseFloat(product.price) * parseFloat(product.productDiscount.replace('%', '')) / 100);
                    } else {
                      product.productNetTotal = product.price - (product.productDiscount || 0);
                    }
                    
                     const vatPercent = SETTING_INVOICE?.INV_VAT_PERCENT || 15;
                    product.productVat = (product.productNetTotal || 0) * vatPercent / 100;
                    product.productNetTotalWithVat = product.productVat + (product.productNetTotal || 0);
                  }
                });
                
                 setProductList([...updatedProductList]);
              }
            }
          }, [fetchedData, customerSelected]);
        
    return (
      <>
            <div className="flex flex-col md:flex-row justify-between  items-center ">
                <div className="flex w-full md:w-fit " >
                    <div className="flex-1 md:w-24   ">
                        <MwInputText 
                            label= {t("quotes.quoteNumber")}
                            id='inv_no' 
                            value={invNo} 
                            disabled={true} 
                            onChange={(e)=>setInvNo(e.target.value)} />
                    </div>
                    <div className="flex-1 md:w-20">
                        <MwInputText 
                            label={t('invoice.invoiceRef')}
                            inputRef={invRef}
                            id='inv_ref' 
                            defaultValue=''
                            />
                    </div>
                </div>
                    <div className="flex w-full md:w-fit " >
                    <div className="flex-1 md:w-38 ">
                        <MwInputText 
                            label= {t("quotes.creationDate")}
                            // inputType='date'
                            inputRef={invDate} 
                            id='inv_date'
                            defaultValue={todayAndTime}
                            invalid = {!!formErrors?.invDate}
                            disabled = {true}
                            // invalidMsg={formErrors?.invDate}
                            />
                    </div>
                    <div className="flex-1 md:w-28">
                        <MwInputText 
                            label={t("quotes.expirationDate")}
                            inputType='date'
                            inputRef={invDateDue} 
                            id='inv_date_due'
                            defaultValue={today}
                            invalid = {!!formErrors?.invDateDue}
                            />
                    </div>
                </div>
                <div className="relative flex w-full items-center gap-4">
                <div className="flex-1">
    <div className="flex justify-between items-center mb-2">
        <span className='text-gray-400 text-xs'>{titleLang === 'ar' ? t('invoice.invTitleAr') : t('invoice.invTitle')}</span>
        <button 
            onClick={() => setTitleLang(titleLang === 'ar' ? 'en' : 'ar')}
            type="button"
            className="px-2 py-0.5 text-xs bg-blue-500 text-white rounded hover:bg-blue-600"
        >
            {titleLang === 'ar' ? 'EN' : 'AR'}
        </button>
    </div>
    <div className={titleLang === 'ar' ? '' : 'hidden'}>
     <MwInputText 
         inputRef={invTitleAr}
         id="inv_title_ar"
         value={arValue}
         onChange={handleArChange}
         dir="rtl"
     />
  </div>
  <div className={titleLang === 'ar' ? 'hidden' : ''}>
     <MwInputText 
         inputRef={invTitle}
         id="inv_title"
         value={enValue}
         onChange={handleEnChange}
         dir="ltr"
     />
  </div>
</div>
    
    <div className="flex-1">
        <MwSelector 
            label={t('invoice.selectCustomer')}
            initalValue={`${t('invoice.selectCustomer')}...`}
            _data={customers} 
            dataType='customer' 
            onClickNew={onClickNewCustomer}
            selectedItem={customerSelected}
            setSelectedItem={setCustomerSelected}
            invalid={!!formErrors?.customerSelected}
         />
    </div>
    
    <div className="flex-1">
        <MwSelector
            initalValue={`${t('invoice.selectSalesman')}...`}
            label={t('invoice.selectSalesman')}
            _data={salesmans} 
            dataType='salesman' 
            onClickNew={onClickNewSalesman}
            selectedItem={salesmanSelected}
            setSelectedItem={setSalesmanSelected}
        />
    </div>

    
</div>

                </div>
                <div  className='flex md:flex-row my-2 flex-col justify-between '>

                        <TagList
                            tagList = {tagList} 
                            setTagList = {setTagList}
                        />
                        <PromoCodeBox
                            havePromoCode = {havePromoCode}
                            setHavePromoCode = {setHavePromoCode}
                            promoCode = {promoCode}
                            setPromoCode = {setPromoCode}
                            promoCodeHandler = {promoCodeHandler}
                            />
                    </div>

                </>
            
        
    )
}

export default InvFormHeader