import React from 'react'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';

const InvoiceFooter = ({
    totalAmount,
    vatAmount,
    discountAmount,
    netAmount,
    invTerms,
    SETTING_PRICE_INCLUDE_VAT,
    SETTING_INV_VAT_PERCENT,
    SETTING_SHOW_TERMS_ON_PRINT,
    isDelivery,
    currentLang,
    SETTING_INV_CURRENCY,
    formatter,
    invData
}) => {
    const [t] = useTranslation('global')
    const invTermsHandler = invTerms

    // Function to get tax details from local storage
    const getTaxDetailsFromLocalStorage = () => {
        try {
            const storedTaxesRaw = localStorage.getItem('taxesData')
             
            // If no data, return empty array
            if (!storedTaxesRaw) return []

            // Try parsing the data
            const storedTaxes = JSON.parse(storedTaxesRaw)
            
            // Ensure it's an array and extract the data array
            const taxesArray = storedTaxes.data || []
            return Array.isArray(taxesArray) ? taxesArray : []
        } catch (error) {
            console.error('Error parsing taxesData from local storage:', error)
            return []
        }
    }

    // Process tax details
    const processTaxDetails = () => {
        if (!invData?.inv_details || invData.inv_details.length === 0) return []

        const storedTaxes = getTaxDetailsFromLocalStorage()
        
        // Find all products with VAT
        const productsWithVAT = invData.inv_details.filter(detail => 
            parseFloat(detail.product_vat) > 0
        )

        // If no products with VAT, return empty
        if (productsWithVAT.length === 0) return []

        // Find and consolidate unique taxes
        const consolidatedTaxes = productsWithVAT.reduce((acc, detail) => {
            // Find matching tax based on tax_id
            const matchingTax = storedTaxes.find(tax => 
                tax.id === parseInt(detail.tax_id)
            ) || {
                tax_name_ar: 'ضريبة القيمة المضافة',
                tax_name_en: 'VAT',
                tax_value: SETTING_INV_VAT_PERCENT
            }

            // Create tax info object
            const taxInfo = {
                name_ar: matchingTax.tax_name_ar?.trim() || 'ضريبة القيمة المضافة',
                name_en: matchingTax.tax_name_en?.trim() || 'VAT',
                value: matchingTax.tax_value
            }

            // Find existing tax in accumulator or add new
            const existingTaxIndex = acc.findIndex(
                t => t.taxInfo.name_ar === taxInfo.name_ar && 
                      t.taxInfo.value === taxInfo.value
            )

            if (existingTaxIndex !== -1) {
                // Add to existing tax's total VAT
                acc[existingTaxIndex].product_vat += parseFloat(detail.product_vat)
            } else {
                // Add new tax entry
                acc.push({
                    ...detail,
                    taxInfo,
                    product_vat: parseFloat(detail.product_vat)
                })
            }

            return acc
        }, [])

        return consolidatedTaxes
    }

    // Process tax details
    const taxDetails = processTaxDetails()

    // Compute total VAT
    const totalVAT = taxDetails.reduce((sum, detail) => sum + detail.product_vat, 0)

    return (
      
            <>
                { !isDelivery &&
                <div> 
                    <div className='my-3 border rounded text-xs'>
                        
                        {
                            discountAmount ? 
                            <div className='flex-col'>
                                <div className='flex justify-between px-3 py-2 border-b'>
                                    <div className='flex flex-col'>
                                        <span>الإجمالي قبل الخصم </span>
                                        <span>TOTAL BEFORE DISCOUNT</span>
                                    </div>
                                    <div className='flex flex-col justify-center '>{ formatter.format(totalAmount)  } </div>
                                </div>
                                <div className='flex justify-between px-3 py-2 border-b'>
                                    <div className='flex flex-col'>
                                        <span> الخصم </span>
                                        <span>DISCOUNT</span>
                                    </div>
                                    <div className='flex flex-col justify-center '>{formatter.format(discountAmount)}</div>
                                </div>
                                <div className='flex justify-between px-3 py-2 border-b'>
                                    <div>{t('invoice.TOTAL_AFTER_DISCOUNT')}</div>
                                    <div className=''>{formatter.format(totalAmount - discountAmount )} </div>
                                </div>
                            </div> : 
                            <div className='flex justify-between px-3 py-2 border-b'>
                                <div className='flex flex-col'>
                                    <span>الإجمالي الفرعي </span>
                                    <span>SUB TOTAL</span>
                                </div>
                                <div className='flex flex-col justify-center '>{formatter.format(totalAmount)}  </div>
                            </div>
                        }
                           {taxDetails.map((detail, index) => (
                        <div key={index} className='flex justify-between px-3 py-2 border-b'>
                            <div className='flex flex-col'>
                                <span>
                                    {detail.taxInfo.name_ar} ({detail.taxInfo.value}%)
                                </span>
                                <span>
                                    {detail.taxInfo.name_en} ({detail.taxInfo.value}%)
                                </span>
                            </div>
                            <div className='flex flex-col justify-center'>
                                {detail.product_vat.toFixed(2)} {SETTING_INV_CURRENCY}
                            </div>
                        </div>
                    ))}
                        <div className='flex justify-between px-3 py-2 border-b'>
                            <div className='flex flex-col'>
                                    <span>الإجمالي شامل الضريبة </span>
                                    <span>TOTAL</span>
                                </div>
                            <div className='font-bold flex flex-col justify-center '>{formatter.format(netAmount)}  </div>
                        </div>
                    </div>
                    {/* <div className='pt-2 flex flex-col justify-center items-center'>
                        { SETTING_PRICE_INCLUDE_VAT === 'true' ?
                            <div className='flex justify-center items-center text-xs text-slate-400 '>{t('invoice.priceIncludesVat')}</div> : 
                            <div className='text-xs text-slate-400 text-center'>{t('invoice.priceNotIncludesVat')}</div> 
                        }
                    </div> */}
                </div>}
                { SETTING_SHOW_TERMS_ON_PRINT === 'true' && invTerms && !isDelivery && 
    <div className='text-xs py-5 page-break'>
        <div className="header-space"></div>
        <div className='font-bold '>- {t('invoice.ConditionAndTerms')}</div>
        <div className='pt-1 px-2 '>{ parse(invTerms.replaceAll('\n','<br/>')) }</div>
    </div>
}
            </>
        
    )
}

export default InvoiceFooter