import { useState,useEffect} from 'react'
import { useCurrent, useFetch, usePut } from '../../../hooks';
import {  MwButton, MwSpinner} from '../../ui';
import {langObjType,objLangTextHandler,LangArrayToObjKeyValue} from '../../../utils/global'
import CategoryForm from './CategoryForm';
import CardHeadeWithActions from '../../ui/CardHeadeWithActions';
import { useTranslation } from 'react-i18next';

const EditCategory = ({id,setMode}) => {
    const [t,i18n ] = useTranslation('global')

   // let categoriesHandler = [];
    // public state
    const {currentLangList,currentTenantId,currentCompanyId,currentLangId} = useCurrent();
    const [formLang,setFormLang] = useState(currentLangId);
    const [errors,setErrors] = useState({});
    const [categories,setCategories] = useState([]);
    const categoryClasses = [
        {label: t('product.product'), value: 'product'},
        {label: t('product.service'), value: 'service'},
    ];
    
    const {data:dataFetchCategory,loading:loadingFetchCategory} = useFetch(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/categories/show/${id}`);
    const {data:resultPut,loading:loadingPost,error:errorPost,putData} = usePut();
    const {data:dataFetchResponse,loading,error} = useFetch(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/categories/${currentTenantId}/${currentCompanyId}`);
    
    const [loadingPage,setLoadingPage] = useState(true);
    const [categoryParentLabel,setCategoryParentLabel] = useState(t('product.Select_Main_Category'));
    const [categoryClassLabel,setCategoryClassLabel] = useState(t('product.SelectCategory'));

    // Start Form state //////
    const [categoryParent,setCategoryParent] = useState('main');
    const [categoryClass,setCategoryClass] = useState();
    const [isActive,setIsActive] = useState(true);
    const [isShowOnWebsite,setIsShowOnWebsite] = useState(true);
    const [categoryName,setCategoryName] = useState(langObjType());
    const [categoryDesc,setCategoryDesc] = useState(langObjType());
    const [categoryMetaKeywords,setCategoryMetaKeywords] = useState(langObjType());
    const [categoryMetaDesc,setCategoryMetaDesc] = useState(langObjType());
    const [categoryCode,setCategoryCode] = useState();

    // TODO: Functions
    const getCategory = () =>{
        let cats = [];
        dataFetchResponse.data.map((e) => {
            //const name = LangArrayToObjKeyValue(e.categoryName);
            if(e._id !== id)
                e.categoryName.forEach(name => {
                    cats.push( {label:name?.text,value:e._id,lang:name.lang})
                });
        });
        setCategories(cats);
    }

    const formValidate = () => {
        let errorObj = {};
        setErrors('');
        if(categoryParent==='sub'){
            errorObj = { ...errorObj, categoryParent : t('validation.selectSubCategory')  };
        }
        if(!categoryClass){
            errorObj = { ...errorObj, categoryClass : t('validation.selectCategoryClass') };
        }
        if(!categoryName[currentLangId]){
            errorObj = { ...errorObj, [`categoryName.${currentLangId}`] : t('validation.enterCategoryName')};
            setFormLang(currentLangId)
        }

        if(Object.keys(errorObj).length !== 0 ){
            setErrors(errorObj)
            return true
        }

        return false;
    }
    const submitHandler = () => {        
        const _data = {
            categoryParent:categoryParent === 'main' ? null : categoryParent.value  ,
            categoryClass,
            categoryCode:categoryParent === 'main' ? categoryCode : (categoryParent?.data?.categoryCode && !categoryCode.includs(categoryParent?.data?.categoryCode) ) ? categoryParent?.data?.categoryCode + '-' + categoryCode :categoryCode ,
            // categoryCode,
            categoryName: objLangTextHandler(categoryName),
            categoryDesc: objLangTextHandler(categoryDesc),
            categoryMetaKeywords: objLangTextHandler(categoryMetaKeywords),
            categoryMetaDesc: objLangTextHandler(categoryMetaDesc),
            isActive,
            isShowOnWebsite,
            tenantId:currentTenantId,
            companyId:currentCompanyId
        }
        
        if(formValidate()) return ;
        putData(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/categories/update/${id}`,_data);
        
    }
    // TODO: useEffect
    useEffect(() =>{
        if(dataFetchResponse){
            getCategory();
        }
    },[dataFetchResponse]);

    useEffect(() =>{
        if(resultPut){
            setMode('List');
        }
    },[resultPut])

    useEffect(() =>{
        if(dataFetchCategory){
            console.log('dataFetchCategory',dataFetchCategory);
            if( dataFetchCategory.data[0].categoryParent === null ) {
                    setCategoryParent('main') 
            } else{
                dataFetchCategory?.data[0].categoryParent?.categoryName.forEach(name => {
                    if(currentLangId === name.lang)
                    setCategoryParent( {label:name?.text,value:dataFetchCategory?.data[0].categoryParent._id,lang:name.lang})
                });
            }
            const labelClass = () => {
                if(dataFetchCategory.data[0].categoryClass === 'product' ){
                    return t('product.product')
                }else if(dataFetchCategory.data[0].categoryClass === 'service' ){
                    return t('product.service')
                }else{
                    return t('product.Other')
                }
            } 
            setCategoryClassLabel(labelClass());
            setCategoryClass(dataFetchCategory.data[0].categoryClass);
            setCategoryName(LangArrayToObjKeyValue(dataFetchCategory.data[0].categoryName))
            setCategoryDesc(LangArrayToObjKeyValue(dataFetchCategory.data[0].categoryDesc))
            setCategoryMetaKeywords(LangArrayToObjKeyValue(dataFetchCategory.data[0].categoryMetaKeywords))
            setCategoryMetaDesc(LangArrayToObjKeyValue(dataFetchCategory.data[0].categoryMetaDesc))
            setIsShowOnWebsite(dataFetchCategory.data[0].isShowOnWebsite)
            setIsActive(dataFetchCategory.data[0].isActive)
            setLoadingPage(false)
            setCategoryCode(dataFetchCategory.data[0]?.categoryCode)
        }
    },[dataFetchCategory]);
    

    return (
        <>
            {dataFetchCategory && !loadingPage ? <div className='flex flex-col gap-5 p-3 text-slate-500 text-sm' >
                    <CardHeadeWithActions title={t('product.UpdateCategory')}>
                            <MwButton type="cancelBtn" inGroup={true} onClick = {()=>setMode('List')} > {t('product.manageCategories')}  </MwButton>
                            <MwButton type="saveBtn" inGroup={true} onClick={submitHandler} >{t('product.save')}</MwButton>
                    </CardHeadeWithActions>
                    <CategoryForm
                        categories = {categories}
                        currentLangList = {currentLangList}
                        formLang = {formLang}
                        setFormLang = {setFormLang}
                        errors = {errors}
                        categoryParent = {categoryParent}
                        setCategoryParent = {setCategoryParent}
                        categoryClasses = {categoryClasses}
                        setCategoryClass = {setCategoryClass}
                        categoryName = {categoryName}
                        setCategoryName = {setCategoryName}
                        categoryDesc = {categoryDesc}
                        setCategoryDesc = {setCategoryDesc}
                        categoryMetaKeywords = {categoryMetaKeywords}
                        setCategoryMetaKeywords = {setCategoryMetaKeywords}
                        categoryMetaDesc = {categoryMetaDesc}
                        setCategoryMetaDesc = {setCategoryMetaDesc}
                        isShowOnWebsite = {isShowOnWebsite}
                        setIsShowOnWebsite = {setIsShowOnWebsite}
                        isActive = {isActive}
                        setIsActive = {setIsActive}
                        categoryCode = {categoryCode}
                        setCategoryCode = {setCategoryCode}
                    />
            </div> : <MwSpinner/>
            }
        </>
    )
}

export default EditCategory