import React,{useState,useEffect} from 'react'
import { CiFilter } from "react-icons/ci";
import { MwButton, MwInputText, MwSelector, MwSpinnerButton } from '../../components/ui';
import { useFetch } from '../../hooks';

const TransactionsFilter = ({
    filterState,
    paymentMethodSelected,
    setPaymentMethodSelected,
    setFilterState,
    typeSelected,
    setTypeSelected,
    userSelected,
    setUserSelected,
    currentTenantId,
    currentCompanyId,
    currentBranchId,
    currentLangId,
    filterHandler
}) => {
    const _listTypeStatus = [
        {
            label:'الكل',
            value:'all',
            lang: 'ar'
        },
        {
            label:'دفع',
            value:'out',
            lang: 'ar'
        },
        {
            label:'قبض',
            value:'in',
            lang: 'ar'
        },
        {
            label:'ALL',
            value:'all',
            lang: 'en'
        },
        {
            label:'PAYMENT',
            value:'out',
            lang: 'en'
        },
        {
            label:'Catch',
            value:'in',
            lang: 'en'
        },
    ];
    const [paymentMethodsList,setPaymentMethodsList]= useState([]);
    const {data:paymentMethodResult,loading:paymentMethodLoading} = useFetch(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/payment-methods/${currentTenantId}/${currentCompanyId}/${currentBranchId}`)

    const {data:usersResult,loading:usersLoading} = useFetch(`${process.env.REACT_APP_Auth_API_BASE_URL}/users/tenant/${currentTenantId}/${currentCompanyId}`);
    const [users,setUsers] = useState([]);

    useEffect(() => {
        if(usersResult){
            const _list = usersResult?.data.map(user => ({
                label: user.name,
                value: user._id,
                lang: currentLangId
            }));
            setUsers(_list);
            console.log('users',users)
        }
    },[usersResult]);

    useEffect(()=>{
        if(paymentMethodResult){
            const _list = paymentMethodResult.map(item => ({
                label:item.description.filter(desc => desc.lang === currentLangId)[0]?.name,
                value: item.id,
                lang: currentLangId
            }))
            setPaymentMethodsList(_list)
            console.log("paymentMethodResult",paymentMethodResult)
        }
    },[paymentMethodResult]);
    return (
        <div className='w-60 text-xs bg-slate-100 p-3 border rounded-xl'>
            <div className='w-full py-1 flex-1 flex  flex-col gap-3  items-start '>
                <div className='flex gap-1'>
                    <CiFilter size={16}/>
                    <span>التصفية</span>
                </div>
                <hr/>
                <div className='w-full'>
                    <MwInputText
                        inputType='date'
                        label='من تاريخ'
                        value={filterState.startDate}
                        onChange={(e)=>setFilterState((state)=>({...state,startDate:e.target.value}))}
                        />
                </div>
                <div className='w-full'>
                    <MwInputText
                        inputType='date'
                        label='الي تاريخ'
                        value={filterState.endDate}
                        onChange={(e)=>setFilterState((state) =>({...state,endDate:e.target.value}))}
                        />
                </div>
                <div className='w-full  relative z-40'>
                    <MwSelector
                        label={`طريقة الدفع`}
                        initalValue={`عرض الجميع`}
                        _data={paymentMethodsList}
                        selectedItem={paymentMethodSelected}
                        setSelectedItem={setPaymentMethodSelected}
                        dataType={`labelValue`}
                        withSearch={false}
                        withAddNew={false}
                        />
                </div>
                <div className='w-full  relative z-30'>
                    <MwSelector
                        label={`نوع العملية`}
                        initalValue={`عرض الجميع`}
                        _data={_listTypeStatus}
                        selectedItem={typeSelected}
                        setSelectedItem={setTypeSelected}
                        dataType={`labelValue`}
                        withSearch={false}
                        withAddNew={false}
                        />
                </div>
                <div className='w-full relative z-10'>
                    { !usersLoading ? <MwSelector
                        label={`المستخدم / الكاشير`}
                        initalValue={`عرض الجميع`}
                        _data={users}
                        selectedItem={userSelected}
                        setSelectedItem={setUserSelected}
                        dataType={`labelValue`}
                        withSearch={false}
                        withAddNew={false}
                        /> : <MwSpinnerButton withLabel={false}/>
                    }
                </div>
                <div className='w-full pt-3'>
                    <MwButton type='secondary' onClick={filterHandler} classNameCustom='w-full' >عرض النتائج</MwButton>
                </div>
            </div>
        </div>
    )
}

export default TransactionsFilter