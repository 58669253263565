import React,{useEffect,useState} from 'react'
import {BiChevronDown} from 'react-icons/bi'
import {AiOutlineSearch} from 'react-icons/ai'
import {MdAddBox} from 'react-icons/md'
import axios from 'axios';
import {  useTranslation } from 'react-i18next';
import { useUser, usePost } from '../../hooks';
import {LangArrayToObjKeyValue} from '../../utils/global';
import { useCurrent } from '../../hooks';
import MwInputText from './MwInputText';


const MwSelectorProduct = ({onChangeCallback,onCreateProduct,reloadProductList,withLabel=false,invalid=false,initalValue,disabled=false,hideSearchAndAdd=false}) => {
    const [t] = useTranslation('global')
    const {currentLangId} = useCurrent();
    const {getUser} = useUser();
    const [products,setProducts] = useState([]);
    const [productsFilter,setProductsFilter] = useState([]);
    const [listLength,setListLength] = useState(0);
    const [inputValue,setInputValue] = useState('');
    const [selectedItem,setSelectedItem] = useState();
    const [open,setOpen] = useState(false);
    const {data:resultPost,loading,postData} = usePost();
    
    const isProductsUpdated = () => {
        if(localStorage.getItem('isProductsUpdated')){
            return JSON.parse(localStorage.getItem('isProductsUpdated'))
            // return true;
        }else{
            return false;
        }
    }
    useEffect(() => {
        console.log('selectedItem',selectedItem);
        if(selectedItem){
            onChangeCallback(selectedItem);
        }
    },[selectedItem]);

    

    useEffect(() =>{

        // if(products.length === 0 || reloadProductList){
        //     postData(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/search/All`,{
        //         tenantId: getUser?.tenant_id,
        //         companyId: getUser?.company_id
        //     });
        // }

        const productStorage = localStorage.getItem('products');
        console.log('isProductsUpdated',isProductsUpdated());
        //if((productStorage && !reloadProductList) || !isProductsUpdated()) {
        if(!isProductsUpdated()) {
            setProducts(JSON.parse(productStorage))
            
        } 
        if(isProductsUpdated() || products.length === 0 || reloadProductList){
            postData(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/search/All`,{
                tenantId: getUser?.tenant_id,
                companyId: getUser?.company_id,
                //  : 'Sales' // تم تعليق هذا السطر للسماح بالبحث عن جميع أنواع المنتجات
            });
        }
    },[reloadProductList]);

    useEffect(()=>{
        if(resultPost && !loading){
            setProducts(resultPost.data)
            localStorage.setItem('products', JSON.stringify(resultPost.data));
            localStorage.setItem('isProductsUpdated','false')
        }
    },[resultPost,loading]);

    useEffect(() => {
        if (initalValue) {
             if (typeof initalValue === 'object' && initalValue.productId) {
                setSelectedItem(initalValue);
                setInputValue(LangArrayToObjKeyValue(initalValue.productName)?.[currentLangId] || 
                             initalValue.productName[0]?.text || 
                             initalValue.productName[1]?.text);
            } else {
                 setSelectedItem(null);
                setInputValue(initalValue);
            }
        }
    }, [initalValue, currentLangId]);

    useEffect(()=>{
        if(inputValue){
            const _list = products?.filter((product)=>{

                if(product.productType !== "Purchases" && product.productType !== "SalesAndPurchases") {
                    return false;
                }
                const productNameAr = product?.productName[0]?.text
                const productNameEn = product?.productName[1]?.text 
                const productBarcode = product?.productBarcode || '';
                const productCode = product?.productCode || '';
                
                if(productNameAr?.toLocaleLowerCase()?.indexOf(inputValue.toLocaleLowerCase()) !== -1 || 
                   productNameEn?.toLocaleLowerCase()?.indexOf(inputValue.toLocaleLowerCase()) !== -1 ||
                   productBarcode?.toLocaleLowerCase()?.indexOf(inputValue.toLocaleLowerCase()) !== -1 ||
                   productCode?.toLocaleLowerCase()?.indexOf(inputValue.toLocaleLowerCase()) !== -1){
                    return true;
                }});
            setProductsFilter(_list)          
            setListLength(_list?.length);
            console.log('search item',productsFilter)
            
        }
    },[inputValue]);



    const addProductHandler = () => {
        setOpen(false);
        onCreateProduct(true);
    }

    return (
        <>
        <div className='relative'>
            
            { withLabel && <label className={`text-xs text-slate-500 `} >{t('invoice.selectProduct')}</label> }
            <div 
                onClick={()=> {
                    !disabled && setOpen(!open);
                    setListLength(1);
                }}
                className={` h-9 bg-white w-full border flex items-center text-sm  justify-between rounded ${ !!invalid && 'border border-red-400' } ${disabled ? 'text-slate-200' : 'text-slate-300'} `}
                >
                {
                selectedItem ? 
                    <span className={` px-2 ${disabled ? ' text-slate-300' : 'text-slate-500'}`}>{LangArrayToObjKeyValue(selectedItem.productName)?.[currentLangId] || selectedItem.productName[0]?.text || selectedItem.productName[1]?.text}</span> : 
                    // initalValue ? initalValue : t('invoice.selectProduct')
                    <MwInputText 
                        disabled={disabled}
                        value={initalValue}
                        classNameInput =  'text-start px-3 border-0 font-bold'
                        onChange={(e)=>{
                            setInputValue(e.target.value);
                            onChangeCallback(e.target.value);
                        }
                        }
                    />
                } 
                <BiChevronDown size={20} className='border rounded m-2'/>
            </div>
            {productsFilter?.length > 0 && 
            <ul className={`bg-slate-50 border-b-2 border-x-2 z-50 overflow-y-auto absolute w-full left-0 right-0 rounded-b-lg ${open ? 'max-h-60 block' : 'max-h-0 hidden'}`}>
              { !hideSearchAndAdd && <div className='sticky top-0 p-3'>
                    <div className='text-end'>
                        <div className=' text-sky-700 absolute top-6 end-5'  onClick={addProductHandler} >
                        <MdAddBox size={24}/> 
                        </div>
                    </div>
                    <div className='flex items-center justify-between  px-2  bg-white rounded '>
                        <AiOutlineSearch size={18} className='text-gray-700' />
                        <input 
                            type="text"
                            placeholder='Enter Product Name'
                            className='placeholder:text-grray-700 p-2 outline-none p-3 w-full text-sm bg-white'
                            value={inputValue}
                            onChange={(e)=>setInputValue(e.target.value.toLowerCase())}
                            />
                    </div>
                </div>}
                <div>
                    {
                        productsFilter?.map((product)=>{
                        const productName = LangArrayToObjKeyValue(product?.productName)?.[currentLangId] || product?.productName[0]?.text || product?.productName[1]?.text
                        let productName2 =""
                        let productDesc =""
                        if(productName === product?.productName[0]?.text){
                            productName2 = product?.productName[1]?.text
                            productDesc = product?.productDesc[0]?.text
                        }else{
                            productName2 = product?.productName[0]?.text
                            productDesc = product?.productDesc[1]?.text
                        }
                        return <li 
                            key={product._id}
                            onClick={()=>{
                                setSelectedItem(product)
                                setOpen(false);
                                setInputValue("")
                            }}
                            className={`ps-5 py-1 border-b text-sm  hover:bg-sky-200 hover:text-white `}> 
                            <div className='flex gap-5 justify-between'>
                                <div>
                                    <div> {productName} </div>
                                    <div className='text-xs text-slate-400'>  {productName2} </div>
                                    <div className='text-xs text-slate-400' >{productDesc}</div>
                                    <div className='text-xs text-slate-400'>
    {product?.productBarcode && (
        <span className='me-2'>
            {t('common.barcode')}: {product.productBarcode}
        </span>
    )}
    {product?.productCode && (
        <span>
            {t('common.code')}: {product.productCode}
        </span>
    )}
</div>
                                </div>
                                <div className='text-xs font-bold text-slate-500 p-3'>
    <div className='flex flex-wrap gap-1 mb-1'>
        { product?.productBrand?.brandName?.length > 0 && <span className='bg-sky-200 rounded-lg px-2 py-1 inline-block'>
                {LangArrayToObjKeyValue(product?.productBrand?.brandName)?.[currentLangId]   || product?.productBrand?.brandName[0]?.text ||  product?.productBrand?.brandName[1]?.text  }     
            </span> }
        { product?.productCategory[0]?.categoryName?.length > 0 && <span className='bg-sky-200 rounded-lg px-2 py-1 inline-block'>
                { LangArrayToObjKeyValue(product?.productCategory[0]?.categoryName)?.[currentLangId]   || product?.productCategory[0]?.categoryName[0]?.text ||      product?.productCategory[0]?.categoryName[1]?.text  }       
            </span> }
    </div>
    <span className='bg-green-100 text-[10px] rounded-lg px-2 py-1 inline-block mt-1'>
        {t('common.qty')}: {product?.qty || 0}
    </span>
</div>
                            </div>
                            {}
                        </li>
                    })
                    }
                </div>
            </ul>}
        </div>
        </>
    )
}

export default MwSelectorProduct;;;