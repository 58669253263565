import React,{useEffect,useState} from 'react'
import { MwInputText,MwSelector,MwSwitch } from '../../ui'
import { useCurrent, useFetch } from '../../../hooks'

const StockAndPrice = ({
    productCode,
    setProductCode,
    productPrice,
    setProductPrice,
    productBarcode,
    setProductBarcode,
    productFactoryCode,
    setProductFactoryCode,
    isStock,
    setIsStock,
    codeInStock,
    setCodeInStock,
    qty,
    setQty,
    cost,
    setCost,
    weightProduct,
    setWeightProduct,
    sizeProduct,
    setSizeProduct,
    errors,
    uomTemplateSelected,
    setTemplateUomSelected,
    uomSaleSelected,
    setUomSaleSelected,
    uomPurchaseSelected,
    setUomPurchesSelected
}) => {
    const {currentTenantId,currentCompanyId,currentLangId} = useCurrent();

    const {data:uomData,loading:uomLoading} = useFetch(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/inventory/uom/tenant/${currentTenantId}`)

    const [uomTemplateList,setTemplateUomList] = useState([]);
    const [uomList,setUomList] = useState([]);
    
     useEffect(() => {
            if(uomData && !uomLoading ){
                let itemList = [];
                uomData.data.map((el)=>itemList?.push({label:el?.name,value:el?.id,lang:currentLangId,data:el}));
                setTemplateUomList(itemList)
                let itemList2 = [];
                uomTemplateSelected?.data?.uom_master?.uom_details.map((el)=>itemList2?.push({label:el?.short_name,value:el?.id,lang:currentLangId,data:el}));
                setUomList(itemList2)
            }
        },[uomData,uomTemplateSelected]);


        
    return (
        <div className='p-3'>
            <div className=' '>
                <MwInputText
                    label='كود المنتج'
                    id='productCode' 
                    value={productCode} 
                    onChange={(e)=>setProductCode(e.target.value)}
                />
            </div>
            <MwSelector
                        label={`قالب الوحدة`}
                        initalValue='أختار قالب الوحدة'
                        dataType = 'labelValue'
                        _data={uomTemplateList}
                        withAddNew = {false}
                        selectedItem={uomTemplateSelected}
                        setSelectedItem={setTemplateUomSelected}
                        className={`z-10`}
                    />
            <div className=' my-2 relative '>
                <MwInputText
                    label='سعر البيع'
                    id='productPrice' 
                    invalid = {!!errors[`price`]}
                    invalidMsg = {errors[`price`]}
                    value={productPrice} 
                    onChange={(e)=>setProductPrice(e.target.value)}
                />
                { uomTemplateSelected && <div className='relative flex flex-row-reverse    '>
                    <MwSelector
                        initalValue='أختار  الوحدة'
                        dataType = 'labelValue'
                        _data={uomList}
                        withAddNew = {false}
                        selectedItem={uomSaleSelected}
                        setSelectedItem={setUomSaleSelected}
                        className={`z-20 w-32 absolute -top-8 end-0`}
                    />
                </div> 
                }
            </div>
            <div className=' my-2 relative '>
                <MwInputText
                    label=' سعر الشراء'
                    id='cost' 
                    invalid = {!!errors[`cost`]}
                    invalidMsg = {errors[`cost`]}
                    value={cost} 
                    onChange={(e)=>setCost(e.target.value)}
                />
                { uomTemplateSelected && <div className='relative flex flex-row-reverse    '>
                    <MwSelector
                        initalValue='أختار  الوحدة'
                        dataType = 'labelValue'
                        _data={uomList}
                        withAddNew = {false}
                        selectedItem={uomPurchaseSelected}
                        setSelectedItem={setUomPurchesSelected}
                        className={`z-10 w-32 absolute -top-8 end-0`}
                    />
                </div> 
                }
            </div>
            <div className=' my-2'>
                <MwInputText
                    label='باركود المنتج'
                    id='productBarcode' 
                    value={productBarcode} 
                    onChange={(e)=>setProductBarcode(e.target.value)}
                />
            </div>
            <div className=' my-2'>
                <MwInputText
                    label='كود المصنع'
                    id='productFactoryCode' 
                    value={productFactoryCode} 
                    onChange={(e)=>setProductFactoryCode(e.target.value)}
                />
            </div>
            <div className='flex justify-between items-center py-4  border-b mb-2'>
                    <label className='text-xs text-slate-400'> يستخدم المستودع / لا يستخدم المستودع</label>
                    <MwSwitch custKey='isStock' isActive={isStock} setIsActive={setIsStock} onChangeSwitch={() => setIsStock(!isStock)} />
            </div>
            <div className=' my-2'>
                <MwInputText
                    label='كود المستودع'
                    id='codeInStock' 
                    value={codeInStock} 
                    onChange={(e)=>setCodeInStock(e.target.value)}
                />
            </div>
            <div className=' my-2'>
                <MwInputText
                    inputType='Number'
                    label='الكمية'
                    id='qty' 
                    value={qty} 
                    onChange={(e)=>setQty(e.target.value)}
                />
            </div>
            <div className=' my-2'>
                <MwInputText
                    label='الوزن'
                    id='weightProduct' 
                    value={weightProduct} 
                    onChange={(e)=>setWeightProduct(e.target.value)}
                />
            </div>
            <div className=' my-2'>
                <MwInputText
                    label='الحجم'
                    id='sizeProduct' 
                    value={sizeProduct} 
                    onChange={(e)=>setSizeProduct(e.target.value)}
                />
            </div>
        </div>
    )
}

export default StockAndPrice