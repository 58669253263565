import {useState,useEffect} from 'react'
import { useCurrent, usePut,useFetch } from '../../../hooks';
import FormLangBar from '../../FormLangBar';
import {langObjType,objLangTextHandler,LangArrayToObjKeyValue} from '../../../utils/global'
import { MwButton,MwInputText,MwTextArea,MwSwitch, MwSpinner } from '../../ui';
import CardHeadeWithActions from '../../ui/CardHeadeWithActions';
import TagForm from './TagForm';
import { useTranslation } from 'react-i18next';

const EditTag = ({id,setMode}) => {
    const {currentLangList,currentTenantId,currentCompanyId,currentLangId} = useCurrent();
    const [formLang,setFormLang] = useState(currentLangId);
    const [errors,setErrors] = useState({});
    const [loadingPage,setLoadingPage] = useState(true);
    const [t,i18n ] = useTranslation('global')
    const {data:dataFetchTags,loading:loadingFetchTags} = useFetch(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/tags/show/${id}`);
    const {data:resultPut,loading:loadingPut,error:errorPost,putData} = usePut();

    // Start Form state //////
    const [isActive,setIsActive] = useState(true);
    const [tagName,setTagName] = useState(langObjType());
    const [tagDesc,setTagDesc] = useState(langObjType());

    const formValidate = () => {
        let errorObj = {};
        setErrors('');
        if(!tagName[currentLangId]){
            errorObj = { ...errorObj, [`tagName.${currentLangId}`] : t('product.Tagentered') };
            setFormLang(currentLangId)
        }

        if(Object.keys(errorObj).length !== 0 ){
            setErrors(errorObj)
            return true
        }
        return false;
    }

    const submitHandler = () => {
        
        const _data = {
            tagName: objLangTextHandler(tagName),
            tagDesc: objLangTextHandler(tagDesc),
            isActive,
            tenantId:currentTenantId,
            companyId:currentCompanyId
        }
        
        if(formValidate()) return ;
        putData(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/tags/update/${id}`,_data);
        
    }

    useEffect(() =>{
        if(resultPut){
            setMode('List');
        }
    },[resultPut])

    useEffect(() =>{
        if(dataFetchTags){
            console.log(dataFetchTags);
            setTagName(LangArrayToObjKeyValue(dataFetchTags.tagName))
            setTagDesc(LangArrayToObjKeyValue(dataFetchTags.tagDesc))
            setIsActive(dataFetchTags.isActive)
            setLoadingPage(false)
        }
    },[dataFetchTags]);

    return (
        <>
            { dataFetchTags && !loadingPage ? <div className='flex flex-col gap-3 px-3 text-slate-500 text-sm p-3' >
                <CardHeadeWithActions title={t('product.updateTag')}>
                    <MwButton type="cancelBtn" inGroup={true} onClick = {()=>setMode('List')} > {t('product.tagmanagement')}  </MwButton>
                    <MwButton type="saveBtn" inGroup={true} onClick={submitHandler} >{t('product.save')}</MwButton>
                </CardHeadeWithActions>
                <TagForm
                        currentLangList = {currentLangList}
                        formLang = {formLang}
                        setFormLang = {setFormLang}
                        errors = {errors}
                        tagName = {tagName}
                        setTagName = {setTagName}
                        tagDesc = {tagDesc}
                        setTagDesc = {setTagDesc}
                        isActive = {isActive}
                        setIsActive = {setIsActive}
                    />
            </div> : <MwSpinner/> }
        </> 
    )
}

export default EditTag