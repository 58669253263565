import React,{useState} from 'react'
import {BiChevronDown} from 'react-icons/bi'
import {AiOutlineSearch} from 'react-icons/ai'
import {MdAddBox} from 'react-icons/md'
import { useCurrent } from '../../hooks';
import CustomerSelectorItem from '../CustomerSelectorItem';


const MwSelector = ({label,_data,dataType,onClickNew,setSelectedItem,selectedItem,initalValue='Choose',withSearch=true,withLabel=true,withAddNew=true,className,styleList,invalid,invalidMsg}) => {
    const [inputValue,setInputValue] = useState('');
    const {currentLangId} = useCurrent()
    const [open,setOpen] = useState(false);

    console.log("_data_data",_data)

    const onSelectedHandler = (item) => {
        setSelectedItem({...item,label:item.label})
        setOpen(false);
        setInputValue("")
    }
    const dataList = _data &&  _data?.map((item,index) => {
        if(dataType === 'customer' || dataType === 'salesman'){
            return <CustomerSelectorItem 
                        key={item._id}
                        item={item} 
                        setSelectedItem={setSelectedItem} 
                        setOpen={setOpen}
                        setInputValue={setInputValue}
                        inputValue={inputValue}
                        />
        }

        if(dataType === 'labelValue'){
            if(item?.lang && item.lang.trim() === currentLangId){
                return (
                    <div 
                        className={`px-4 py-2 border-t border-slate-300 cursor-default text-sm hover:bg-slate-300 hover:font-bold ${item?.label?.toLowerCase().startsWith(inputValue) ? 'block' : 'hidden'}`}
                        key={index}
                        onClick={() => onSelectedHandler(item)}
                    >
                        {item.label}
                    </div>
                );
            } else {
                return (
                    <div 
                        className={`px-4 py-2 border-t border-slate-300 cursor-default text-sm hover:bg-slate-300 hover:font-bold ${item?.label?.toLowerCase().includes(inputValue.toLowerCase()) ? 'block' : 'hidden'}`}
                        key={index}
                        onClick={() => onSelectedHandler(item)}
                    >
                        {item.label}
                    </div>
                );
            }
        }


        if (dataType === 'salesmansTypes') {
            // Find the appropriate text based on language
            const displayText = item.typeName.find(name => name.lang === currentLangId)?.text || 
                                item.typeName[0]?.text || 'No Name';
            
            // Create a label property for consistency with the rest of the component
            const itemWithLabel = {
                ...item,
                label: displayText
            };
            
            return <div
                className={`px-4 py-2 border-t border-slate-300 cursor-default text-sm hover:bg-slate-300 hover:font-bold ${displayText.toLowerCase().includes(inputValue.toLowerCase()) ? 'block' : 'hidden'}`}
                key={item._id}
                onClick={() => onSelectedHandler(itemWithLabel)}
            >
                {displayText}
            </div>;
        }

    })

    const displayLabel = () => {
        if (!selectedItem || selectedItem === 'sub') {
            return initalValue;
        }
        
        if (dataType === 'salesmansTypes' && selectedItem.typeName) {
            // Find the text in the selected language
            const text = selectedItem.typeName.find(name => name.lang === currentLangId)?.text || 
                         selectedItem.typeName[0]?.text || selectedItem.label;
            return text;
        }
        
        return selectedItem.label;
    };
    return (
        <>
            <div className={` z-10 ${open && ' relative '} ${className}`}>
                <label className='text-xs text-slate-400'> {label} </label>
                <div
                    onClick={()=>setOpen(!open)}
                    className={`cursor-default flex text-xs bg-white text-slate-600 w-full h-8 px-2 items-center justify-between rounded border  ${ invalid && ' border-red-500'}  ${styleList} `} >
<span className={selectedItem && selectedItem !== 'sub' ? 'font-bold' : ''}>
    {displayLabel()}
</span>                        <BiChevronDown size={20}/>
                </div>
                <ul className={`bg-slate-200 border border-slate-200 shadow-lg absolute inset-x-0 z-50 mt-1 rounded-b-lg overflow-y-auto ${open ? 'max-h-60 block' : 'max-h-0 hidden'}`}>                       {withSearch && <div className='sticky top-0 p-3 bg-slate-200 border-b border-slate-100'>
                        { 
                        withAddNew && <div className='text-end'>
                            <div className='text-sky-700 absolute top-4 end-4'  
                            onClick={()=>{ 
                                onClickNew()
                                setOpen(false) 
                            }}
                            > 
                            <MdAddBox size={24}/> </div>
                        </div>
                        }
<div className='flex items-center justify-between px-2 bg-white rounded'>     <AiOutlineSearch size={18} className='text-gray-600' />
     <input 
         type="text"
         placeholder={label}
         className='placeholder:text-gray-400 outline-none text-xs p-2 w-full bg-white'         value={inputValue}
         onChange={(e)=>setInputValue(e.target.value.toLowerCase())}
     />
 </div>
                    </div> }
                    <div className='mb-2 [&>*]:border-b [&>*]:border-slate-300 [&>*:last-child]:border-b-0 '>
                        {dataList}
                    </div>
                </ul>
                { invalidMsg && <div className='text-xs text-red-500'>{invalidMsg}</div> }
            </div>
        </>
    )
}

export default MwSelector