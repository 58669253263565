import React from 'react'
import {  useTranslation } from 'react-i18next';

const InvoiceBody = ({
    customerName,
    salesmanName,
    invProducts,
    isDelivery,
    SETTING_INV_CURRENCY,
    inv_no ,
    inv_date,  
    inv_date_due  ,
    formatter,
    SETTING_INV_VAT_PERCENT
}) => {
    const [t] = useTranslation('global')
    const invProductsList = invProducts.map((prod) => (
        <div key={prod.id} className={`flex justify-between border-b  ${ prod.is_returned && 'bg-orange-50'}`}>
            <div className='flex-1 p-2 border-e'>{prod.product_name} {prod.is_returned && <span className='font-bold text-orange-300'>[مرتجع]</span> } </div>
            {!isDelivery && <div className='w-20 text-center  border-e p-2'>{prod.price } </div>}
            <div className='w-16 text-center border-e p-2'>{prod.qty}</div>
            {!isDelivery && <div className='w-24 text-center border-e p-2'>{prod.total_price } </div>}
            {!isDelivery && <div className='w-24 text-center border-e p-2'>{prod.product_discount } </div>}
            {!isDelivery && <div className='w-24 text-center border-e p-2'>{prod.product_net_total } </div>}
            {!isDelivery && <div className='w-24 text-center border-e p-2'>{prod.product_vat } </div>}
            {!isDelivery && <div className='w-24 text-center border-e p-2'>{prod.product_net_total_with_vat } </div>}
        </div>
    ));

    // const invProductsList = invProducts?.map((prod) => (
    //     <div key={prod.id} className='flex justify-between border-b p-1 '>
    //         <div className='flex-1 px-2'>{prod.product_name}</div>
    //         <div className='w-16 text-center '>{prod.qty}</div>
    //         {!isDelivery && <div className='w-20 text-center'>{prod.price} {SETTING_INV_CURRENCY}</div>}
    //         {!isDelivery && <div className='w-24 text-center'>{prod.total_price} {SETTING_INV_CURRENCY}</div>}
    //     </div>))

    return (
        <>
            <div className='  rounded-lg mb-4'>
                
                
                <div className='flex items-center justify-center font-bold   py-2'> 
                <div className='flex flex-col text-center'>
                        <span>عرض سعر </span>
                        <span> Proposal Quotation
                        </span>
                        </div>
                </div>
                
                {/*  */}
                <div className='flex justify-between text-xs rounded-t-lg border  rounded-lg '>
                    <div className='flex-1 flex items-center justify-between gap-3 p-2'>
                        <div className='flex flex-col text-center'>
                        <span>العميل </span>
                        <span>   Customer</span>
                        </div>
                        <span className='font-bold'>{customerName}</span>
                    </div>
                    <div className='border-l border-r flex-1 flex items-center justify-between gap-3 p-2'>
                        <div className='flex flex-col text-center'>
                            <span> المندوب  </span>
                            <span>   Salesman</span>
                        </div>
                        <span className='font-bold'>{salesmanName}</span>
                    </div>
                    {/* <div className='flex-1 flex items-center justify-between gap-3 p-2'>
                        <div className='flex flex-col text-center'>
                            <span>تاريخ الاستحقاق</span>
                            <span>DUE DATE</span>
                        </div>
                        <span className='font-bold'>{inv_date_due}</span>
                    </div> */}
                </div>
                <div className='flex justify-between text-xs rounded-t-lg border mt-5 '>
                    <div className='flex-1 flex items-center justify-between gap-3 p-2'>
                        <div className='flex flex-col text-center'>
                        <span>رقم العرض:</span>
                        <span>ISSUE DATE</span>
                        </div>
                        <span className='font-bold'>{inv_no?.substring(0,10)}</span>
                    </div>
                    <div className='border-l border-r flex-1 flex items-center justify-between gap-3 p-2'>
                        <div className='flex flex-col text-center'>
                            <span>تاريح الفاتورة</span>
                            <span>INVOICE DATE</span>
                        </div>
                        <span className='font-bold'>{inv_date}</span>
                    </div>
                    {/* <div className='flex-1 flex items-center justify-between gap-3 p-2'>
                        <div className='flex flex-col text-center'>
                            <span>تاريخ الاستحقاق</span>
                            <span>DUE DATE</span>
                        </div>
                        <span className='font-bold'>{inv_date_due}</span>
                    </div> */}
                </div>
                {/*  */}
                <div className='flex justify-between text-xs rounded-t-lg border-b border'>
                    <div className='flex-1 flex items-center justify-between gap-3 p-2'>
                        <div className='flex flex-col text-center'>
                            <span>تاريخ الاصدار</span>
                            <span>ISSUE DATE</span>
                        </div>
                        <span className='font-bold'>{inv_date?.substring(0,10)}</span>
                    </div>
                    <div className='border-l border-r flex-1 flex items-center justify-between gap-3 p-2'>
                        <div className='flex flex-col text-center'>
                            <span>رقم الفاتورة</span>
                            <span>INVOICE NO</span>
                        </div>
                        <span className='font-bold'>{inv_no}</span>
                    </div>
                    <div className='flex-1 flex items-center justify-between gap-3 p-2'>
                        <div className='flex flex-col text-center'>
                            <span>تاريخ الاستحقاق</span>
                            <span>DUE DATE</span>
                        </div>
                        <span className='font-bold'>{inv_date_due}</span>
                    </div>
                </div>
                
                <div  className='flex justify-between items-center p-3 bg-slate-10 mt-10 text-xs font-bold border-t border-r border-l rounded-t'>
                    <div className='flex-1 text-center  flex flex-col'>
                        <span>اسم المنتج - الخدمة</span>
                        <span>PRODUCT / SERVICE NAME</span>
                    </div>
                    {
                    !isDelivery && 
                        <div className='w-20 text-center flex flex-col '>
                            <span>سعر الوحدة</span>
                            <span>PRICE UNIT</span>
                        </div>
                    }
                    <div className='w-16 text-center flex flex-col'>
                        <span>{t('invoice.quantity')}</span>
                        <span>QUANTITY</span>
                    </div>
                    {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>السعر</span>
                        <span>PRICE</span>
                        </div>}
                    {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>الخصم</span>
                        <span>PRICE</span>
                        </div>}
                    {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>إج. ق الضريبة </span>
                        <span>T.A DISCOUNT</span>
                        </div>}
                    {!isDelivery && <div className='w-24 text-center  flex flex-col'>
                        <span>الضريبة  </span>
                        <span>VAT  </span>
                        </div>}
                    {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>إج. ب الضريبة</span>
                        <span>T.A VAT</span>
                        </div>}
                </div>
                <div className='border '>{invProductsList}</div>
            </div>
        </>
    )
}

export default InvoiceBody