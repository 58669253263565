import React from 'react';

const ToggleSwitch = ({ id, label, checked, onChange }) => {
  return (
    <div className="flex items-center gap-3">
      <label 
        htmlFor={id} 
        className="relative inline-flex items-center cursor-pointer"
      >
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={onChange}
          className="sr-only peer"
        />
        <div className="w-11 h-6 bg-gray-200 rounded-full peer 
                      peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full 
                      peer-checked:after:border-white after:content-[''] after:absolute 
                      after:top-[2px] after:right-[2px] after:bg-white after:border-gray-300 
                      after:border after:rounded-full after:h-5 after:w-5 after:transition-all
                      peer-checked:bg-blue-500 peer-hover:after:scale-95">
        </div>
      </label>
      <span className="text-sm font-medium text-gray-700">
        {label}
      </span>
    </div>
  );
};

export default ToggleSwitch;