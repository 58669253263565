import {useEffect, useState} from 'react'
import {MdOutlineAddBox} from 'react-icons/md'
import { ConfirmDelete, MwButton, MwInputText, MwSpinnerButton } from '../../components/ui';
import { useCurrent, useDelete, usePost } from '../../hooks';
import ListProductsItems from './ListProductsItems';
import NoDataFound from '../ui/NoDataFound';
import CardHeadeWithActions from '../ui/CardHeadeWithActions';
import {BiSearch} from 'react-icons/bi'
import {LangArrayToObjKeyValue} from '../../utils/global';
import { useTranslation } from 'react-i18next'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import ProductFilter from '../Common/ProductFilter';


const ListProducts = ({idSelected,setIdSelected,setMode}) => {
    const [t,i18n ] = useTranslation('global')
  const isRTL = i18n.dir() === 'rtl';

    const [products,setProducts] = useState();
    const [loadingProducts,setLoadingProducts] = useState(false);
    const [term,setTerm] = useState();
    const [productsFilter,setProductsFilter] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [filteredByPanel, setFilteredByPanel] = useState(null);


 const handlePageChange = (pageNumber) => {
  setCurrentPage(pageNumber);
};

const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = itemsPerPage === 20 
  ? productsFilter?.slice(indexOfFirstItem, indexOfLastItem) 
  : productsFilter;
const totalPages = Math.ceil((productsFilter?.length || 0) / itemsPerPage);
    
    const { currentTenantId ,currentCompanyId,currentLangId} = useCurrent();
    const [showConfirmDelete,setShowConfirmDelete] = useState(false);
    const {data:postResult,loading:postLoading,error,postData} = usePost();
    const {data:deleteResult ,loading:deleteLoading,deleteItem} = useDelete()
    
    const getProducts = () =>{
        postData(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/search/All`,{
            tenantId: currentTenantId,
            companyId: currentCompanyId
        })
    }



    const onChangeSearch = (newValue) => {
       // setData(newValue);
    }
    const editHandeler = (id) =>{
        setIdSelected(id)
        setMode('Edit');
    }
    // const deleteHandeler = (id) =>{
    //     setIdSelected(id)
    //     setShowConfirmDelete(true);
    // }
    const deleteHandeler = (id) => {
               deleteItem(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/delete/${id}`)
           }
    // const confirmDeleteHandler = () =>{
    //     deleteItem(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/delete/${idSelected}`)
    // }

    useEffect(() => {
        getProducts();
    },[]);

    useEffect(() => {
      if (postResult) {
          setProducts(postResult.data);
          setProductsFilter(postResult.data);
          console.log('Products',postResult.data)

      }
  }, [postResult]);

 useEffect(() => {
  if (postResult) {
       const originalData = [...postResult.data];
      
       const sortedData = originalData.sort((a, b) => {
           const codeA = parseInt(a.productCode) || a.productCode;
          const codeB = parseInt(b.productCode) || b.productCode;
          
           if (typeof codeA === 'number' && typeof codeB === 'number') {
              return codeA - codeB;  
          } else {
              return String(codeA).localeCompare(String(codeB));  
          }
      });
      
      // تحديث الحالة
      setProducts(sortedData);
      setProductsFilter(sortedData);
  }
}, [postResult]);


    useEffect(()=>{
        if(deleteResult && !deleteLoading){
            setShowConfirmDelete(false);
            getProducts();
        }
    },[deleteResult]);

    const onChangeHandler = () => {
      setCurrentPage(1);
      
      // تحديد المنتجات المراد البحث فيها (إما جميع المنتجات أو المنتجات المفلترة)
      const productsToSearch = filteredByPanel || products;
      
      if (term) {
          const searchTerm = term.toString().toLowerCase().trim();
          const _list = productsToSearch?.filter((product) => {
              // الحصول على اللغة الحالية
              const currentLang = i18n.language;
              const defaultLang = 'en';
              
              // اسم المنتج باللغة الحالية أو الافتراضية
              const productName = product?.productName?.find(item => item.lang === currentLang)?.text || 
                                 product?.productName?.find(item => item.lang === defaultLang)?.text || 
                                 product?.productName?.[0]?.text || '';
              
              // وصف المنتج
              const productDesc = product?.productDesc?.find(item => item.lang === currentLang)?.text || 
                                product?.productDesc?.find(item => item.lang === defaultLang)?.text || 
                                product?.productDesc?.[0]?.text || '';
              
              // اسم العلامة التجارية
              const brandName = product?.productBrand?.brandName?.find(item => item.lang === currentLang)?.text || 
                               product?.productBrand?.brandName?.find(item => item.lang === defaultLang)?.text || 
                               product?.productBrand?.brandName?.[0]?.text || '';
              
              // اسم التصنيف
              const categoryName = product?.productCategory?.[0]?.categoryName?.find(item => item.lang === currentLang)?.text || 
                                  product?.productCategory?.[0]?.categoryName?.find(item => item.lang === defaultLang)?.text || 
                                  product?.productCategory?.[0]?.categoryName?.[0]?.text || '';
              
              // كود المنتج
              const productCode = product?.productCode || '';
              
              // التحقق من وجود مصطلح البحث في أي من هذه الحقول
              return productName.toLowerCase().includes(searchTerm) || 
                     productDesc.toLowerCase().includes(searchTerm) || 
                     brandName.toLowerCase().includes(searchTerm) || 
                     categoryName.toLowerCase().includes(searchTerm) || 
                     productCode.toLowerCase().includes(searchTerm);
          });
          setProductsFilter(_list);
      } else {
          setProductsFilter(productsToSearch);
      }
      setLoadingProducts(false);
  }
  
  // 6. تعديل useEffect للتعامل مع مصطلح البحث
  useEffect(() => {
      if (!term && term !== '') {
          setProductsFilter(filteredByPanel || products);
          setLoadingProducts(false);
          return;
      }
      
      setLoadingProducts(true);
      const timeout = setTimeout(() => {
          onChangeHandler();
      }, 500);
      
      return () => {
          clearTimeout(timeout);
      }
  }, [term, filteredByPanel, products])
  
  // 7. إضافة useEffect للاستجابة لتغييرات الفلتر
  useEffect(() => {
      if (filteredByPanel === null) {
          // لا توجد مرشحات نشطة، استخدم جميع المنتجات
          if (!term) {
              setProductsFilter(products);
          } else {
              onChangeHandler();
          }
      } else {
          // مرشحات نشطة، استخدم المنتجات المفلترة
          if (!term) {
              setProductsFilter(filteredByPanel);
          } else {
              // إعادة تشغيل البحث على المنتجات المفلترة
              onChangeHandler();
          }
      }
  }, [filteredByPanel]);


    return (
        <>
           
            <div className='text-slate-500 bg-slate-50 rounded-lg z-30 h-screen max-h-[calc(100vh-180px)]   text-xs py-2'>
                {/* <CardHeadeWithActions title={t('product.productsList')}>
                </CardHeadeWithActions> */}
                    <div className='    flex gap-2 justify-between '>
                       <ProductFilter 
    products={products} 
    onFilterChange={(filtered) => setFilteredByPanel(filtered)} 
  />
         
                          
                       
                        <div className={` relative w-[50%]   `}>
                            <BiSearch className='absolute top-2 start-3' size={16}/>
                            <MwInputText 
                                classNameInput={`bg-slate-50 rounded-xl `}
                                placeholder = {t('product.searchProduct')}
                                value = {term}
                                onChange = {(e) => setTerm(e.target.value)}
                                />
                        </div>

                        
                    </div>
                { (postLoading || loadingProducts) && <MwSpinnerButton  withLabel={false} isFullCenter={true} />}
                {products?.length > 0 ? (
  <div className=' h-[calc(100%-4rem)] overflow-y-auto custom-scrollbar mt-1'>
    {currentItems?.map((item, index) => (
      <ListProductsItems 
        key={item._id} 
        item={item} 
        index={indexOfFirstItem + index} 
        editHandeler={editHandeler} 
        deleteHandeler={deleteHandeler} 
      />
    ))}
    {/* Add pagination controls */}
    {totalPages > 1 && (
  <div className="flex justify-center gap-2 mt-4 pb-4">
    <button
      onClick={() => {
        setCurrentPage(1);
        setItemsPerPage(productsFilter?.length || itemsPerPage);
      }}
      className={`px-3 py-1 rounded ${
        itemsPerPage !== 20 ? 'bg-indigo-600 text-white' : 'bg-slate-200 hover:bg-slate-300'
      }`}
    >
      {t('common.all')}
    </button>
    
    {totalPages > 5 && currentPage > 3 && (
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        className="px-3 py-1 rounded bg-slate-200 hover:bg-slate-300"
      >
            {i18n.language === 'ar' ? <MdKeyboardArrowRight size={20} /> : <MdKeyboardArrowLeft size={20} />}

      </button>
    )}
    
    {Array.from({ length: totalPages }, (_, i) => i + 1)
      .filter(number => {
        if (totalPages <= 5) return true;
        if (number === 1 || number === totalPages) return true;
        return Math.abs(currentPage - number) < 3;
      })
      .map((number) => (
        <button
          key={number}
          onClick={() => {
            handlePageChange(number);
            setItemsPerPage(20);
          }}
          className={`px-3 py-1 rounded ${
            currentPage === number && itemsPerPage === 20
              ? 'bg-indigo-600 text-white'
              : 'bg-slate-200 hover:bg-slate-300'
          }`}
        >
          {number}
        </button>
      ))}
    
    {totalPages > 5 && currentPage < totalPages - 2 && (
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        className="px-3 py-1 rounded bg-slate-200 hover:bg-slate-300"
      >
           {i18n.language === 'ar' ? <MdKeyboardArrowLeft size={20} /> : <MdKeyboardArrowRight size={20} />}

      </button>
    )}
  </div>
)}
  </div>
) : (
  !postLoading && <NoDataFound msg={`لا يوجد منتجات`}/>
)}
            </div>
        </>
    )
}

export default ListProducts