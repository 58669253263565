import {useState,useEffect} from 'react'
import {MdOutlineAddBox} from 'react-icons/md'
import { useCurrent,useFetch,useDelete } from '../../../hooks';
import ListBrandsItem from './ListBrandsItem';
import { ConfirmDelete, MwButton } from '../../ui';
import NoDataFound from '../../ui/NoDataFound';
import CardHeadeWithActions from '../../ui/CardHeadeWithActions';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const ListBrands = ({idSelected,setIdSelected,setMode}) => {
     const [t,i18n ] = useTranslation('global')
    const {currentTenantId,currentCompanyId} = useCurrent();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(13);
    const [showConfirmDelete,setShowConfirmDelete] = useState(false);
    const {data:brands,loading,error,refreshHandler} = useFetch(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/brands/${currentTenantId}/${currentCompanyId}`);
    const {data:deleteResult,loading:deleteLoading,deleteItem} = useDelete()
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = itemsPerPage === 13 
    ? (brands?.data ? brands.data.slice(indexOfFirstItem, indexOfLastItem) : [])
    : (brands?.data || []); 
       const totalPages = brands?.data ? Math.ceil(brands.data.length / itemsPerPage) : 0;

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const editHandeler = (id) => {
        setIdSelected(id)
        setMode('Edit')
    } 

    const deleteHandeler = (id) => {
        setIdSelected(id)
        setShowConfirmDelete(true);
    }

    const confirmDelete = () => {
        deleteItem(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/brands/delete/${idSelected}`)
    }

    useEffect(() =>{
        if(deleteResult){
            setShowConfirmDelete(false);
            refreshHandler();
        }
    },[deleteResult]);

    return (
        <>
            <div className='  text-slate-500 bg-slate-50 rounded-lg text-xs p-3 h-full '>
                
                <ConfirmDelete onDeleteHandler={()=>confirmDelete()} onShow={showConfirmDelete} setOnShow={setShowConfirmDelete} msg={t('product.confirmDeleteBrand')}/>
                
                <CardHeadeWithActions title={t('product.listbrand')} >
                    <MwButton inGroup={true} type='saveBtn' onClick={() => setMode('Add')}  >
                        <MdOutlineAddBox size={18}/>
                        {t('product.createNewBrand')}
                    </MwButton>
                </CardHeadeWithActions>
                
                <div className='py-5'> {
    brands?.data.length > 0 ? (
        <div className='h-[calc(100vh-280px)] overflow-y-auto custom-scrollbar'>
            {currentItems.map((el, index) => (
                <ListBrandsItem 
                    index={indexOfFirstItem + index + 1}
                    el={el} 
                    key={el._id} 
                    editHandeler={editHandeler} 
                    deleteHandeler={deleteHandeler} 
                />
            ))}
                {totalPages > 1 && (
  <div className="flex justify-center gap-2 mt-4 pb-4">
    <button
      onClick={() => {
        setCurrentPage(1);
        setItemsPerPage(brands?.data?.length || itemsPerPage);
      }}
      className={`px-3 py-1 rounded ${
        itemsPerPage !== 13 ? 'bg-indigo-600 text-white' : 'bg-slate-200 hover:bg-slate-300'
      }`}
    >
      {t('common.all') || "الكل"}
    </button>
    
    {totalPages > 5 && currentPage > 3 && (
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        className="px-3 py-1 rounded bg-slate-200 hover:bg-slate-300"
      >
            {i18n.language === 'ar' ? <MdKeyboardArrowRight size={20} /> : <MdKeyboardArrowLeft size={20} />}

      </button>
    )}
    
    {Array.from({ length: totalPages }, (_, i) => i + 1)
      .filter(number => {
        if (totalPages <= 5) return true;
        if (number === 1 || number === totalPages) return true;
        return Math.abs(currentPage - number) < 3;
      })
      .map((number) => (
        <button
          key={number}
          onClick={() => {
            handlePageChange(number);
            setItemsPerPage(13);
          }}
          className={`px-3 py-1 rounded ${
            currentPage === number && itemsPerPage === 13
              ? 'bg-indigo-600 text-white'
              : 'bg-slate-200 hover:bg-slate-300'
          }`}
        >
          {number}
        </button>
      ))}
    
    {totalPages > 5 && currentPage < totalPages - 2 && (
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        className="px-3 py-1 rounded bg-slate-200 hover:bg-slate-300"
      >
          {i18n.language === 'ar' ? <MdKeyboardArrowLeft size={20} /> : <MdKeyboardArrowRight size={20} />}

      </button>
    )}
  </div>
)}
        </div>
    ) : <NoDataFound msg={t('product.noBrands')} />
} </div>
            </div>
        </>
        )


}

export default ListBrands