import {  useTranslation } from 'react-i18next';

const InvoiceHeader = ({
    logo,
    companyName,
    companyDesc,
    vatNo,
    inv_no,
    inv_date,
    inv_date_due,
    companyAddress,
    invoiceSettings
}) => {

    const [t] = useTranslation('global')
    console.log('invoiceSettings',invoiceSettings);
    return (
        <div className='header'>
            <div className='flex justify-between items-center '>
            {invoiceSettings?.SHOW_LOGO_ON_PRINT === 'true' && <div><img src={logo} className='w-72' /></div> }

                <div className='flex gap-1 border p-5 '>
                    <div className='flex  flex-col gap-1 ps-2 '>
                        <div className='font-bold text-lg border-b p-1' >{companyName}</div>
                        <div className='flex  flex-col gap-1 text-xs text-gray-500'>
                            <div     className='border-b p-1'>{companyDesc}</div>
                            <div  className='border-b p-1'>{t('invoice.VAT_NUMBER')}: {vatNo}</div>
                            <div className='p-1' >{companyAddress}</div>
                        </div>
                    </div>
                </div>
               
            </div>            
        </div>
    )
}

export default InvoiceHeader