import React from 'react';

const ActiveIndicator = ({ active }) => {
   const isActive = active === true || active === 1;

  return (
    <span
      className={`inline-block w-3 h-3 rounded-full ${isActive ? 'bg-green-500' : 'bg-gray-400'} mr-2`}
    ></span>
  );
};

export default ActiveIndicator;
