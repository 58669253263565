import React, { useState, useEffect, useRef } from 'react';
import { BiFilter } from 'react-icons/bi';
import { IoMdClose } from 'react-icons/io';
import { MdCheck } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const ProductFilter = ({ products, onFilterChange }) => {
  const [t, i18n] = useTranslation('global');
  const currentLang = i18n.language;
  const [isOpen, setIsOpen] = useState(false);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const filterRef = useRef(null);

  // Extract unique brands and categories from products
  useEffect(() => {
    if (products?.length) {
      // Extract brands
      const uniqueBrands = [];
      const brandMap = new Map();
      
      products.forEach(product => {
        if (product?.productBrand?.brandName) {
          const brandName = product.productBrand.brandName.find(item => item.lang === currentLang)?.text || 
                            product.productBrand.brandName.find(item => item.lang === 'en')?.text || 
                            product.productBrand.brandName[0]?.text;
          
          if (brandName && !brandMap.has(brandName)) {
            brandMap.set(brandName, true);
            uniqueBrands.push({
              id: product.productBrand._id || brandName,
              name: brandName
            });
          }
        }
      });
      
      // Extract categories
      const uniqueCategories = [];
      const categoryMap = new Map();
      
      products.forEach(product => {
        if (product?.productCategory?.length) {
          product.productCategory.forEach(category => {
            const categoryName = category.categoryName?.find(item => item.lang === currentLang)?.text || 
                                category.categoryName?.find(item => item.lang === 'en')?.text || 
                                category.categoryName?.[0]?.text;
            
            if (categoryName && !categoryMap.has(categoryName)) {
              categoryMap.set(categoryName, true);
              uniqueCategories.push({
                id: category._id || categoryName,
                name: categoryName,
                code: category.categoryCode
              });
            }
          });
        }
      });
      
      setBrands(uniqueBrands);
      setCategories(uniqueCategories);
    }
  }, [products, currentLang]);

  // Close filter panel when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Apply filters
  useEffect(() => {
    const hasFilters = selectedBrands.length > 0 || selectedCategories.length > 0 || selectedTypes.length > 0;
    
    if (!hasFilters) {
      onFilterChange(null);
      return;
    }
    
    const filtered = products?.filter(product => {
      // Check brand match
      const brandMatch = selectedBrands.length === 0 || (
        product?.productBrand?.brandName && selectedBrands.some(selectedBrand => {
          const productBrandName = product.productBrand.brandName.find(item => item.lang === currentLang)?.text || 
                                  product.productBrand.brandName.find(item => item.lang === 'en')?.text || 
                                  product.productBrand.brandName[0]?.text;
          return selectedBrand === productBrandName;
        })
      );
      
      // Check category match
      const categoryMatch = selectedCategories.length === 0 || (
        product?.productCategory?.length && product.productCategory.some(category => {
          const categoryName = category.categoryName?.find(item => item.lang === currentLang)?.text || 
                              category.categoryName?.find(item => item.lang === 'en')?.text || 
                              category.categoryName?.[0]?.text;
          return selectedCategories.includes(categoryName);
        })
      );
      
      // Check product type match
      const typeMatch = selectedTypes.length === 0 || (
        product?.productType && selectedTypes.includes(product.productType)
      );
      
      return brandMatch && categoryMatch && typeMatch;
    }) || [];
    
    onFilterChange(filtered);
  }, [selectedBrands, selectedCategories, selectedTypes, products, currentLang]);

  const toggleBrand = (brand) => {
    setSelectedBrands(prev => 
      prev.includes(brand) 
        ? prev.filter(b => b !== brand) 
        : [...prev, brand]
    );
  };

  const toggleCategory = (category) => {
    setSelectedCategories(prev => 
      prev.includes(category) 
        ? prev.filter(c => c !== category) 
        : [...prev, category]
    );
  };

  const toggleType = (type) => {
    setSelectedTypes(prev => 
      prev.includes(type) 
        ? prev.filter(t => t !== type) 
        : [...prev, type]
    );
  };

  const clearFilters = () => {
    setSelectedBrands([]);
    setSelectedCategories([]);
    setSelectedTypes([]);
  };

  const hasActiveFilters = selectedBrands.length > 0 || selectedCategories.length > 0 || selectedTypes.length > 0;

  return (
    <div className="relative" ref={filterRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`flex items-center gap-1 p-2 rounded-xl ${
          hasActiveFilters ? 'bg-indigo-100 text-indigo-600' : 'bg-slate-50 hover:bg-slate-100'
        } transition-all`}
      >
        <BiFilter size={24} />
        <span className="text-sm">{t('product.filter')}</span>
        {hasActiveFilters && (
          <span className="inline-flex items-center justify-center w-5 h-5 bg-indigo-600 text-white rounded-full text-xs">
            {selectedBrands.length + selectedCategories.length + selectedTypes.length}
          </span>
        )}
      </button>

      {isOpen && (
        <div className="absolute top-full mt-2 right-0 bg-white rounded-lg shadow-lg border border-slate-200 w-72 z-50">
          <div className="p-3 border-b flex justify-between items-center">
            <h3 className="font-medium text-sm">{t('product.filterProducts')}</h3>
            <div className="flex gap-2">
              {hasActiveFilters && (
                <button
                  onClick={clearFilters}
                  className="text-xs text-slate-500 hover:text-slate-700 transition-colors"
                >
                  {t('common.clearAll')}
                </button>
              )}
              <button onClick={() => setIsOpen(false)} className="text-slate-400 hover:text-slate-600">
                <IoMdClose size={18} />
              </button>
            </div>
          </div>

          <div className="max-h-96 overflow-y-auto custom-scrollbar">
            {/* Product Types section */}
            <div className="p-3 border-b">
  <h4 className="font-medium text-xs text-slate-500 mb-2">{t('product.types') || 'أنواع المنتجات'}</h4>
  <div className="space-y-1">
    {['Sales', 'Purchases'].map((type) => (
      <div
        key={type}
        className="flex items-center justify-between cursor-pointer p-1.5 rounded hover:bg-slate-50"
        onClick={() => toggleType(type)}
      >
        <span className="text-xs">
          {type === 'Sales' ? 'مبيعات' : type === 'Purchases' ? 'مشتريات' : type}
        </span>
        <div
          className={`w-4 h-4 rounded flex items-center justify-center ${
            selectedTypes.includes(type)
              ? 'bg-indigo-600'
              : 'border border-slate-300'
          }`}
        >
          {selectedTypes.includes(type) && <MdCheck size={12} color="white" />}
        </div>
      </div>
    ))}
  </div>
</div>
            {/* Brands section */}
            {brands.length > 0 && (
              <div className="p-3 border-b">
                <h4 className="font-medium text-xs text-slate-500 mb-2">{t('product.brands')}</h4>
                <div className="space-y-1">
                  {brands.map((brand) => (
                    <div
                      key={brand.id}
                      className="flex items-center justify-between cursor-pointer p-1.5 rounded hover:bg-slate-50"
                      onClick={() => toggleBrand(brand.name)}
                    >
                      <span className="text-xs">{brand.name}</span>
                      <div
                        className={`w-4 h-4 rounded flex items-center justify-center ${
                          selectedBrands.includes(brand.name)
                            ? 'bg-indigo-600'
                            : 'border border-slate-300'
                        }`}
                      >
                        {selectedBrands.includes(brand.name) && <MdCheck size={12} color="white" />}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Categories section */}
            {categories.length > 0 && (
              <div className="p-3">
                <h4 className="font-medium text-xs text-slate-500 mb-2">{t('product.categories')}</h4>
                <div className="space-y-1">
                  {categories.map((category) => (
                    <div
                      key={category.id}
                      className="flex items-center justify-between cursor-pointer p-1.5 rounded hover:bg-slate-50"
                      onClick={() => toggleCategory(category.name)}
                    >
                      <div className="flex items-center">
                        <span className="text-xs">{category.name}</span>
                        {category.code && (
                          <span className="ml-2 text-xs text-slate-400">({category.code})</span>
                        )}
                      </div>
                      <div
                        className={`w-4 h-4 rounded flex items-center justify-center ${
                          selectedCategories.includes(category.name)
                            ? 'bg-indigo-600'
                            : 'border border-slate-300'
                        }`}
                      >
                        {selectedCategories.includes(category.name) && <MdCheck size={12} color="white" />}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {brands.length === 0 && categories.length === 0 && (
              <div className="p-3 text-center text-slate-500 text-xs">
                {t('common.noFilterOptions')}
              </div>
            )}
          </div>

          {hasActiveFilters && (
            <div className="p-3 border-t bg-slate-50">
              <div className="flex flex-wrap gap-1">
              {selectedTypes.map(type => (
  <div 
    key={type} 
    className="bg-indigo-50 text-indigo-700 text-xs px-2 py-1 rounded-full flex items-center gap-1"
  >
    <span>
      {type === 'Sales' ? 'مبيعات' : type === 'Purchases' ? 'مشتريات' : type}
    </span>
    <IoMdClose 
      size={14} 
      className="cursor-pointer" 
      onClick={(e) => {
        e.stopPropagation();
        toggleType(type);
      }} 
    />
  </div>
))}
                {selectedBrands.map(brand => (
                  <div 
                    key={brand} 
                    className="bg-indigo-50 text-indigo-700 text-xs px-2 py-1 rounded-full flex items-center gap-1"
                  >
                    <span>{brand}</span>
                    <IoMdClose 
                      size={14} 
                      className="cursor-pointer" 
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleBrand(brand);
                      }} 
                    />
                  </div>
                ))}
                {selectedCategories.map(category => (
                  <div 
                    key={category} 
                    className="bg-indigo-50 text-indigo-700 text-xs px-2 py-1 rounded-full flex items-center gap-1"
                  >
                    <span>{category}</span>
                    <IoMdClose 
                      size={14} 
                      className="cursor-pointer" 
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleCategory(category);
                      }} 
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductFilter;