import React,{useEffect, useRef, useState} from 'react'
import {BiChevronDown} from 'react-icons/bi'
import {AiOutlineSearch} from 'react-icons/ai'
import {MdAddBox} from 'react-icons/md'
import CustomerSelectorItem from '../CustomerSelectorItem';
import { useCurrent } from '../../hooks';
import TagListItem from './TagListItem';

const MwSelectorMulti = ({label,_data,dataType,onClickNew,setSelectedItem,selectedItem,initalValue='Choose',withLabel=true,withAddNew=true,className}) => {
    const [inputValue,setInputValue] = useState('');
    const {currentLangId} = useCurrent()
    const [open,setOpen] = useState(false);
    const isMultiSelect = dataType === 'salesman';
    const inputRef = useRef(null);

    const onSelectedHandler = (item) => {
        if (selectedItem.some(obj => obj.value === item.value)) return ;
        setSelectedItem([...selectedItem,{...item,label:item.label}])
        setOpen(false);
        setInputValue("")
    }

    const onSelectedHandlerCustomer = (item) => {
        setSelectedItem(prev => {
            // للاختيار المتعدد
            if (isMultiSelect) {
                if (!Array.isArray(prev)) return [item];
                if (prev.some(i => i.value === item.value)) return prev;
                return [...prev, item];
            }
            // للاختيار المفرد
            return item;
        });
        setOpen(false);
        setInputValue("");
    }

    const dataList = _data &&  _data?.map((item) => {
        if(dataType === 'customer' || dataType === 'salesman'){
            return <CustomerSelectorItem 
                        key={item._id}
                        item={item} 
                        setSelectedItem={onSelectedHandlerCustomer} 
                        setOpen={setOpen}
                        setInputValue={setInputValue}
                        inputValue={inputValue}
                        />
        }
        if(dataType === 'labelValue' ){
            if(item.lang === currentLangId )
                return <div 
                            className={`px-4 py-2 border-t cursor-default hover:bg-indigo-50 hover:font-bold  ${item?.label?.toLowerCase().startsWith(inputValue) ? 'block' : 'hidden'}`}
                            key={item.value}
                            onClick={()=>onSelectedHandler(item)}
                            >
                            {item.label}
                        </div>
        }

    })

    const removeItem = (tag) => {
        const tagFilter =  selectedItem.filter(t => t.label !== tag); 
        setSelectedItem(tagFilter)
    }

    return (
        <>
<div ref={inputRef} className={`relative w-full ${className}`}>   
                 <label className='text-xs text-slate-400'> {label} </label>
                <div
                    onClick={()=>setOpen(!open)}
                    className='cursor-default flex text-xs bg-white text-slate-600 w-full h-8 px-2 items-center justify-between rounded'>
                        <div className='flex-1 flex gap-1' >
                            {selectedItem?.length > 0 ? selectedItem?.map((el,indx)=>(<TagListItem key={`${el.value}-${indx}`} tag={el.label} removeTagHandler={()=>removeItem(el.label)} />))  : initalValue}
                        </div>
                        <BiChevronDown size={20}/>
                </div>
                <ul className={`bg-slate-200 border border-slate-200 shadow-lg absolute inset-x-0 z-50 mt-1 rounded-b-lg overflow-y-auto ${open ? 'max-h-60 block' : 'max-h-0 hidden'}`}>                         <div className='sticky top-0 p-3 bg-slate-200 border-b border-slate-100'>
                        { 
                        withAddNew && <div className='text-end'>
                            <div className='text-sky-700 absolute top-4 end-4'  
                            onClick={()=>{ 
                                onClickNew()
                                setOpen(false) 
                            }}
                            > <MdAddBox size={24}/> </div>
                        </div>
                        }
                        <div className='flex items-center justify-between  px-2 bg-white rounded '>
                            <AiOutlineSearch size={18} className='text-gray-600' />
                            <input 
                                type="text"
                                placeholder= {label}
                                className='placeholder:text-gray-400 outline-none text-xs p-2 w-full bg-white'
                                value={inputValue}
                                onChange={(e)=>setInputValue(e.target.value.toLowerCase())}
                                />
                        </div>
                    </div>
                    <div className='mb-2 [&>*]:border-b [&>*]:border-slate-300 [&>*:last-child]:border-b-0 '>
                        {dataList}
                    </div>
                </ul>
                </div>      
             
        </>
    )
}

export default MwSelectorMulti