import React, { useEffect, useState } from 'react'
import { useCurrent, useFetch, usePost } from '../../../hooks'
import { MwSelectorMulti,MwSelector, MwSpinner,TagList, MwInputText, MwButton } from '../../ui';
import {LangArrayToObjKeyValue} from '../../../utils/global'

const ProductLinks = ({
    productCategorySelected,
    setProductCategorySelected,
    productBrandSelected,
    setProductBrandSelected,
    productTags,
    setProductTags,
    genProductCode,
    setGenProductCode,
    setProductCode,
    productCode
}) => {
    const {currentTenantId,currentCompanyId,currentLangId} = useCurrent();
    const {data:categories,loading:categoriesLoading} = useFetch(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/categories/${currentTenantId}/${currentCompanyId}`)
    const {data:brands,loading:brandsLoading} = useFetch(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/brands/${currentTenantId}/${currentCompanyId}`)
    const {data:tags,loading:tagsLoading} = useFetch(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/tags/${currentTenantId}/${currentCompanyId}`)
    const {data:genCodeResult,loading:genCodeLoading,postData:getGenProductCode} = usePost()
    
    
    const [categoriesList,setCategoriesList] = useState([]);
    const [brandsList,setBrandsList] = useState([]);
    const [tagsList,setTagsList] = useState([]);
    const [genCode,setgenCode] = useState(false);
    
    const genProductCodeHandler = async(cat) => {
        setGenProductCode('')
        const _url = `${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/gencode/${cat}`
        await getGenProductCode(_url,{tenantId:currentTenantId,companyId:currentCompanyId})
    }
    
    useEffect(() => {
        if(categories && !categoriesLoading ){
            let itemList = [];
            categories.data.map((el)=>itemList?.push({label:LangArrayToObjKeyValue(el.categoryName)?.[currentLangId],value:el._id,lang:currentLangId ,data:el}));
            setCategoriesList(itemList)
        }
    },[categories]);


    useEffect(() => {
        if(brands && !brandsLoading ){
            let itemList = [];
            brands.data.map((el)=>itemList?.push({label:LangArrayToObjKeyValue(el.brandName)?.[currentLangId],value:el._id,lang:currentLangId}));
            setBrandsList(itemList)
        }
    },[brands]);

    
    useEffect(() => {
        if(productCategorySelected.length > 0 && genCode ){
            setProductCode(null);
            console.log('cat select',productCategorySelected)
            
            // const _code_level_2 = productCategorySelected[0]?.data?.categoryParent ? productCategorySelected[0]?.data?.categoryParent?.categoryCode+'-' : ''
            // const _code_full = `${_code_level_1}-${_code_level_2}`
            if(productCategorySelected[0]?.data?.categoryCode){

                genProductCodeHandler(productCategorySelected[0]?.data?.categoryCode)
                setGenProductCode(productCategorySelected[0]?.data?.categoryCode)
            }
            // const _code_full = `${_code_level_1}-${_code_level_2}${('1').padStart(3,'0')}`
            // 
        }
    },[productCategorySelected,genCode])

    useEffect(() => {
        if(genCodeResult){
            const _newcode = genProductCode +'-'+ (parseInt(genCodeResult.data)+1).toString().padStart(4,'0')
            setGenProductCode(_newcode)
            console.log('_newcode',_newcode)
        }
    },[genCodeResult])
    return (
        <>
            {
                (categories && brands && tags && !categoriesLoading && !brandsLoading && !tagsLoading ) ? 
                <div className='relative flex flex-col gap-3 py-3 '>
                    <div className='flex gap-2'>
                        <div className='flex-1 z-50'>
                            <MwSelectorMulti
                                label={`التصنيف`}
                                initalValue='أختار التصنيف'
                                dataType = 'labelValue'
                                _data={categoriesList}
                                withAddNew = {false}
                                selectedItem={productCategorySelected}
                                setSelectedItem={setProductCategorySelected}
                                className={`z-20`}
                            />
                        </div>
                        <div className='w-40'>
                            {
                                genCode && genProductCode ? 
                                <div className='flex'>
                                    <div>
                                        <MwInputText
                                            label='كود المنتج التلقائي'
                                            id='genProductCode' 
                                            value={genProductCode}
                                            disabled={true}
                                            onChange = {(e) =>setGenProductCode(e.target.value) }
                                        />
                                    </div>
                                    { !productCode && genCode && <div className='pt-5'><MwButton  classNameCustom="w-12" actionType='button'  onClick={()=>{setProductCode(genProductCode)}}>Apply</MwButton></div> }
                                </div> : <div className=' pt-5'><MwButton classNameCustom="w-40" actionType='button' type='secondary' disabled={ !productCategorySelected || productCategorySelected.length === 0} onClick={()=>setgenCode(true)}>Generate</MwButton></div>
                                    
                            }
                        </div>
                    </div>
                    <MwSelector
                        label={`الماركة`}
                        initalValue='أختار الماركة'
                        dataType = 'labelValue'
                        _data={brandsList}
                        withAddNew = {false}
                        selectedItem={productBrandSelected}
                        setSelectedItem={setProductBrandSelected}
                        className={`z-10`}
                    />
                    <TagList
                        tagList={productTags}
                        setTagList={setProductTags}
                        withLabel={true}
                        label={`اضف علامات للمنتج (Tags)`}
                    />
                </div> : <MwSpinner/>
            } 
        </>
    )
}

export default ProductLinks