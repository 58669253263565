import React, { useState, useEffect } from 'react';
import MwButton from '../ui/MwButton';
import { useCurrent, usePut } from '../../hooks';
import { useTenant } from '../../hooks';
import { MdOutlineCancel } from "react-icons/md";
import { FaRegSave } from "react-icons/fa";
import { AiOutlineDelete } from 'react-icons/ai';
 import { useDispatch, useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';

import { useTranslation } from 'react-i18next';
import CardHeadeWithActions from '../ui/CardHeadeWithActions';
import { LuLayoutDashboard } from 'react-icons/lu';
import ToggleSwitch from '../OsNewWeight/OsToggleSwitch';
import { getListPrice } from '../../store/ListPriceSlice';

const OsEditListName = ({ id, setMode }) => {
  const { t, i18n } = useTranslation('global');
  const isRTL = i18n.dir() === 'rtl';
  
  const { data, loading, error, putData } = usePut();
  const { tenantId } = useTenant();
  const [name, setName] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [isModified, setIsModified] = useState(false);
  const [originalName, setOriginalName] = useState("");
  const [originalIsActive, setOriginalIsActive] = useState(false);
  const { currentTenantId, currentCompanyId, currentBranchId } = useCurrent();
  const dispatch = useDispatch();
  const { ListData } = useSelector((state) => state.ListPrice);
  
  // تحميل البيانات عند تغيير الـ id
  useEffect(() => {
    if (id) {
      dispatch(getListPrice(`${process.env.REACT_APP_INV_API_SALES_SERVER_BASE_URL}/inventory/list-price/${id}`));
    }
  }, [dispatch, id]);

  // تعبئة الحقول عند تغيير ListData
  useEffect(() => {
    if (ListData && ListData.length > 0) {
      const selectedItem = ListData.find(item => item.id === id);
      if (selectedItem) {
        setName(selectedItem.name);
        setIsActive(selectedItem.is_active === 1);
        setOriginalName(selectedItem.name);
        setOriginalIsActive(selectedItem.is_active === 1);
        setIsModified(false);
      }
    }
  }, [ListData, id]);

  // عرض رسائل النجاح أو الخطأ
  useEffect(() => {
    if (data?.status === 1) {
      toast.success(t("listPrice.saveSuccess"), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      // تفريغ الحقول بعد الإضافة بنجاح
      setName("");
      setIsActive(false);
      setErrorMessages({});
      setIsModified(false);
    } else if (error) {
      toast.error(t("listPrice.saveError"), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
    }
  }, [data, error, t]);

  // التحقق من صحة النموذج
  const validateForm = () => {
    let errors = {};
    if (!name.trim()) errors.name = t("listPrice.listNameRequired");
    setErrorMessages(errors);
    return Object.keys(errors).length === 0;
  };

  // حفظ البيانات
  const handleSave = async () => {
    if (!tenantId) {
      toast.error(t("listPrice.notFoundTenantId"), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      return;
    }
    if (!validateForm()) {
      toast.error(t("listPrice.fillRequired"), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      return;
    }
    const payload = {
      name: name,
      is_active: isActive ? 1 : 0,
    };
    try {
      await putData(`${process.env.REACT_APP_INV_API_SALES_SERVER_BASE_URL}/inventory/list-price/${id}`, payload);
      setTimeout(()=>{
        setMode('List');
      },1500)
    
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  // إلغاء العملية
  const handleCancel = () => {
    setMode('List');
  };

   const handleNameChange = (e) => {
    setName(e.target.value);
    checkIfModified(e.target.value, isActive);
  };

  const handleIsActiveChange = (e) => {
    setIsActive(e.target.checked);
    checkIfModified(name, e.target.checked);
  };

  // التحقق من وجود تعديلات
  const checkIfModified = (currentName, currentIsActive) => {
    const isNameModified = currentName !== originalName;
    const isActiveModified = currentIsActive !== originalIsActive;
    setIsModified(isNameModified || isActiveModified);
  };

  return (
    <div className='p-3'>
    <CardHeadeWithActions title={  
                 <div className='flex mb-8 items-center gap-2 '>
      <LuLayoutDashboard  className="w-6 h-6" /> 
      <span className='text-xs'>
      {t('listPrice.managementlists_price')}
      </span>
    </div>
}>              
              
              <MwButton
        actionType="button"
        inGroup={true}
        type="saveBtn"
        onClick={handleCancel}
        className="mr-2"
      >
        {t("listPrice.lists_price")}
      </MwButton>
              
            </CardHeadeWithActions>
    <div className= "bg-white text-xs p-6 rounded-lg shadow-md">
    <Toaster
  position="top-center"
  containerStyle={{
    top: 60, 
  }}
  toastOptions={{
    duration: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    pauseOnHover: true,
    style: {
      direction: "rtl"
    }
  }}
/>



       <div
  className={`w-full flex mb-6 ${isRTL ? 'justify-end' : 'justify-end'}`}
  dir={isRTL ? 'rtl' : 'ltr'}
>



  {isRTL ? (
    <>
      <MwButton
        actionType="button"
        size="sm"
        inGroup={true}
        type="saveBtn"
        onClick={handleSave}
        disabled={loading || !isModified}
        className="mr-0"
      >
        {loading ? (
          <span>{t("listPrice.saving")}</span>
        ) : (
          <>
            <FaRegSave />
            <span>{t("listPrice.save")}</span>
          </>
        )}
      </MwButton>

       
    </>
  ) : (
    <>
      <MwButton
        actionType="button"
        size="sm"
        inGroup={true}
        type="original-left"
        onClick={handleSave}
        disabled={loading || !isModified}
      >
        {loading ? (
          <span>{t("listPrice.saving")}</span>
        ) : (
          <>
            <FaRegSave />
            <span>{t("listPrice.save")}</span>
          </>
        )}
      </MwButton>

      <MwButton
        actionType="button"
        size="sm"
        inGroup={true}
        type="deleteBtn"
        onClick={handleCancel}
      >
        <AiOutlineDelete className="ml-1" />
        {t("listPrice.cancel")}
      </MwButton>
    </>
  )}
</div>


      <div className="gap-4 flex justify-between mb-4">
        <div className="block w-[50%]">
          <label htmlFor="name" className="block text-sm text-gray-600 mb-1">
            {t("listPrice.listName")}
          </label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={handleNameChange}
            className="w-full p-2 border border-gray-300 text-slate-600 rounded-md text-center"
          />
          {errorMessages.name && <p className="text-red-500 text-sm">{errorMessages.name}</p>}
        </div>
        <div className="block w-[6%] mt-8">
          {/* <label htmlFor="isActive" className="block text-sm text-slate-600 mb-1">
            {t("listPrice.status") || "الحالة"}
          </label> */}
          <ToggleSwitch
            id="isActive"
            label={t("listPrice.active") || "نشط"}
            checked={isActive}
            onChange={handleIsActiveChange}
          />
        </div>
      </div>
    </div>
    </div>
  );
};

export default OsEditListName;
