
import { configureStore } from '@reduxjs/toolkit';
import langReducer from './langSlice';
import currentCompanyReducer from './currentCompanySlice';
import currentBranchReducer from './currentBranchSlice';
import authReducer from './authSlice';
import settingReducer from './settingSlice';
import wieghtReducer from './weightSlice'
import ListPrice from './ListPriceSlice'
export const store = configureStore({
        reducer:{
            lang: langReducer,
            currentCompany:currentCompanyReducer,
            currentBranch:currentBranchReducer,
            auth:authReducer,
            settings:settingReducer,
            weight:wieghtReducer,
            ListPrice
        }
    });

