import React, { useRef, useState, useEffect } from 'react';
import { CiViewList } from 'react-icons/ci';
import { MdEdit } from 'react-icons/md';
import { AiOutlineDelete } from 'react-icons/ai';

const ActionsDropdown = ({ 
  isRTL = false,
  addItemButton,
  onView,
  onEdit,
  onDelete,
  itemId,
  viewText = 'View',
  editText = 'Edit',
  deleteText = 'Delete',
  className = '',
  isListOpen = false
}) => {
  const [showActions, setShowActions] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowActions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div  className={`relative ${className} ${
      isListOpen || showActions ? 'font-normal' : ''
    }`}  ref={dropdownRef}>
      <button
        className="text-lg text-gray-500 hover:text-gray-700 px-2 
                  hover:bg-gray-100 rounded-full p-1 transition-colors"
        onClick={(e) => {
          e.stopPropagation();
          setShowActions(!showActions);
        }}
      >
        ⋮
      </button>

      {showActions && (
        <div 
          className={`absolute ${isRTL ? 'left-0' : 'right-0'} top-0 mt-8 
                     bg-white shadow-lg rounded-md p-2 z-10 
                     border border-gray-300 min-w-[150px]`}
          onClick={(e) => e.stopPropagation()}
        >
          {addItemButton && (
            <>
              {addItemButton}
              <hr className="my-2" />
            </>
          )}
          
          {onView && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onView(itemId);
                setShowActions(false);
              }}
              className="flex items-center gap-2 text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
            >
              <CiViewList className="w-5 h-5" />
              {viewText}
            </button>
          )}

          {onEdit && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onEdit(itemId);
                setShowActions(false);
              }}
              className="flex items-center gap-2 text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
            >
              <MdEdit className="w-5 h-5" />
              {editText}
            </button>
          )}

          {onDelete && (
            <button
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation();
                onDelete(itemId,e);
                setShowActions(false);
              }}
              className="flex items-center gap-2 text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 w-full"
            >
              <AiOutlineDelete className="w-5 h-5" />
              {deleteText}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ActionsDropdown;