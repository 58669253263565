import React, { useState,useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {  useTranslation } from 'react-i18next';
import {AiOutlineSave} from 'react-icons/ai'
import {MdOutlineAddBox} from 'react-icons/md'
import {updateSettings} from '../../../store/settingSlice'
import {useUser,useInvoice,useCurrent, usePut,useLogs} from '../../../hooks';
import {MwToast, MwButton, CardAmount, MwSpinner, MwInputText} from '../../ui';
import CreateProduct from '../../Products/CreateProduct';
import {getAllSalesman} from '../../../services/salesmanService';
import {getAllVendors} from '../../../services/vendorService';
import MwSpinnerButton from '../../ui/MwSpinnerButton';
import SideModal from '../../ui/SideModal';
import InvFormHeader from './InvFormHeader';
import InvFormBody from './InvFormBody';
import toast, { Toaster } from 'react-hot-toast';
import { amountFormat } from '../../../utils/invoceHelper';


const InvFormUpdate = ({tenantId,tenantUsername,invData}) => {
    console.log("FIRSTinvData", invData);
    const [invTitle, setInvTitle] = useState(invData?.title || '');
const [invTitleAr, setInvTitleAr] = useState(invData?.title_ar || '');
const [priceListItems, setPriceListItems] = useState([]);
    
    const [t] = useTranslation('global')
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [disabledDiscount,setDisabledDiscount] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [taxesList, setTaxesList] = useState([]);



    const {currentLangId} = useCurrent();
    const companySelected  = useSelector((state)=>state.currentCompany)
    const branchSelected = useSelector((state)=>state.currentBranch)
    const {getUser} = useUser();
    const {
            initalProductItem,
            SETTING_QUOTE,
            calcSumPrice,
            calcSumNetTotal,
            toFloat,
            calcVat,
            formatter
            } = useInvoice();
    const INV_CURRENCY = SETTING_QUOTE?.INV_CURRENCY
    const SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION = SETTING_QUOTE?.SHOW_INVOICE_PRODUCT_DESCRIPTION || 'false';
    const SETTING_INV_WITH_HEADER_FOOTER = SETTING_QUOTE?.INV_WITH_HEADER_FOOTER || 'false';
    const SETTING_INV_UOM_SHOW = SETTING_QUOTE?.INV_UOM_SHOW || 'false';
    const SETTING_INV_QTY_DAYS = SETTING_QUOTE?.INV_QTY_DAYS || 'false';
    const SETTING_INV_CURRENCY = INV_CURRENCY || 'SAR'
    const invoiceSettings = useSelector(state => state.settings).invoiceSettings;


    const {resultLogsPost,loadingLogsPost,errorLogsPost,postDataHandler} = useLogs()

    // products
    const [reloadProductList,setReloadProductList] = useState(false)
    const [showProductModal,setShowProductModal] = useState(false);
    // Customer
    const [customers,setCustomers] = useState([]); 
    const [showCreateCustomerModal,setShowCreateCustomerModal] = useState(false);
    const [isAddedCustomer,setIsAddedcustomer] = useState(false); 
    // Salesman
    const [salesmans,setSalesmans] = useState([]); 
    const [showCreateSalesmanModal,setShowCreateSalesmanModal] = useState(false);
    const [isAddedSalesman,setIsAddedSalesman] = useState(false); 
    // Toast
    const [showToast,setShowToast] = useState(false);
    const [msgToast,setMsgToast] = useState('');
    
    const { data:invDataPost, loading:invLoadingPost, error:invErrorPost,putData:invPut} = usePut(); 
    const  [formErrors,setFormErrors] = useState({});
    // Start Invoice input fields 
    //console.log(invData)
    const productDetails = invData?.quote_details.map((e,index) => {
        return {
            index,
            productId: e.product_id,
            productName: e.product_name,
            productDesc: e.product_desc || '',
            qty: e.qty,
            qtyDays: e.qty_days || 1,
            price: e.total_price,
            priceUnit: e.price,
            productDiscount: e.product_discount,
            productNetTotal: e.product_net_total,
            productVat: e.product_vat,
            productNetTotalWithVat: e.product_net_total_with_vat,
            uom: e.uom || '',
            uomTemplateId: e.uom_template_id || '',
            tax_id: e.tax_id || '0', // Use existing tax_id or default to '0'
            tax_rate: e.tax_relation?.tax_value || invoiceSettings.INV_VAT_PERCENT, // Use tax_relation value or default setting
        };
    });
    console.log("productDetails before", productDetails)


    const [productList, setProductList] = useState(productDetails || []);
    console.log("productList usestate", productList)
    console.log("productDetails", productDetails)
    const [invNo,setInvNo] = useState(invData?.inv_no);
    const invRef = useRef(invData?.inv_ref || '');
     const invDate = useRef(invData?.inv_date);
    const invDateDue = useRef(invData?.inv_date_due);
    const invCurrency = useRef();
    const [customerSelected,setCustomerSelected] = useState({ id:invData?._id,label:invData?.customer_name});
    const [salesmanSelected,setSalesmanSelected] = useState([]);
    console.log("salesmanSelectedos", salesmanSelected)
    const [invNotes,setInvNotes] = useState(invData?.notes || '');
    const [invTerms,setInvTerms] = useState(invData?.terms || '');
    const [havePromoCode,setHavePromoCode] = useState(false);
    const [promoCode,setPromoCode] = useState('');
    const [invTotalAmount,setInvTotalAmount] = useState(invData.total_amount);
    const [totalAmountWithDiscount,setTotalAmountWithDiscount] = useState('');
    const [discount,setDiscount] = useState(invData.discount_amount)
    const [vat,setVat] = useState(invData?.vat_amount)
    const [netTotal,setNetTotal] = useState(invData?.net_amount)
    const [tagList,setTagList] = useState(JSON.parse(invData?.inv_tags));
    ////////////////////////////////////////////////
    
    const initalCustomerName = invData?.customer_name || '';
    const initalSalesmanName = invData?.salesman_name || '';
    const initalSalesmanId = invData?.salesman_id || '';
    // Promo Code
    const promoCodeHandler =() => {

    }
    // Product Functions
    const showModalProductHandle = () => {
        setShowProductModal(true);
    }
    const onProductCreated = () => {
        setReloadProductList(true);
    }

    // Customer Functions
    const onCustomerCreated = () => {
        setIsAddedcustomer(true);
        
        // setMsgToast('Customer Has been added...')
        // setShowToast(true);
    }
    const onClickNewCustomer = () => {
        setShowCreateCustomerModal(true);
        setIsAddedcustomer(false);
    }
    


    // Salesman Functions
    const onSalesmanCreated = () => {
        setIsAddedSalesman(true);
        // setMsgToast('Salesman Has been added...')
        // setShowToast(true);
    }
    const onClickNewSalesman = () => {
        setShowCreateSalesmanModal(true);
        setIsAddedSalesman(false);
    }
    const calcSum = () => {
        return calcSumPrice(productList)
        
    }

    // const invSumHandler = () => {
    //     const sum = calcSum();
    //     const _totalAmountWithDiscount =  sum - discount;
    //     setInvTotalAmount(sum);
    //     setTotalAmountWithDiscount(_totalAmountWithDiscount);
    //     vatHandler(_totalAmountWithDiscount);
    // }
    const invSumHandler = () => {
        console.log('invSumHandler')
        const sum = calcSum();
        const netTotal = calcSumNetTotal(productList);
        const _discountItems = sum - netTotal;
        
        _discountItems > 0 ? setDisabledDiscount(true) : setDisabledDiscount(false)
        if(disabledDiscount && _discountItems >= 0){
            setDiscount(amountFormat(_discountItems) ) 
        }
        const _totalAmountWithDiscount = sum - discount;
        setInvTotalAmount(sum);
        setTotalAmountWithDiscount(_totalAmountWithDiscount);
        vatHandler(_totalAmountWithDiscount);
        console.log('total => ',invTotalAmount,totalAmountWithDiscount,)
    }
    // const vatHandler = (total=totalAmountWithDiscount) => {
    //     const calcvat = calcVat(total)
    //     setVat(calcvat)
        
    //     const nt = toFloat(calcvat,false) + toFloat(total,false)
    //     setNetTotal(nt)
        
    // }

    // const discountHandler = () => {
    //     invSumHandler();
    // }
    const vatHandler = (total = totalAmountWithDiscount) => {
        // Total VAT is now set directly, ensuring it reflects the sum from product rows
        console.log("vat",vat)
        setVat(toFloat(vat, false)); // Ensure VAT is a float value
        
        // Recalculate net total based on total amount, discount, and VAT
        const invTotal = total;
        const invDiscount = toFloat(discount, false);
        const invVat = toFloat(vat, false);
        
        // Calculate net total: total - discount + VAT
        const net = toFloat(invTotal - invDiscount + invVat);
        setNetTotal(net);
    };
    
    const discountHandler = () => {
        invSumHandler(); 
    };
     // add new useEffect vat handler
    useEffect(() => {
        if (productList) {  
            invSumHandler(); // Recalculate everything when product list changes
        }
    }, [productList, vat]); // Add vat to dependencies
    
    // add new useEffect vat handler
    useEffect(() => {
        netTotalHandler(); // Recalculate net total
        vatHandler(); // Recalculate VAT and net total
    }, [invTotalAmount, vat, discount]);
    const netTotalHandler = () => {
        const invTotal  = toFloat(invTotalAmount,false)   ;
        const invDiscount = toFloat(discount,false) ;  ;
        const invVat = toFloat(vat,false) ;  ;
        const net = toFloat(invTotal - invDiscount + invVat);
        setNetTotal(net);
    }

    const discountOnchange = (value) => {
        
        const sum = calcSum();
        let discountValue;
        if(value.includes('%')){
            invSumHandler();
            discountValue =  (toFloat( toFloat(sum,false) * toFloat(value.replace('%', ''),false) /100 ));
            console.log(discountValue);
        }else{
            discountValue = value
        }
        setDiscount(()=> discountValue );
    }
    const validateForm = () => {
        setFormErrors({})
        let errors = {};
        let hasErrors = false
        if(!invDate?.current.value){
            errors.invDate = 'Date is required';
            hasErrors = true
        }
        if(!invDateDue?.current.value){
            errors.invDateDue = 'Date Due is required';
            hasErrors = true
        }

          if(!productList || productList.length === 0) {
              toast.error('يجب إضافة منتج واحد على الأقل') 
                    
                    hasErrors = true;
                    return
                } else if(!productList[0]?.productId) {
                      toast.error('يجب اختيار منتج') 
                    hasErrors = true;
                    return
                }

        if(Object.keys(errors).length > 0){
            setFormErrors({...errors});
            return false;
        }
        return true;
    }
    const onsubmitHandler = (e) => {
        if(e.key === 'Enter') return ;
        e.preventDefault()
        // validate form 
        if(!validateForm())return;
        
        let productListHandler = [];
        productList.map((product)=>{
            if (product && product.productName !=='Deleted' ){
                productListHandler.push(  {
                    id: product.id,
                    product_id : product.productId,
                    product_name : product.productName ,
                    unit_id : 1,
                    qty : product.qty ,
                    price: (parseFloat(product.price)/parseInt(product.qty)),
                    total_price : product.price,
                    product_discount : product.productDiscount || 0,
                    product_net_total : product.productNetTotal,
                    product_vat : product.productVat,
                    product_net_total_with_vat: product.productNetTotalWithVat,
                    tax_id: product.tax_id || "0",
                    productdesc: product.product_desc || '',


                });
            }
        });

        const _data = {
            inv_no: invNo,
            inv_ref:invRef?.current.value,
            inv_date:invDate?.current.value,
            inv_date_due:invDateDue?.current.value,
            customer_id:customerSelected?._id,
            customer_name:customerSelected?.label,
            salesman_id:salesmanSelected?._id,
            salesman_name:salesmanSelected?.label,
            total_amount: invTotalAmount,
            discount_amount: discount,
            // promo_code_id:
            // promo_code:
            vat_amount: vat,
            terms: invTerms,
            net_amount: netTotal,
            notes: invNotes,
            is_paid: false,
            type_id:1,
            status_id:1,
            tenant_id: tenantId,
            company_id: companySelected.value,
            branch_id: branchSelected.value,
            created_by: getUser?._id || 0,
            inv_tags:JSON.stringify(tagList),
            inv_details:productListHandler
        }
        console.log("_data",_data);
        
        invPut(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/quotes/update/${invData.id}`,_data)
    };

    const getcustomerHandler = async () => {
        const res = await getAllVendors(tenantId,companySelected.value,branchSelected.value)
        setCustomers(res);
    }

    const getSalesmanHandler = async () => {
        const res = await getAllSalesman(tenantId,companySelected.value,branchSelected.value);
        setSalesmans(res);
    }
    const setLogsHandler =  () => {
        const _data = {
            moduleName: "SALES",
            resourceName: "QUOTES",
            eventName:"UPDATE",
            logContentEn: `Update quote No: ${invDataPost.inv_no}`,
            logContentAr: `تعديل عرض سعر برقم : ${invDataPost.inv_no}`,
        }
        postDataHandler(_data);
    }

    useEffect(()=>{
        if(resultLogsPost)
        setTimeout(() => {
            navigate(`/${tenantUsername}/quotes`);

        }, 2000);
    },[resultLogsPost]);

    useEffect(()=>{
        if(invDataPost){
            setLogsHandler();
        }
    },[invDataPost]);
    

    useEffect(() => { 
        setProductList([...productDetails])
        getcustomerHandler();    
        getSalesmanHandler();
        console.log('invData',invData,productList)  
    },[]);

    useEffect(() => {
        if(isAddedCustomer){
            getcustomerHandler();        
        }
    },[isAddedCustomer]);

    useEffect(() => {
        if(isAddedSalesman){
            getSalesmanHandler();       
        }
    },[isAddedSalesman]);


     useEffect(() => {
        if (invDataPost) {
             
            toast.success('تم تحديث السعر بنجاح', {
                toastId: 'success-save',  
                position: "top-center",
                autoClose: 3000,
            });
           
        } else if (invErrorPost) {
              toast.error('حدث خطأ أثناء حفظ عرض السعر', {
                toastId: 'error-save',  
                position: "top-center",
                autoClose: 3000,
            }) 
        }
    }, [invDataPost, invErrorPost]);

 

    useEffect(()=>{
        invSumHandler()
        dispatch(updateSettings({
            typeSetting : 'invoice', value :{
                INV_ITEMS_ROW_NUMBER : productList.length + 1
            }
        }))
    },[productList]);

    useEffect(()=>{
        netTotalHandler();
        vatHandler();
    },[invTotalAmount])

    useEffect(() => {
        discountHandler();
    },[discount])

    useEffect(() => {
         setIsLoading(true);
        
         const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);  
        
        return () => clearTimeout(timer);
    }, []);
    useEffect(() => {
        if(invData){
            setSalesmanSelected({label:invData?.salesman_name,_id:invData?.salesman_id})
            setCustomerSelected({label:invData?.
                customer_name,_id:invData?.
                customer_id})
        }
    }, []);




    return (

        <>

<Toaster
  position="top-center"
  containerStyle={{
    top: 60, 
  }}
  toastOptions={{
    duration: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    pauseOnHover: true,
    style: {
      direction: "rtl"
    }
  }}
/>
         {isLoading ? (
                <div className="flex justify-center items-center h-screen">
                    <MwSpinner size="large" />
                </div>
            ) : (
                <>

        
                { showToast && <MwToast 
                    msg={msgToast} 
                    isShow={showToast}
                    setIsShow = {setShowToast}
                    /> 
                }

            <SideModal title="Create New Product" onShow={showProductModal} setOnShow={setShowProductModal} >
                <CreateProduct onProductCreated={onProductCreated} setReloadProductList={setReloadProductList}  />
            </SideModal>

            {/* <SideModal title="Create New Customer" onShow={showCreateCustomerModal} setOnShow={setShowCreateCustomerModal} >
                <CreateCustomer onCustomerCreated={onCustomerCreated}  />
            </SideModal> */}

            {/* <SideModal title="Create New Salesman" onShow={showCreateSalesmanModal} setOnShow={setShowCreateSalesmanModal} >
                <CreateSalesman onSalesmanCreated={onSalesmanCreated}  />
            </SideModal>
             */}
            {
            <form  onSubmit={onsubmitHandler} >
                <div className='flex items-center justify-between mb-3 mt-1' >
                    <div className='pb-2 text-gray-400 text-sm flex items-center gap-1 '>
                        <MdOutlineAddBox size={18}/>
                        تعديل عرض سعر رقم {invData?.inv_no}
                    </div>
                    { invLoadingPost ? <MwSpinnerButton/> : 
                        <MwButton
                        actionType='submit'
                        inGroup = {true}
                                type = 'saveBtn'
                                disabled={isSubmitting}
                            > <AiOutlineSave size={16}/>  حفظ عرض السعر  </MwButton> 
                    }
                </div>
                <div  className='flex flex-col h-full justify-between  bg-slate-50 p-3 rounded-lg'>
                    <InvFormHeader
                            invNo = {invNo}
                            setInvNo = {setInvNo}
                            invRef = {invRef}
                            invTitle={invTitle}
                            setInvTitle={setInvTitle}
                            invTitleAr={invTitleAr}
                            setInvTitleAr={setInvTitleAr}
                            invDate = {invDate}
                            invDateDue = {invDateDue}
                            invCurrency = {invCurrency}
                            customers = {customers}
                            onClickNewCustomer = {onClickNewCustomer}
                            salesmans = {salesmans}
                            onClickNewSalesman = {onClickNewSalesman}
                            customerSelected = {customerSelected} 
                            setCustomerSelected = {setCustomerSelected}
                            salesmanSelected = {salesmanSelected}
                            setSalesmanSelected = {setSalesmanSelected}
                            formErrors = {formErrors}
                            initalCustomerName = {initalCustomerName}
                            initalSalesmanName = {initalSalesmanName}
                            initalSalesmanId = {initalSalesmanId}
                            priceListItems={priceListItems}
                             setPriceListItems={setPriceListItems}
                            //  SETTING_PRICE_INCLUDE_VAT = {SETTING_QUOTE.PRICE_INCLUDE_VAT}
                             SETTING_INVOICE = {SETTING_INV_CURRENCY}
                             productList={productList}
                             setProductList={setProductList}
                             taxesList={taxesList}
                             setTaxesList={setTaxesList}


                    />

                       {/* <InvFormFooter
                        invNotes = {invNotes}
                        setInvNotes = {setInvNotes}
                        invTotalAmount = {invTotalAmount}
                        totalAmountWithDiscount = {totalAmountWithDiscount} 
                        invCurrency = {invCurrency}
                        promoCode = {promoCode}
                        setPromoCode = {setPromoCode}
                        discount = {discount}
                        vat = {vat}
                        netTotal = {netTotal}
                        havePromoCode = {havePromoCode}
                        setHavePromoCode = {setHavePromoCode}
                        promoCodeHandler = {promoCodeHandler}
                        discountOnchange = {discountOnchange}
                        // SETTING_PRICE_INCLUDE_VAT = {SETTING_QUOTE.PRICE_INCLUDE_VAT}
                        SETTING_INV_CURRENCY = {SETTING_INV_CURRENCY}
                        tagList = {tagList} 
                        setTagList = {setTagList}
                    /> */}
                    <InvFormBody 
                        SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION={SETTING_SHOW_INVOICE_PRODUCT_DESCRIPTION}
                        SETTING_INV_QTY_DAYS={SETTING_INV_QTY_DAYS}
                        SETTING_INV_UOM_SHOW={SETTING_INV_UOM_SHOW}
                        initalProductItem={productDetails}
                        reloadProductList={reloadProductList}
                        showModalProductHandle={showModalProductHandle}
                        productList={productList}
                        setProductList={setProductList}
                        formErrors={formErrors}
                        invNotes={invNotes}
                        invTerms={invTerms}
                        setInvNotes={setInvNotes}
                        setInvTerms={setInvTerms}
                        isEdit={true}
                        priceListItems = {priceListItems}
                        setVat={setVat}
                        invoiceSettings={invoiceSettings}
                        taxesList={taxesList}
                        setTaxesList={setTaxesList}

                    /> 

<div className='flex md:flex-row flex-col gap-2  items-top p-3 justify-between text-sm'>
                        <div className="flex-1  flex items-center justify-between">
                            <CardAmount
                                label= 'الإجمالي قبل الضريبة'
                                amountValue={invTotalAmount}
                                footerValue={SETTING_INV_CURRENCY}
                                />
                            <div id='discount-card' className=' flex-1 text-center '>
                                <div className=' text-slate-400 text-xs'> الخصم علي إجمالي الفاتورة </div>
                                <div>.</div>
                                <MwInputText
                                    value={discount}
                                    // placeholder={t('invoice.discount')}
                                    disabled={true}
                                    classNameInput = 'my-1'
                                    size='sm'
                                    onChange={(e)=>discountOnchange(e.target.value)}
                                />
                                {/* <div className='text-slate-400 text-xs'>{ SETTING_INV_CURRENCY }</div> */}
                            </div>
                            <CardAmount
                                label= 'الإجمالي بعد الخصم'
                                amountValue={ totalAmountWithDiscount }
                                footerValue={SETTING_INV_CURRENCY}
                                />
                        </div>
                        <div className="flex-1 flex items-center justify-between">
                            <CardAmount
                                label={t('invoice.vatAmount')}
                                amountValue={vat}
                                footerValue={SETTING_INV_CURRENCY}
                                />
                            <CardAmount
                                label={t('invoice.netTotal')}
                                amountValue={netTotal}
                                footerValue={SETTING_INV_CURRENCY}
                                />                        
                        </div>
                    </div>
                  
                </div>
            </form> }
        </>
            )
        }
        
        </>
       
    )
}

export default InvFormUpdate