import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAddBox, MdClose, MdDelete, MdEdit } from 'react-icons/md';
 import * as XLSX from 'xlsx';
 import toast, { Toaster } from 'react-hot-toast';

import { useDispatch, useSelector } from 'react-redux';
import { getListPrice } from '../../store/ListPriceSlice';
import { useCurrent, usePut, usePost, useDelete } from '../../hooks';
import { FaFileExcel, FaRegSave, FaTimes } from 'react-icons/fa';
 import useDeleteWithBody from './../../hooks/useDeleteWithBody';
import MwButton from './../ui/MwButton';
import { MwSelectorProduct, MwSpinner, NoDataFound } from '../ui';
import { LuLayoutDashboard } from 'react-icons/lu';
import CardHeadeWithActions from './../ui/CardHeadeWithActions';
import DeleteConfirmationModal from '../Common/OsDeleteConfirmationModal ';
 const CustomTable = ({ id, setMode }) => {
  const { t, i18n } = useTranslation('global');
  const isRTL = i18n.dir() === 'rtl';

  const { currentTenantId, currentCompanyId, currentBranchId } = useCurrent();
  const [rows, setRows] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newRow, setNewRow] = useState({
    id: `temp-initial-${Date.now()}`, 
    item: '',
    price: '',
    product_id: 0
  });
  const [showContent, setShowContent] = useState(false);
   
     useEffect(() => {
       const timer = setTimeout(() => {
         setShowContent(true);
       }, 2300);
       return () => clearTimeout(timer);
    }, []);
  const [errors, setErrors] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const dispatch = useDispatch();
  const { putData, data: putResponse, error: putError } = usePut();
  const { data: postResponse, loading: postloading, error: postError, postData } = usePost();
  const { ListData, loading, error } = useSelector((state) => state.ListPrice);
  const { deleteItem, data: deleteResponse, error: deleteError } = useDelete();
  const { deleteWithBody , data:DataDelete,loading:LoadDelete,error:ErrDelete} = useDeleteWithBody();

  useEffect(() => {
    if (postResponse?.status === 1) {
      toast.success(t('listPrice.saveSuccess'), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      setTimeout(() => {
        setMode('List');
      }, 2000); } else if (putError || postError || deleteError) {
      toast.error(t('listPrice.saveError'), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
    }
  }, [setMode, postResponse, postError, loading, deleteError, t, putError]);

  useEffect(() => {
    if (deleteResponse?.status === 1) {
      toast.success(t('listPrice.delSuccess'), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
    } else if (deleteError) {
      toast.error(t('listPrice.delError'), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
    }
  }, [deleteResponse, deleteError, t]);
  useEffect(() => {
    if (DataDelete?.status === 1) {
      toast.success(t('listPrice.delSuccess'), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
    } else if (ErrDelete) {
      toast.error(t('listPrice.delError'), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
    }
  }, [DataDelete, ErrDelete, t]);

  useEffect(() => {
    if (id) {
      dispatch(getListPrice(`${process.env.REACT_APP_INV_API_SALES_SERVER_BASE_URL}/inventory/list-price-items/${id}`));
    }
  }, [dispatch, id]);

  const units = Array.isArray(ListData) ? ListData : [];

  useEffect(() => {
    if (units.length > 0) {
      // تأخير لمدة 1500 ميللي ثانية (1.5 ثانية)
      const timer = setTimeout(() => {
        const newRows = units.map((unit) => ({
          id: unit.id,
          item: unit.product_name ? unit.product_name.trim() : '',
          price: unit.product_sale_price || '',
          list_price_id: unit.list_price_id,
          product_id: unit.product_id || 0
        }));
        setRows(newRows);
      }, 1500);
  
      // تنظيف الـ timeout لو الـ component اتفك من الDOM قبل ما ينتهي التأخير
      return () => clearTimeout(timer);
    } else {
      setRows([]);
    }
  }, [units]);
  

  if (loading || !showContent) {
         return (
              <MwSpinner />
          
         );
       }

  if (error) {
    return (
      <div className="text-center p-4">
        <p className="text-red-500">{t('listPrice.saveError')}: {error}</p>
      </div>
    );
  }

  const addOrUpdateRow = () => {
    const newErrors = {};
    if (!newRow.item) newErrors.item = t('listPrice.listNameRequired');
    if (!newRow.price) newErrors.price = t('listPrice.fillRequired');

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    if (isEditing) {
      const updatedRows = rows.map((row) =>
        row.id === newRow.id ? { ...row, item: newRow.item, price: newRow.price } : row
      );
      setRows(updatedRows);
    } else {
       const uniqueId = `temp-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
      const updatedRow = { 
        ...newRow, 
        id: uniqueId,   
        isNew: true 
      };
      setRows([...rows, updatedRow]);
    }

    setNewRow({ id: null, item: '', price: '', product_id: 0 });
    setErrors({});
    setShowModal(false);
    setIsEditing(false);
    setHasChanges(true);
  };

  const handleInputChange = (e, field) => {
    setNewRow(prevRow =>({ ...newRow, [field]: e.target.value }));
    setErrors({ ...errors, [field]: '' });
  };

  const handleProductSelect = (selectedProduct) => {
    if (selectedProduct && selectedProduct._id) {
      const productName = selectedProduct.productName[0]?.text || selectedProduct.productName[1]?.text || '';
       setNewRow(prevRow => ({
        ...prevRow,
        item: productName,
        product_id: selectedProduct._id,
        price: selectedProduct.price || '',
        uomSale: selectedProduct.uomSale || null
      }));
    } else {
       setNewRow(prevRow => ({ ...prevRow, item: selectedProduct, product_id: 0 }));
    }
  };

  const handleDeleteClick = (id) => {
    console.log("Deleting item with ID:", id);
    console.log("Current rows:", rows);
    
    const rowToDelete = rows.find((row) => row.id === id);
    console.log("Row to delete:", rowToDelete);
    
    if (rowToDelete && rowToDelete.isNew) {
      // Delete only this specific new item
      const updatedRows = rows.filter((row) => row.id !== id);
      console.log("Updated rows after deletion:", updatedRows);
      setRows(updatedRows);
      setHasChanges(true);
    } else {
      setItemToDelete(id);
      setDeleteModalOpen(true);
    }
  };

  const handleConfirmDelete = async () => {
    if (selectedIds.length > 0) {
      const hasNewItems = selectedIds.some((id) => rows.find((row) => row.id === id && row.isNew));
      if (hasNewItems) {
        toast.error(t('messages.cannotDeleteNewItem'), {
          style: {
            backgroundColor: '#e5e7eb',
            color: '#374151',
            padding: '1rem',
            borderRadius: '0.5rem',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
          }
        });
        return;
      }
      await handleDeleteMultipleItems();
    } else if (itemToDelete) {
      const rowToDelete = rows.find((row) => row.id === itemToDelete);
      if (rowToDelete && rowToDelete.isNew) {
        toast.error(t('messages.cannotDeleteNewItem'), {
          style: {
            backgroundColor: '#e5e7eb',
            color: '#374151',
            padding: '1rem',
            borderRadius: '0.5rem',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
          }
        });
        return;
      }
      try {
        await deleteItem(`${process.env.REACT_APP_INV_API_SALES_SERVER_BASE_URL}/inventory/list-price-items/${itemToDelete}`);
        
        dispatch(getListPrice(`${process.env.REACT_APP_INV_API_SALES_SERVER_BASE_URL}/inventory/list-price-items/${id}`));
      } catch (error)  {
        console.error('Error deleting item:', error);
      }
    }
    setDeleteModalOpen(false);
    setItemToDelete(null);
  };

  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
    setItemToDelete(null);
  };

  const openEditModal = (row) => {
    if (row.isNew) {
      toast.error(t('messages.cannotEditNewItem'), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      return;
    }
    setSelectedItem(row);
    setNewRow({ id: row.id, item: row.item, price: row.price, product_id: row.product_id || 0 });
    setIsEditing(true);
    setShowModal(true);
  };

  const handleUpdate = async () => {
    if (!selectedItem) return;

    if (!selectedItem.list_price_id || !selectedItem.id) {
      toast.error(t('messages.invalidDataForUpdate'), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      return;
    }

    const updatedData = {
      list_price_id: selectedItem.list_price_id,
      product_id: newRow.product_id || 0,
      product_name: newRow.item,
      product_sale_price: newRow.price
    };

    try {
      await putData(`${process.env.REACT_APP_INV_API_SALES_SERVER_BASE_URL}/inventory/list-price-items/${selectedItem.id}`, updatedData);
      setShowModal(false);
      dispatch(getListPrice(`${process.env.REACT_APP_INV_API_SALES_SERVER_BASE_URL}/inventory/list-price-items/${id}`));
      setHasChanges(false);
    } catch (error) {
      toast.error(t('listPrice.saveError'), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      console.error('Error updating item:', error);
    }
  };

  const handleSaveData = async () => {
    const payload = rows.map((row) => ({
      list_price_id: id,
      product_id: row.product_id,
      product_name: row.item,
      product_sale_price: row.price,
      tenant_id: currentTenantId,
      company_id: currentCompanyId,
      branch_id: currentBranchId
    }));
    console.log("payload", payload);
    console.log("units", units);

    try {
      await postData(`${process.env.REACT_APP_INV_API_SALES_SERVER_BASE_URL}/inventory/list-price-items`, payload);
      console.log("payload", payload);
      setHasChanges(false);
    } catch (error) {
      toast.error(t('listPrice.saveError'), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      console.error('Error saving data:', error);
    }
  };

  const handleBack = () => {
    setMode('List');
  };

  const handleAddProduct = () => {
    return false;
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (!file.name.endsWith('.xlsx') && !file.name.endsWith('.xls') && !file.name.endsWith('.csv')) {
      toast.error(t('messages.fileNotAllowed'), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: { backgroundColor: '#e5e7eb', color: '#374151' }
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      let workbook;

      if (file.name.endsWith('.csv')) {
        const csvData = event.target.result;
        workbook = XLSX.read(csvData, { type: 'string' });
      } else {
        workbook = XLSX.read(data, { type: 'array' });
      }

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const newRows = jsonData.slice(1).reduce((acc, row, index) => {
        if (!row[0] || !row[1]) {
          let errorMsg = '';
          if (!row[0]) {
            errorMsg += t('messages.nameEmpty') + ' ';
          }
          if (!row[1]) {
            errorMsg += t('messages.priceEmpty');
          }
          toast.error(errorMsg.trim(), {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: { backgroundColor: '#e5e7eb', color: '#374151' }
          });
          return acc;
        }
        acc.push({
          id: `temp-excel-${Date.now()}-${index}`,
          item: row[0],
          price: row[1],
          product_id: 0,
          isNew: true,
          source: 'excel'
        });
        return acc;
      }, []);
      

      setRows([...rows, ...newRows]);
      setHasChanges(true);
    };

    if (file.name.endsWith('.csv')) {
      reader.readAsText(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  const handleDeleteMultipleItems = async () => {
    if (selectedIds.length === 0) return;
    const idDelete=  {
      ids: selectedIds
    }
    console.log("idDelete",idDelete)
    try {
      await deleteWithBody(`${process.env.REACT_APP_INV_API_SALES_SERVER_BASE_URL}/inventory/list-price-items`,idDelete);
    
      dispatch(getListPrice(`${process.env.REACT_APP_INV_API_SALES_SERVER_BASE_URL}/inventory/list-price-items/${id}`));
      setSelectedIds([]);
    } catch (error) {
      toast.error(t('listPrice.delError'), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      console.error('Error deleting items:', error);
    }
  };

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      const allIds = rows.filter((row) => !row.isNew).map((row) => row.id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const handleCheckItem = (id) => {
    const row = rows.find((row) => row.id === id);
    if (row && row.isNew) {
      toast.error(t('messages.cannotSelectNewItem'), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      return;
    }

    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((itemId) => itemId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const generateExcelTemplate = () => {
    const headers = ['Item', 'Brand', 'Category', 'Product Price'];
    const sampleData = [
      ['Sample Product 1', 'Brand A', 'Category 1', '100'],
      ['Sample Product 2', 'Brand B', 'Category 2', '150'],
    ];
    
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...sampleData]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Template');
    XLSX.writeFile(workbook, 'import_template.xlsx');
  };


  return (
    <div className=" text-xs text-slate-500 p-3">
      <Toaster
  position="top-center"
  containerStyle={{
    top: 60, 
  }}
  toastOptions={{
    duration: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    pauseOnHover: true,
    style: {
      direction: "rtl"
    }
  }}
/>
        <CardHeadeWithActions title={  
                 <div className='flex   items-center gap-2 '>
      <LuLayoutDashboard  className="w-6 h-6" /> 
      <span>
      {t('listPrice.managementlists_price')}
      </span>
    </div>
}>
                 <MwButton
                        actionType="button"
                        inGroup={true}
                        type="saveBtn"
                        onClick={()=>setMode('List')}
                    >
                     
                  
                     {t('listPrice.lists_price')} 
                    </MwButton>
            </CardHeadeWithActions>
       <div className={`bg-white my-4 rounded-lg  p-3 overflow-y-scroll custom-scrollbar ${rows.length > 0 ? "h-[calc(100vh-100px)]" : ""}`}>
       <div className="mb-6 w-full flex justify-between items-center">
  {/* مجموعة أدوات الاستيراد وتحميل القالب */}
  <div className="flex items-center gap-1">
    <div className="  text-slate-500">
      <ul className="flex gap-1">
        {/* زر استيراد ملف Excel */}
        <li
          style={{ cursor: "pointer" }}
          className="px-2 py-1 flex items-center gap-1 bg-slate-100 hover:font-bold"
        >
          <label className="flex items-center cursor-pointer">
            <input
              type="file"
              accept=".xlsx, .xls, .csv"
              onChange={handleFileUpload}
              className="hidden"
            />
            <FaFileExcel className="ml-1" />
            <span className="text-sm text-slate-500">{t("actions.excelImport")}</span>
          </label>
        </li>

        {/* زر تحميل قالب Excel */}
        <li
          style={{ cursor: "pointer" }}
          onClick={generateExcelTemplate}
          className="px-2 py-1 flex items-center gap-1 bg-slate-100 hover:font-bold"
        >
          <FaFileExcel className="ml-1" />
          <span className="text-sm text-slate-500">{t("actions.downloadTemplate")}</span>
        </li>
      </ul>
    </div>
  </div>

  {/* زر التحديث */}
  <div className="inline-flex">
    <MwButton
      size="sm"
      onClick={handleSaveData}
      type="secondary"
      inGroup={true}
      disabled={postloading || !hasChanges}
    >
      <FaRegSave className="mr-1" />
      <span>{t("actions.save")}</span>
    </MwButton>
  </div>
</div>




      {rows.length === 0 ? (
        <div className="h-auto">
          <NoDataFound msg={t("deleted.no_product_in_warehouses")} />
        </div>
      ) : (
        <div className='overflow-x-auto'>
          <table className="min-w-full bg-white text-gray-800 border-collapse rounded-lg overflow-hidden shadow-sm">
            <thead>
              <tr>
                <th className="text-gray-500 bg-slate-200 font-bold text-sm p-3">
                  <div className="flex flex-col items-center gap-2">
                    <p className="text-sm font-medium text-gray-700 mb-2">{t('table.selectAll')}</p>
                    <input 
                      type="checkbox" 
                      onChange={handleCheckAll} 
                      checked={selectedIds.length === rows.length && rows.length > 0}
                    />
                  </div>
                </th>
                <th className="text-gray-500 bg-slate-200 font-bold text-sm p-3 text-center">#</th>
                <th className="text-gray-500 bg-slate-200 font-bold text-sm p-3 text-center">{t('table.brand')}</th>
                <th className="text-gray-500 bg-slate-200 font-bold text-sm p-3 text-center">{t('table.category')}</th>
                <th className="text-gray-500 bg-slate-200 font-bold text-sm p-3 text-center">{t('table.productPrice')}</th>
                <th className="text-gray-500 bg-slate-200 font-bold text-sm p-3 text-center">{t('table.actions')}</th>
              </tr>
            </thead>
            
            <tbody>
              {rows.map((row) => (
                <tr 
                  key={row.id} 
                  className={`${row.isNew ? "hover:bg-gray-50" : "hover:bg-gray-50"}`}
                >
                  <td className="border p-3 text-center">
                    <input
                      type="checkbox"
                      checked={selectedIds.includes(row.id)}
                      onChange={() => handleCheckItem(row.id)}
                      disabled={row.isNew}
                    />
                  </td>
                  <td className="border p-3 text-center">{row.item}</td>
                  <td className="border p-3 text-center">-</td>
                  <td className="border p-3 text-center">-</td>
                  <td className="border p-3 text-center">{row.price}</td>
                  <td className="border p-3">
                    <div className="flex justify-center gap-2">
                      <button
                        onClick={() => openEditModal(row)}
                        className={`p-1 rounded hover:bg-blue-100 ${row.isNew ? "opacity-50 cursor-not-allowed" : ""}`}
                        disabled={row.isNew}
                      >
                        <MdEdit className="text-blue-600" size={20} />
                      </button>
                      <button
                        onClick={() => handleDeleteClick(row.id)}
                        className="p-1 rounded hover:bg-red-100"
                      >
                        <MdDelete className="text-red-600" size={20} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="flex justify-between items-center mt-4">
        <div>
          {selectedIds.length > 0 && (
            <MwButton 

              onClick={() => setDeleteModalOpen(true)} 
              type="deleteBtn"
             >
              <MdDelete className="mr-2" /> {t('actions.delete')}
            </MwButton>
          )}
        </div>
        
        <MwButton
         size='sm'
        type='secondary'
          onClick={() => {
         
            setNewRow({ id: null, item: '', price: '', product_id: 0 });
            setIsEditing(false);
            setShowModal(true);
          }}
        
          className="bg-blue-500 text-white px-4 py-2 rounded flex items-center hover:bg-blue-600 transition-colors"
        >
          <MdAddBox className="mr-2" /> {t('actions.add')}
        </MwButton>
        {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg w-11/12 max-w-[50%] relative">
            <button onClick={() => setShowModal(false)} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
              <MdClose size={24} />
            </button>

            <h3 className="text-lg font-bold mb-4">
              {isEditing ? t('actions.update') : t('actions.add')}
            </h3>
            <div className="space-y-4 mb-3">
              <div>
                <label className="block">{t('table.item')}:</label>
                <MwSelectorProduct
                  onChangeCallback={handleProductSelect}
                  withLabel={false}
                  value={newRow.item}
                  initalValue={newRow.item}
                  onCreateProduct={handleAddProduct}
                />
                {errors.item && <p className="text-red-500 text-sm">{errors.item}</p>}
              </div>
              <div className="flex gap-4 ">
                <div className="flex-1">
                  <label className="block">{t('listPrice.price')}</label>
                  <input
                    type="number"
                    value={newRow.price} 
                    onChange={(e) => handleInputChange(e, 'price')}
                    className="w-full p-2 border rounded"
                  />
                  {errors.price && <p className="text-red-500 text-sm">{errors.price}</p>}
                </div>
                <div className="flex-1">
                  <label className="block">{t('table.uomSale')}</label>
                  <input
                    type="text"
                    value={newRow.uomSale?.name || ''}
                    disabled
                    className="w-full p-2 border rounded bg-gray-50"
                  />
                </div>
              </div>
            </div>

            <MwButton
              onClick={isEditing ? handleUpdate : addOrUpdateRow}
              type='secondary'
               
             >
              {isEditing ? t('actions.update') : t('actions.add')}
            </MwButton>
          </div>
        </div>
      )}
      </div>

      

      <DeleteConfirmationModal
        isOpen={deleteModalOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        message={selectedIds.length > 1 ? t('actions.confirmMultipleDelete') : t('actions.confirmDelete')}
      />
    </div>
    </div>
  );
};

export default CustomTable;