import  { useState,useEffect } from 'react'
import {MwButton, MwSpinner} from '../../components/ui'
import {langObjType,objLangTextHandler,LangArrayToObjKeyValue} from '../../utils/global'
import {AiOutlineSave} from 'react-icons/ai'
import {MdOutlineAddBox} from 'react-icons/md'
import BaiscInfo from './forms/BaiscInfo'
import StockAndPrice from './forms/StockAndPrice'
import SEO from './forms/SEO'
import ProductLinks from './forms/ProductLinks'
import { useCurrent, useFetch, usePut } from '../../hooks'
import {PRODUCT_TYPE_LIST} from '../../pages/products/constants'
import ProductImages from './forms/ProductImages'
import {allowImgTypes} from '../../pages/products/constants'
import { useTranslation } from 'react-i18next'
import useUploadFile from './../../hooks/useUploadFile';




const EditProduct = ({id,onHide,onProductCreated,setReloadProductList,setMode}) => {
    const [t,i18n ] = useTranslation('global')

    const [tabActive,setTabActive] = useState('TabOne'); // tabs [ TabOne , TabTwo ]
    const [errors,setErrors] = useState({});
    const {currentTenantId,currentCompanyId,currentLangId} = useCurrent();
    const {data:product,loading:loadingProduct} = useFetch(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/show/${id}`);
    const {data:resultPut,putData} = usePut();
    // Start Form state //////
    const [productCode,setProductCode] = useState('');
    const [productBarcode,setProductBarcode] = useState('');
    const [productFactoryCode,setProductFactoryCode] = useState('');
    const [productName,setProductName] = useState(langObjType());
    const [productDesc,setProductDesc] = useState(langObjType());
    const [productPrice,setProductPrice] = useState('');
    const [productTypeSelected,setProductTypeSelected] = useState();
    // const [productMetadata,setProductMetadata] = useState('');
    const [isActive,setIsActive] = useState(true);
    const [isShowOnWebsite,setIsShowOnWebsite] = useState(false);
    const [qty,setQty] = useState(0);
    const [cost,setCost] = useState();
    const [productMetaKeywords,setProductMetaKeywords] = useState(langObjType());
    const [productMetaDesc,setProductMetaDesc] = useState(langObjType());
    const [isStock,setIsStock] = useState(false);
    const [codeInStock,setCodeInStock] = useState();
    const [weightProduct,setWeightProduct] = useState();
    const [sizeProduct,setSizeProduct] = useState();
    const [uomSaleSelected,setUomSaleSelected] = useState();
    const [uomPurchaseSelected,setUomPurchesSelected] = useState();
    const [uomTemplateSelected,setTemplateUomSelected] = useState();
    // Product Links state
    const [genProductCode,setGenProductCode] = useState();
    const [productCategorySelected,setProductCategorySelected] = useState([]);
    const [productBrandSelected,setProductBrandSelected] = useState();
    const [productTags,setProductTags] = useState([]);
    // Product Images
    const [productImages,setProductImages] = useState([]);
    const [productHomePicDefault,setProductImagesDefault] = useState();
    const {location:productHomePic,loading:loadingUploadHomePic,handleUpload:handleHomePicUpload} = useUploadFile(allowImgTypes,`products-${currentTenantId}`,'image')
    const {location:productImagesItem,loading:loadingUploadProductImages,handleMultiUploads:handleImagesItemUpload} = useUploadFile(allowImgTypes,`products-${currentTenantId}`,'image')
    const {resultdelete,loadingDeleting,handleDelete} = useUploadFile()

    useEffect(()=>{
        if(productImagesItem){
            setProductImages((state) => [...state,productImagesItem]);
            console.log('productImages=>',productImages);
        }
    },[productImagesItem])

    useEffect(() => {
        if(productHomePic) {
            setProductImagesDefault(productHomePic);
        }
    }, [productHomePic])

    const cancelHandle = () => {
        onHide();
    }

    const saveHandler = async (e) => {
        e.preventDefault(true);
        let errorObj = {};
        if(!productName[currentLangId]){
            errorObj = { ...errorObj, [`productName.${currentLangId}`] : 'You must specify a product name'};
            //setCurrentLang(defaultLang)
        }
        if(!productTypeSelected){
            errorObj = {...errorObj, productType:' Product type is required  '}
        }

        if(!productPrice){
            errorObj = {...errorObj, price:'You must specify a product price'}
            productName[currentLangId] && setTabActive('TabTwo');
        }else{
            if( isNaN(parseFloat(productPrice))){
                errorObj = {...errorObj, price:' Price is not a number  '}
                productName[currentLangId] && setTabActive('TabTwo');
            }
        }
        
        if(Object.keys(errorObj).length !== 0 ){
            setErrors(errorObj)
            console.log('errors',errors)
            return
        }
        onHide && setReloadProductList(false)
        const _data = {
            productName : objLangTextHandler(productName) ,
            productDesc :objLangTextHandler(productDesc),
            productMetaKeywords :objLangTextHandler(productMetaKeywords),
            productMetaDesc :objLangTextHandler(productMetaDesc),
            productTags: JSON.stringify(productTags),
            productCategory: productCategorySelected?.map((item) => item['value'] ),
            productBrand:productBrandSelected?.value,
            productType: productTypeSelected?.value ,
            productHomePic: productHomePic || productHomePicDefault,
            productImages: productImages,
            productCode,
            productBarcode,
            productFactoryCode,
            codeInStock,
            weightProduct,
            sizeProduct,
            price:productPrice,
            qty:qty,
            cost: cost,
            uomTemplate:{
                id : uomTemplateSelected?.data.id,
                name : uomTemplateSelected?.data.name,
            },
            uomSale:{
                id: uomSaleSelected?.data.id,
                name: uomSaleSelected?.data.name,
                short_name: uomSaleSelected?.data.short_name,
                rate: uomSaleSelected?.data.rate,
            },
            uomPurchase:{
                id: uomPurchaseSelected?.data.id,
                name: uomPurchaseSelected?.data.name,
                short_name: uomPurchaseSelected?.data.short_name,
                rate: uomPurchaseSelected?.data.rate,
            },
            isStock,
            isShowOnWebsite,
            isActive,
            tenantId:currentTenantId,
            companyId : currentCompanyId
        };
        console.log(_data);
        putData(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/update/${id}`,_data);
  

    }

    useEffect(()=>{
        if(resultPut){
            if(resultPut.status === 1){
                localStorage.setItem('isProductsUpdated','true')
                onHide && cancelHandle();
            }else{
                console.log('Error:',resultPut)
            }
            onHide ? onProductCreated() : setMode('List') ;
        }
    },[resultPut])

    useEffect(()=>{
        if(product?.data && !loadingProduct ){
            const cats = product?.data.productCategory.map((cat)=>{
                return {
                    label:LangArrayToObjKeyValue(cat.categoryName)?.[currentLangId],
                    value:cat._id,
                    lang:currentLangId,
                    data:cat
                }
            })
            
            setProductName(LangArrayToObjKeyValue(product?.data.productName));
            setProductDesc(LangArrayToObjKeyValue(product?.data.productDesc));
            setIsShowOnWebsite(product?.data.isShowOnWebsite);
            setIsActive(product?.data.isActive);
            setProductCode(product?.data.productCode);
            setProductPrice(product?.data.price);
            setProductBarcode(product?.data.productBarcode)
            setProductFactoryCode(product?.data.productFactoryCode)
            setIsStock(product?.data.isStock)
            setCodeInStock(product?.data.codeInStock)
            setQty(product?.data.qty)
            product?.data?.uomTemplate && setTemplateUomSelected({
                label : product?.data?.uomTemplate?.name,
                value : product?.data?.uomTemplate?.id,
                data : product?.data?.uomTemplate
            })
            product?.data?.uomSale && setUomSaleSelected({
                label : product?.data?.uomSale?.short_name,
                value : product?.data?.uomSale?.id,
                data : product?.data?.uomSale,
            })
            product?.data?.uomPurchase && setUomPurchesSelected({
                label : product?.data?.uomPurchase?.short_name,
                value : product?.data?.uomPurchase.id,
                data : product?.data?.uomPurchase,
            })
            setCost(product?.data.cost)
            setWeightProduct(product?.data.weightProduct)
            setSizeProduct(product?.data.sizeProduct)
            setProductCategorySelected(cats)
            setProductBrandSelected({
                label:LangArrayToObjKeyValue(product?.data.productBrand?.brandName)?.[currentLangId],
                value: product?.data.productBrand?._id,
                lang: currentLangId
            });
            if(product?.data?.productType){
                const _select = PRODUCT_TYPE_LIST.filter(t => t.value === product?.data?.productType && t.lang === currentLangId)[0]
                setProductTypeSelected(_select)
            }
            if(product?.data?.productHomePic){
                setProductImagesDefault(product?.data?.productHomePic)
            }
            if(product?.data?.productImages){
                setProductImages(product?.data?.productImages)
            }
            setProductTags(JSON.parse(product?.data?.productTags))
            setProductMetaKeywords(LangArrayToObjKeyValue(product?.data?.productMetaKeywords))
            setProductMetaDesc(LangArrayToObjKeyValue(product?.data?.productMetaDesc))
           // console.log(product)
        }
    },[product]);

    return (
        <div className='p-3' >
            { product && !loadingProduct ? <form  >
                <div className='flex justify-between items-center mb-3 text-sm '>
                <div className='text-slate-400 flex gap-1'>
    <MdOutlineAddBox size={18}/>
    {t('product.editProduct')}
</div>
<div className='flex'>
    {onHide && <MwButton inGroup={true} type='cancelBtn' onClick={cancelHandle}>{t('common.cancel')}</MwButton>}
    <MwButton type="cancelBtn" inGroup={true} onClick={() => setMode('List')}>{t('product.manageProducts')}</MwButton>
    <MwButton inGroup={true} type='saveBtn' onClick={saveHandler}>{t('product.save')} <AiOutlineSave size={18}/></MwButton>
</div>
</div>
<div className='bg-slate-50 rounded-lg text-sm flex flex-col justify-between items-between p-2'>
    <ul className='flex sticky top-8 text-gray-600 text-xs'>
        <li 
            onClick={() => setTabActive('TabOne')} 
            className={`cursor-default px-2 py-1 m-1 text-gray hover:text-sky-700 ${tabActive === 'TabOne' && 'text-sky-900 border-sky-900 border-b'}`}>
            {t('product.basicInfo')}
        </li>
        <li 
            onClick={() => setTabActive('TabTwo')}  
            className={`cursor-default px-2 py-1 m-1 text-gray hover:text-sky-700 ${tabActive === 'TabTwo' && 'text-sky-900 border-sky-900 border-b'}`}>
            {t('product.warehousePricing')}
        </li>
        <li 
            onClick={() => setTabActive('TabThree')}  
            className={`cursor-default px-2 py-1 m-1 text-gray hover:text-sky-700 ${tabActive === 'TabThree' && 'text-sky-900 border-sky-900 border-b'}`}>
            {t('product.productLinks')}
        </li>
        <li 
            onClick={() => setTabActive('TabFour')}  
            className={`cursor-default px-2 py-1 m-1 text-gray hover:text-sky-700 ${tabActive === 'TabFour' && 'text-sky-900 border-sky-900 border-b'}`}>
            {t('product.seo')}
        </li>
        <li 
            onClick={() => setTabActive('TabFive')}  
            className={`cursor-default px-2 py-1 m-1 text-gray hover:text-sky-700 ${tabActive === 'TabFive' && 'text-sky-900 border-sky-900 border-b'}`}>
            {t('product.productImages')}
        </li>
    </ul>
                    <div id='content-body' >
                        {
                            tabActive === 'TabOne' && <BaiscInfo     
                                                            productName = {productName}
                                                            setProductName = {setProductName}
                                                            productDesc = {productDesc}
                                                            setProductDesc = {setProductDesc}
                                                            isShowOnWebsite = {isShowOnWebsite}
                                                            setIsShowOnWebsite = {setIsShowOnWebsite}
                                                            isActive = {isActive}
                                                            setIsActive = {setIsActive}
                                                            errors = {errors}
                                                            productTypeSelected = {productTypeSelected}
                                                            setProductTypeSelected = {setProductTypeSelected}
                                                            />
                            
                        }
                        {
                            tabActive === 'TabTwo' && <StockAndPrice
                                                            productCode = {productCode}
                                                            setProductCode = {setProductCode}
                                                            productPrice = {productPrice}
                                                            setProductPrice = {setProductPrice}
                                                            productBarcode = {productBarcode}
                                                            setProductBarcode = {setProductBarcode}
                                                            productFactoryCode = {productFactoryCode}
                                                            setProductFactoryCode = {setProductFactoryCode}
                                                            isStock = {isStock}
                                                            setIsStock = {setIsStock}
                                                            codeInStock = {codeInStock}
                                                            setCodeInStock = {setCodeInStock}
                                                            qty = {qty}
                                                            setQty = {setQty}
                                                            cost = {cost}
                                                            setCost = {setCost}
                                                            weightProduct = {weightProduct}
                                                            setWeightProduct = {setWeightProduct}
                                                            sizeProduct = {sizeProduct}
                                                            setSizeProduct  = {setSizeProduct}
                                                            errors = {errors}
                                                            uomSaleSelected = {uomSaleSelected}
                                                            setUomSaleSelected = {setUomSaleSelected}
                                                            uomPurchaseSelected = {uomPurchaseSelected}
                                                            setUomPurchesSelected = {setUomPurchesSelected}
                                                            uomTemplateSelected = {uomTemplateSelected}
                                                            setTemplateUomSelected = {setTemplateUomSelected}
                                                            />
                            
                        }
                        { tabActive === 'TabThree' && 
                        <ProductLinks
                            productCategorySelected = {productCategorySelected}
                            setProductCategorySelected = {setProductCategorySelected}
                            productBrandSelected = {productBrandSelected}
                            setProductBrandSelected = {setProductBrandSelected}
                            productTags = {productTags}
                            setProductTags = {setProductTags}
                            genProductCode = {genProductCode}
                            setGenProductCode = {setGenProductCode}
                            setProductCode = {setProductCode}
                            productCode = {productCode}
                        /> }
                        {
                            tabActive === 'TabFour' && 
                            <SEO
                                productMetaKeywords = {productMetaKeywords}
                                setProductMetaKeywords = {setProductMetaKeywords}
                                productMetaDesc = {productMetaDesc}
                                setProductMetaDesc = {setProductMetaDesc}
                            />
                            
                        }
                        {
                            tabActive === 'TabFive' && 
                            <ProductImages
                                productHomePic = {productHomePic} 
                                productHomePicDefault = {productHomePicDefault}
                                setProductImagesDefault={setProductImagesDefault}
                                loadingUploadHomePic = {loadingUploadHomePic}
                                handleHomePicUpload = {handleHomePicUpload}
                                productImages={productImages}
                                setProductImages={setProductImages}
                                productImagesItem = {productImagesItem}
                                loadingUploadProductImages = {loadingUploadProductImages}
                                handleImagesItemUpload = {handleImagesItemUpload}
                                resultdelete = {resultdelete}
                                loadingDeleting = {loadingDeleting}
                                handleDelete = {handleDelete}
                                />
                            
                        }
                    </div>
                </div>
            </form> : <MwSpinner/> }
        </div>
    )
}

export default EditProduct