import React, { useEffect, useRef } from 'react'

const MwTextArea = ({
  id,
  value,
  onChange,
  label,
  placeholder,
  rows = 4,
  invalid,
  invalidMsg,
  disabled = false,
  maxHeight = '300px'
}) => {
  const textareaRef = useRef(null);
  
   const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;
    
     textarea.style.height = 'auto';
    
     const newHeight = `${Math.min(textarea.scrollHeight, parseInt(maxHeight))}px`;
    textarea.style.height = newHeight;
  };
  
   useEffect(() => {
    adjustHeight();
  }, [value]);
  
  return (
    <div className="w-full mb-2">
      {label && (
        <label 
          className="block text-xs font-medium text-slate-500 mb-1 mr-1" 
          htmlFor={id}
        >
          {label}
        </label>
      )}
      
      <div className="relative">
        <textarea 
          ref={textareaRef}
          className={`w-full text-xs p-3 rounded-md transition-all duration-200 ease-in-out
            outline-none border ${invalid ? 'border-red-400' : 'border-slate-200'} 
            ${disabled ? 'bg-slate-100 text-slate-400' : 'bg-white text-slate-700'} 
            focus:border-blue-400 focus:ring-1 focus:ring-blue-300 hover:border-slate-300
            overflow-y-auto min-h-[80px]`}
          style={{
            resize: 'vertical',
            minHeight: `${rows * 20}px`,
            maxHeight: maxHeight
          }}
          id={id}
          name={id}
          value={value || ''}
          onChange={(e) => {
            onChange(e);
            adjustHeight();
          }}
          placeholder={placeholder}
          disabled={disabled}
        />
        
        {invalid && invalidMsg && (
          <p className="mt-1 text-xs text-red-500">{invalidMsg}</p>
        )}
      </div>
    </div>
  )
}

export default MwTextArea