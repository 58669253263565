import {LangArrayToObjKeyValue} from '../../../utils/global'
import {AiOutlineEdit} from 'react-icons/ai'
import {RiDeleteBin6Line} from 'react-icons/ri'
import { useCurrent } from '../../../hooks';
import { useTranslation } from 'react-i18next';

const ListBrandsItem = ({index,el,editHandeler,deleteHandeler}) => {

  const {currentLangId} = useCurrent();
  const brandName = LangArrayToObjKeyValue(el.brandName)?.[currentLangId] ;
const [t,i18n ] = useTranslation('global')
    const isRTL = i18n.dir() === 'rtl';
  return (
      <div key={el._id} className='flex justify-between items-center my-1 gap-2 border-b py-2 hover:font-bold hover:bg-indigo-50'>
       <div className='w-12 text-center'>{index}</div>
        <div className='flex-1 px-2'> <span>{brandName}</span> </div>
        <div className={` w-44 text-center ${el.isShowOnWebsite ? ' bg-green-200 ' : 'bg-slate-200' } px-2 pb-1 rounded-lg `}>{el.isShowOnWebsite ? `${t('product.showOnWebsite')}` : `${t('product.hideOnWebsite')}`}</div>
        <div className={` w-16 text-center px-2 pb-1 rounded-lg   ${el.isActive ? ' bg-green-200 ' : 'bg-slate-200' }`}>{el.isActive ? `${t('product.active')}` :`${t('product.inactive')}`}</div>
        <div className='w-8 text-center text-indigo-400 cursor-default hover:text-indigo-800  ' onClick={()=>editHandeler(el._id)}><AiOutlineEdit size={18}/></div>
        <div className='w-8 text-center text-indigo-400 cursor-default hover:text-indigo-800  ' onClick={()=>deleteHandeler(el._id)}><RiDeleteBin6Line size={18} /></div>
      </div>
  );

}

export default ListBrandsItem