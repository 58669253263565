import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MwButton, NoDataFound } from '../ui';
 import { MdOutlineEdit, MdOutlinePostAdd } from "react-icons/md";
 import { useCurrent, useDelete } from '../../hooks';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getListPrice } from '../../store/ListPriceSlice';
 import { useTranslation } from 'react-i18next';
import { FaFileAlt } from 'react-icons/fa';
  import CardHeadeWithActions from '../ui/CardHeadeWithActions';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import ActionsDropdown from '../Common/ActionsDropdown';
import DeleteConfirmationModal from '../Common/OsDeleteConfirmationModal ';
import { IoMdAddCircleOutline } from 'react-icons/io';
import ActiveIndicator from '../Common/ActiveIndicator';

const OSviewAllListsPrices = ({ idSelected, setIdSelected, setMode }) => {
  const { t, i18n } = useTranslation('global');
  const isRTL = i18n.dir() === 'rtl';
  const { currentTenantId, currentCompanyId, currentBranchId } = useCurrent();
  const navigate = useNavigate();
  const { tenant } = useParams();
  const dispatch = useDispatch();
  const { deleteItem } = useDelete();

  const { ListData, loading, error } = useSelector((state) => state.ListPrice);
  const units = Array.isArray(ListData) ? ListData : [];
  
  // لتحديد القائمة المفتوحة لكل صف
  const [openRowActions, setOpenRowActions] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = itemsPerPage === 10 
  ? units.slice(indexOfFirstItem, indexOfLastItem) 
  : units;
  const totalPages = Math.ceil(units.length / itemsPerPage);
 
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  
  // مرجع لتتبع النقر خارج القائمة المفتوحة
  const actionsRefs = useRef({});

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (openRowActions && actionsRefs.current[openRowActions] && !actionsRefs.current[openRowActions].contains(e.target)) {
        setOpenRowActions(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [openRowActions]);

  useEffect(() => {
    if (currentTenantId && currentCompanyId && currentBranchId) {
      dispatch(
        getListPrice(
          `${process.env.REACT_APP_INV_API_SALES_SERVER_BASE_URL}/inventory/list-price/${currentTenantId}/${currentCompanyId}/${currentBranchId}`
        )
      );
    }
  }, [dispatch, currentTenantId, currentCompanyId, currentBranchId]);

  const handleEdit = (id) => {
    setIdSelected(id);
    setMode('EditList');
  };

  const handleDelete = (id) => {
    setSelectedUnitId(id);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedUnitId) {
      const selectedUnit = units.find((unit) => unit.id === selectedUnitId);

      if (selectedUnit && selectedUnit.items && selectedUnit.items.length > 0) {
        toast.error(t("price.deleteError") || "لا يمكن الحذف! يجب حذف العناصر الداخلية أولاً.", {
          style: {
            backgroundColor: '#e5e7eb',
            color: '#374151',
            padding: '1rem',
            borderRadius: '0.5rem',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          },
        });
        setDeleteModalOpen(false);
        return;
      }

      deleteItem(`${process.env.REACT_APP_INV_API_SALES_SERVER_BASE_URL}/inventory/list-price/${selectedUnitId}`)
        .then(() => {
          toast.success(t("price.deleteSuccess") || "تم الحذف بنجاح!", {
            style: {
              backgroundColor: '#e5e7eb',
              color: '#374151',
              padding: '1rem',
              borderRadius: '0.5rem',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            },
          });
          setDeleteModalOpen(false);
          setSelectedUnitId(null);
          dispatch(
            getListPrice(
              `${process.env.REACT_APP_INV_API_SALES_SERVER_BASE_URL}/inventory/list-price/${currentTenantId}/${currentCompanyId}/${currentBranchId}`
            )
          );
        })
        .catch((error) => {
          toast.error(t("price.deleteErrorGeneric") || "حدث خطأ أثناء محاولة الحذف!", {
            style: {
              backgroundColor: '#e5e7eb',
              color: '#374151',
              padding: '1rem',
              borderRadius: '0.5rem',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            },
          });
          console.error('Error deleting unit:', error);
        });
    }
  };

  const handleView = (id) => {
    setIdSelected(id);
    setMode('ViewList');
  };
  const handleViewTable = (id) => {
    setIdSelected(id);
    setMode('DetailsLists');
  };

  const toggleActions = (id) => {
    setOpenRowActions((prev) => (prev === id ? null : id));
  };

  const AddUnit = () => {
    setMode('AddList');
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className='bg-gray-50 p-3 rounded-lg'>
        <div className='flex justify-end mb-3'>
        <MwButton inGroup={true} type="saveBtn" onClick={AddUnit}>
      <IoMdAddCircleOutline />
      <span>{t("price.addList")}</span>
    </MwButton>
        </div>
     


    <div className="bg-red-50 p-4 rounded-lg">
        <p className="text-red-600">
          {t("price.loadError") || "حدث خطأ أثناء تحميل البيانات:"} {error?.message || t("price.unknownError") || 'خطأ غير معروف'}
        </p>
      </div>
    
    </div>
    

      
    );
  }

  if (units.length === 0) {
    return (
      <div className="bg-gray-50 p-6 rounded-lg text-center ">
              <NoDataFound msg={t("reports.No_Results")}/> 
              <div>
          <MwButton inGroup={true} type="saveBtn" onClick={AddUnit}>
            <IoMdAddCircleOutline />
            <span>{t("price.addList")}</span>
          </MwButton>
        </div>
      </div>
    );
  }
 

  // if (units.length === 0) {
  //   return (
  //     <div className="bg-gray-50 p-6 rounded-lg text-center flex flex-col items-center justify-center">
  //       <p className="text-slate-600 mb-4">{t("price.noList") || "لا توجد قوائم أسعار متاحة"}</p>
  //       <div>
  //         <MwButton inGroup={true} type="saveBtn" onClick={AddUnit}>
  //           <IoAddCircleOutline />
  //           <span>{t("price.addList")}</span>
  //         </MwButton>
  //       </div>
  //     </div>
  //   );
  // }
 
  return (
    <div className="  min-w-[600px] h-[calc(100vh-200px)]  text-xs p-3 relative bg-gray-50   rounded-lg">
      <ToastContainer />

      <CardHeadeWithActions title={   t("price.listTitle")}>
      <MwButton inGroup={true} type="saveBtn" onClick={AddUnit}>
          <IoMdAddCircleOutline />
          <span className='text-sm'>{t("price.addList")}</span>
        </MwButton>
                </CardHeadeWithActions>
      
     {/* Header */}
 
<div
  className="grid grid-cols-12 gap-3 px-4 mt-2 bg-slate-200 font-bold text-slate-500 text-sm rounded-t-2xl justify-between items-center border-t py-4 hover:bg-indigo-50 hover:font-bold"
>
  <div className="col-span-1 ">#</div>
  <div className="col-span-1  ">{t("price.listName") || "اسم القائمة"}</div>
  <div className="col-span-8 text-center">{t("price.productsCount") || "عدد المنتجات"}</div>
  <div className="col-span-1 text-center ">{t("requisition.creation_date")}</div>
  <div className="col-span-1 text-center"></div>
</div>

{/* List Rows */}
<div className="h-[calc(100vh-320px)] overflow-y-auto custom-scrollbar">
{currentItems.map((unit, index) => {
  const addItemButton = (
    <button
      onClick={() => {
        handleViewTable(unit.id);
      }}
      className="flex items-center gap-1 text-left px-4 py-2 text-sm text-slate-700 hover:bg-gray-100 w-full"
    >
      <MdOutlinePostAdd className="w-5 h-5" />
      <span>{t("listPrice.add_items")}</span>
    </button>
  );
  console.log("openRowActions === unit.id ",openRowActions )
  return (
    <div
      key={unit.id}
      className={`grid grid-cols-12 gap-3 px-4 py-2 border-t cursor-pointer items-center ${
        openRowActions === null 
          ? 'hover:bg-indigo-50 hover:font-bold' 
          : (openRowActions === unit.id 
            ? 'bg-indigo-50' 
            : '')
      }`}
      onClick={() => handleView(unit.id)}
    >
      <div className="col-span-1  text-slate-600">{index + 1}</div>
      <div className="col-span-1 text-slate-600">
        {unit?.name || t("price.noName") || "اسم غير متوفر"}
      </div>
      <div className="col-span-8 text-center text-slate-600">
        {unit?.items?.length || 0}
      </div>
      <div className="col-span-1 mx-6 text-center text-slate-600">
        {new Date(unit?.created_at).toLocaleDateString("en-EG")}
      </div>
      <div className="col-span-1 flex items-center justify-end gap-2">
       <ActiveIndicator active={unit.is_active} />
         <ActionsDropdown
          isRTL={isRTL}
          addItemButton={addItemButton}
          onView={(id) => {
            handleView(id);
          }}
          onEdit={(id) => {
            handleEdit(id);
          }}
          onDelete={(id, e) => handleDelete(id, e)}
          itemId={unit.id}
          viewText={t("price.view") || "عرض"}
          editText={t("price.edit") || "تعديل"}
          deleteText={t("price.delete") || "حذف"}
          isListOpen={openRowActions === unit.id}
        />
      </div>
    </div>
  );
})}



{totalPages > 1 && (
  <div className="flex justify-center gap-2 mt-4 pb-4">
    <button
      onClick={() => {
        setCurrentPage(1);
        setItemsPerPage(units.length || itemsPerPage);
      }}
      className={`px-3 py-1 rounded ${
        itemsPerPage !== 10 ? 'bg-indigo-600 text-gray-100' : 'bg-slate-200 text-gray-600 hover:bg-slate-300'
      }`}
    >
      {t("common.all") || "الكل"}
    </button>
    
    {totalPages > 5 && currentPage > 3 && (
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        className="px-3 py-1 rounded bg-slate-200 text-gray-600 hover:bg-slate-300"
      >
            {i18n.language === 'ar' ? <MdKeyboardArrowRight size={20} /> : <MdKeyboardArrowLeft size={20} />}

      </button>
    )}
    
    {Array.from({ length: totalPages }, (_, i) => i + 1)
      .filter(number => {
        if (totalPages <= 5) return true;
        if (number === 1 || number === totalPages) return true;
        return Math.abs(currentPage - number) < 3;
      })
      .map((number) => (
        <button
          key={number}
          onClick={() => {
            handlePageChange(number);
            setItemsPerPage(10);
          }}
          className={`px-3 py-1 rounded ${
            currentPage === number && itemsPerPage === 10
              ? 'bg-indigo-600 text-gray-100'
              : 'bg-slate-200 text-gray-600 hover:bg-slate-300'
          }`}
        >
          {number}
        </button>
      ))}
    
    {totalPages > 5 && currentPage < totalPages - 2 && (
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        className="px-3 py-1 rounded bg-slate-200 text-gray-600 hover:bg-slate-300"
      >
           {i18n.language === 'ar' ? <MdKeyboardArrowLeft size={20} /> : <MdKeyboardArrowRight size={20} />}

      </button>
    )}
  </div>
)} 
</div>



      <DeleteConfirmationModal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default OSviewAllListsPrices;
