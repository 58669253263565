import {useState} from 'react'
import ListBrands from '../../components/Products/brand/ListBrands';
import CreateBrand from '../../components/Products/brand/CreateBrand';
import EditBrand from '../../components/Products/brand/EditBrand';

const ProductsBrands = () => {

    const [mode,setMode] = useState('List');
    const [idSelected,setIdSelected] = useState();

    return (
        <>
            <div className="">
                { mode === 'List' && <ListBrands idSelected = {idSelected} setIdSelected = {setIdSelected} setMode={setMode} /> }
                { mode === 'Add' && <CreateBrand setMode={setMode} /> }
                { (mode === 'Edit' && idSelected) && <EditBrand id={idSelected} setMode={setMode} /> }
            </div>
        </>
    )
}

export default ProductsBrands