import React, { useEffect, useState } from 'react'
import axios from "axios";
import { useParams } from 'react-router-dom';
import {BiSearch} from 'react-icons/bi'

import { MwInputText } from '../ui';
import { useCurrent } from '../../hooks';
import InvListBasic from './InvListBasic';
import { useTranslation } from 'react-i18next';
const SearchQuote = ({className,onChangeSearch=()=>{},isOpen=true}) => {
    const params = useParams();
    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
    const [term,setTerm] = useState();
    const [dataResult,setDataResult] = useState([]);
    const [show,setShow] = useState(false);
    const [loading,setLoading] = useState(false);
    const [t] = useTranslation('global')
    const onChangeHandler = (e) => {
        setTerm(e.target.value);
    };

    const getResult = async () => {
        if (isOpen) {
            setShow(isOpen);
        }
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/quotes/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${term}`);
        const result = response.data;
        setLoading(false);
        console.log('dataResult',result);
        setDataResult(result)
        onChangeSearch(result)

    }

    

    useEffect(() => {
        if(!term){
            setShow(false)
            onChangeSearch([])
        }
        const timeout = setTimeout(() => {
            if(term && isOpen){
                getResult();
            }else if (term && !isOpen) {
                 getResult();
                setShow(false);
            }
        }, 1000);
        
        return () =>{
            clearTimeout(timeout);
        }
    },[term, isOpen])

    return (
            <div className=' flex-1'>
                <div onClick={() => term &&  isOpen && setShow(!show)}  className={`relative ${show && 'border-t border-s border-e rounded-lg'}`}>
                    <BiSearch className='absolute top-2 start-3' size={16}/>
                    <MwInputText 
                        classNameInput={`bg-slate-50 rounded-lg ${className}`}
                        placeholder = {t('DashBoard.searchQuote')}
                        value = {term}
                        onChange = {onChangeHandler}
                        
                        />
                </div>
                {  show && isOpen && 
                    <div className={`absolute z-50 top-8 end-2  w-3/6  overflow-y-auto h-48 border-b border-s border-e  bg-slate-50 border-0 rounded-b-lg opacity-95 ${'block'}`}>
                        <InvListBasic loading={loading} data={dataResult} />
                    </div> 
                }
                
            </div>
    )
}

export default SearchQuote