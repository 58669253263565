import {useState,useEffect} from 'react'
import { useCurrent, usePost } from '../../../hooks';
import {langObjType,objLangTextHandler} from '../../../utils/global'
import { MwButton } from '../../ui';
import CardHeadeWithActions from '../../ui/CardHeadeWithActions';
import TagForm from './TagForm';
import { useTranslation } from 'react-i18next';

const CreateTag = ({setMode}) => {
    const {currentLangList,currentTenantId,currentCompanyId,currentLangId} = useCurrent();
    const [formLang,setFormLang] = useState(currentLangId);
    const [errors,setErrors] = useState({});
  const [t,i18n ] = useTranslation('global')
    const {data:resultPost,loading:loadingPost,error:errorPost,postData} = usePost();

    // Start Form state //////
    const [isActive,setIsActive] = useState(true);
    const [tagName,setTagName] = useState(langObjType());
    const [tagDesc,setTagDesc] = useState(langObjType());

    const formValidate = () => {
        let errorObj = {};
        setErrors('');
        if(!tagName[currentLangId]){
            errorObj = { ...errorObj, [`tagName.${currentLangId}`] : t('product.Tagentered') };
            setFormLang(currentLangId)
        }

        if(Object.keys(errorObj).length !== 0 ){
            setErrors(errorObj)
            return true
        }
        return false;
    }

    const submitHandler = () => {
       // e.preventDefault();
        
        const _data = {
            tagName: objLangTextHandler(tagName),
            tagDesc: objLangTextHandler(tagDesc),
            isActive,
            tenantId:currentTenantId,
            companyId:currentCompanyId
        }
        
        if(formValidate()) return ;
        postData(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/tags`,_data);
        setMode('List');
    }

    useEffect(() =>{
        if(resultPost){
            console.log(resultPost);
        }
    },[resultPost])
    return (
        <>
            <div className='flex flex-col gap-3 px-3 text-slate-500 text-sm p-3' >
                <CardHeadeWithActions title={t('product.createNewTag')} >
                            <MwButton type="cancelBtn" inGroup={true} onClick = {()=>setMode('List')} > {t('product.tagmanagement')}  </MwButton>
                            <MwButton type="saveBtn" inGroup={true} onClick={submitHandler} >{t('product.save')}</MwButton>
                </CardHeadeWithActions>
                <TagForm
                        currentLangList = {currentLangList}
                        formLang = {formLang}
                        setFormLang = {setFormLang}
                        errors = {errors}
                        tagName = {tagName}
                        setTagName = {setTagName}
                        tagDesc = {tagDesc}
                        setTagDesc = {setTagDesc}
                        isActive = {isActive}
                        setIsActive = {setIsActive}
                    />
            </div>
        </>
    )
}

export default CreateTag