import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFilePdf, FaPrint, FaFileExcel, FaTimes, FaRegFileExcel } from 'react-icons/fa';
import { MdDelete, MdEditNote } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { getListPrice } from '../../store/ListPriceSlice';
import { MwButton, MwSpinner, NoDataFound } from '../ui';
// import handleConvertToPDF from '../../utils/';
import * as XLSX from 'xlsx';
import { LuLayoutDashboard } from 'react-icons/lu';
import CardHeadeWithActions from '../ui/CardHeadeWithActions';

const CombinedTableStocking = ({ id, setMode }) => {
  const [t, i18n] = useTranslation('global');
  const isRTL = i18n.dir() === 'rtl';
  const printRef = useRef();
  const dispatch = useDispatch();
  
  const { ListData, loading } = useSelector((state) => state.ListPrice);

  useEffect(() => {
    if (id) {
      dispatch(getListPrice(`${process.env.REACT_APP_INV_API_SALES_SERVER_BASE_URL}/inventory/list-price-items/${id}`));
    }
  }, [dispatch, id]);

  const handlePrint = () => {
    if (ListData.length == 0) return
    const printContents = document.getElementById('printable-content').outerHTML;
    const printWindow = window.open('', '_blank');
    printWindow.document.open();
    
    printWindow.document.write(`
        <!DOCTYPE html>
        <html dir="rtl" lang="ar">
          <head>
            <title>إذن مخزني</title>
            <style>
              @page {
                size: A4;
                margin: 1.5cm;
              }
              @media print {
                html, body {
                  width: 100%;
                  height: 100%;
                  margin: 0 !important;
                  padding: 0 !important;
                  overflow: hidden !important;
                }
                body {
                  display: block !important;
                }
                #printable-content {
                  page-break-inside: avoid !important;
                  break-inside: avoid !important;
                  height: 100% !important;
                  max-height: 100% !important;
                  overflow: hidden !important;
                }
              }
              body {
                font-family: 'Arial', sans-serif;
                direction: rtl;
                text-align: right;
                color: #000;
                print-color-adjust: exact;
                -webkit-print-color-adjust: exact;
              }
               ${Array.from(document.styleSheets)
                .map((sheet) => {
                  try {
                    return Array.from(sheet.cssRules)
                      .map((rule) => rule.cssText)
                      .join('');
                  } catch (e) {
                    return '';
                  }
                })
                .join('')}
            </style>
          </head>
          <body>
            <div id="printable-content">
              ${printContents}
            </div>
            <script>
              window.onload = function () {
                window.print();
                window.onafterprint = function () {
                  window.close();
                };
              };
            </script>
          </body>
        </html>
      `);
    printWindow.document.close();
};
  const exportExcel = () => {
    if (ListData && ListData.length > 0) {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(ListData);
      XLSX.utils.book_append_sheet(wb, ws, t("reports.Price_List_Report"));
      XLSX.writeFile(wb, `${t("reports.Price_List_Report")}.xlsx`);
    }
  };

  if (loading) return <MwSpinner />;

  const renderContent = () => {
   

    return (
      <div id="printable-content" ref={printRef} className="bg-slate-50  p-8 h-[calc(100vh-100px)]   ">



        <div className="text-center mb-8 border-b pb-6">
          <span className="text-[16px] font-semibold mb-2 text-gray-600">{t('table.price_list')}</span>
        </div>

        <div className="h-[calc(100vh-100px)]    ">
          <table className="w-full border-collapse print:text-sm">
            <thead>
              <tr className="bg-gray-100 text-[#000000]">
                <th className="border p-3 text-center w-1/12">{t('table.item')}</th>
                <th className="border p-3 text-center w-4/12">{t('table.brand')}</th>
                <th className="border p-3 text-center w-2/12">{t('table.category')}</th>
                <th className="border p-3 text-center w-2/12">{t('table.productPrice')}</th>
              </tr>
            </thead>
            <tbody>
              {ListData.map((item, index) => (
                <tr key={item.id} className="hover:bg-gray-50">
                  <td className="border p-3 text-center text-gray-700">{index + 1}</td>
                  <td className="border p-3 text-center text-gray-700">{item.product_name || '-'}</td>
                  <td className="border p-3 text-center text-gray-700">-</td>
                  <td className="border p-3 text-center text-gray-700">{item.product_sale_price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <  div className='text-xs p-3'>
        <CardHeadeWithActions title={  
                 <div className='flex items-center gap-2 '>
      <LuLayoutDashboard  className="w-6 h-6" /> 
      <span>
      {t('listPrice.managementlists_price')}
      </span>
    </div>
}>
                
                        
                
            
                    <MwButton
                        actionType="button"
                        inGroup={true}
                        type="saveBtn"
                        onClick={()=>setMode('List')}
                    >
                     
                    
                       {t('listPrice.lists_price')}
                    </MwButton>
              
            </CardHeadeWithActions>

            <div className={`bg-slate-50 my-2 rounded-lg text-xs p-3 ${
    !ListData || ListData.length === 0 
        ? 'h-auto' 
        : 'h-[calc(100vh-100px)] overflow-y-scroll custom-scrollbar'
}`}>
            <div className=" flex flex-col my-3  ">

            <div className="mb-6 w-full flex justify-between items-center ">
  {/* مجموعة أدوات التصدير */}
  <div className="flex items-center gap-1">
    <div className="  text-slate-500"></div>
    <div className="  text-slate-500">
      <ul className="flex gap-1">
        <li
          style={{ cursor: "pointer" }}
          onClick={exportExcel}
          className="px-2 py-1 flex items-center gap-1 bg-slate-100 hover:font-bold"
        >
          <FaRegFileExcel />
          <span className="text-sm text-slate-500">{t("reports.Export_Excel")}</span>
        </li>
        {/* <li
          style={{ cursor: "pointer" }}
          onClick={() =>
            handleConvertToPDF({
              lang: "ar",
              elementId: "printable-content",
              fileName: 'price_list.pdf',
            })
          }
          className="px-2 py-1 flex items-center gap-1 bg-slate-100 hover:font-bold"
        >
          <FaFilePdf />
          <span className="text-sm text-slate-500">{t("reports.Save_as_PDF")}</span>
        </li> */}
        <li
          style={{ cursor: "pointer" }}
          onClick={handlePrint}
          className="px-2 py-1 flex items-center gap-1 bg-slate-100 hover:font-bold"
        >
          <FaPrint />
          <span className="text-sm text-slate-500">{t("reports.Print")}</span>
        </li>
      </ul>
    </div>
  </div>

  <div className="inline-flex">
            <MwButton
              actionType="button"
              inGroup={true}
              size="sm"
              type="saveBtn"
              onClick={() => setMode('DetailsLists')}
            >
              <MdEditNote />
              {t('requisition.edit')}/ {t('requisition.add')}
            </MwButton>
          </div>
 
</div>

    

      { 
         !ListData || ListData.length === 0?  
         <div className="h-auto">   
         <NoDataFound msg={t("deleted.no_product")} />
     </div>
         :
    (    <div className="flex-1 h-[calc(100vh-100px)] overflow-y-scroll custom-scrollbar">
        {renderContent()}
      </div> )
      }
    </div>
    </div>
    </div >
  );
};

export default CombinedTableStocking;