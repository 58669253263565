import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { MdDragIndicator } from 'react-icons/md';

const DraggableFormRow = ({ children, index, moveRow, id, productList }) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'FORM_ROW',
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover(item, monitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: 'FORM_ROW',
    item: () => {
        const currentItem = productList[index];
        return {
            id,
            index,
            // احتفظ بالحالة الكاملة للعنصر
            state: {
                productId: currentItem.productId,
                productName: currentItem.productName,
                price: currentItem.price,
                priceUnit: currentItem.priceUnit,
                qty: currentItem.qty,
                qtyDays: currentItem.qtyDays,
                // أي خصائص إضافية مهمة
            }
        };
    },
    collect: (monitor) => ({
        isDragging: monitor.isDragging(),
    }),
});

  dragPreview(drop(ref));

  return (
    <div 
      ref={ref} 
      style={{ opacity: isDragging ? 0.5 : 1 }} 
      data-handler-id={handlerId} 
      className="flex items-center w-auto"
    >
      <div 
        ref={drag} 
        className="cursor-move p-2 hover:bg-slate-200 rounded flex items-center"
      >
        <MdDragIndicator size={20} className="text-slate-600" />
      </div>
      <div className="flex w-full items-center">
        <div className="flex-grow flex items-center">
          {children}
        </div>
      </div>
    </div>
  );
};

export default DraggableFormRow;