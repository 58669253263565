import { TiDeleteOutline } from "react-icons/ti";
import { MwInputText } from "../../components/ui";
 
const DraggableLabel = ({
  productName,
  onChangeSection,  
  deleteItem,
  t
}) => {
  return (
    <div className="w-full mx-4 flex gap-1 items-center">
      <MwInputText 
        onChange={(e) => onChangeSection(e.target.value)}
        value={productName}
        classNameInput="bg-slate-300 text-slate-850 font-bold"
        placeholder={t("invoices.sectionNamePlaceholder")}
      />
      
      <TiDeleteOutline
        onClick={deleteItem}
        size={21}
        title={t("invoices.deleteSection")}
        className="text-red-500 bg-red-100 rounded-lg hover:bg-red-200 transition-colors"
      />
    </div>
  );
};

export default DraggableLabel;