import React, { useState, useEffect } from 'react';
import MwButton from '../ui/MwButton';
import { useCurrent, usePost } from '../../hooks';
import { useTenant } from '../../hooks';
import { FaRegSave } from "react-icons/fa";
import toast, { Toaster } from 'react-hot-toast';

import { AiOutlineDelete } from 'react-icons/ai';
 import { useTranslation } from 'react-i18next';
import CardHeadeWithActions from './../ui/CardHeadeWithActions';
import { LuLayoutDashboard } from 'react-icons/lu';
import ToggleSwitch from '../OsNewWeight/OsToggleSwitch';

const OsCreateListPrice = ({ setMode, setIdSelected,idSelected }) => {
  const { t, i18n } = useTranslation('global');
  const isRTL = i18n.dir() === 'rtl';
  
  const { postData, data, loading, error } = usePost(true);
  const { tenantId } = useTenant();
  const [templateName1, setTemplateName1] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const { currentTenantId, currentCompanyId, currentBranchId } = useCurrent();

  // useEffect لمراقبة data و error
  useEffect(() => {
    if (data?.status === 1) {
      toast.success(t("listPrice.saveSuccess"), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      // تفريغ الحقول بعد الإضافة بنجاح
      setTemplateName1("");
      setIsActive(false);
      setErrorMessages({});
      // يمكنك تغيير حالة العرض هنا إذا رغبت
      // setMode('List');
    } else if (error) {
      toast.error(t("listPrice.saveError"), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
    }
  }, [data, error, setMode, t]);

  const validateForm = () => {
    let errors = {};
    if (!templateName1.trim()) errors.templateName1 = t("listPrice.listNameRequired");
    setErrorMessages(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = async () => {
    if (!tenantId) {
      toast.error(t("listPrice.notFoundTenantId"), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      return;
    }
    if (!validateForm()) {
      toast.error(t("listPrice.fillRequired"), {
        style: {
          backgroundColor: '#e5e7eb',
          color: '#374151',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
        }
      });
      return;
    }
    const payload = {
      tenant_id: currentTenantId,
      company_id: currentCompanyId,
      branch_id: currentBranchId,
      name: templateName1,
      is_active: isActive ? 1 : 0,
    };
    try {
      await postData(`${process.env.REACT_APP_INV_API_SALES_SERVER_BASE_URL}/inventory/list-price`, payload);
      console.log('list price', payload);
      setTimeout(()=>{
        setMode('List');
      },1500)
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleCancel = () => {
    setMode('List');
  };

  return (
    <div className='p-3'>
     <CardHeadeWithActions title={  
                 <div className='flex mb-8 items-center gap-2 '>
      <LuLayoutDashboard  className="w-6 h-6" /> 
      <span className='text-xs'>
      {t('listPrice.managementlists_price')}
      </span>
    </div>
}>              
              
              <MwButton
        actionType="button"
         
        inGroup={true}
        type="saveBtn"
        onClick={handleCancel}
        className="mr-2"
      >
         {t("listPrice.lists_price")}
      </MwButton>
              
            </CardHeadeWithActions>
   
    <div className="bg-white text-xs p-6 rounded-lg shadow-md">
    <Toaster
  position="top-center"
  containerStyle={{
    top: 60, 
  }}
  toastOptions={{
    duration: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    pauseOnHover: true,
    style: {
      direction: "rtl"
    }
  }}
/>
                 
     <div
  className={`w-full flex mb-6 ${isRTL ? 'justify-end' : 'justify-end'}`}
  dir={isRTL ? 'rtl' : 'ltr'}
>
  {isRTL ? (
    <>
      <MwButton
        actionType="button"
        size="sm"
        inGroup={true}
        type="saveBtn"
        onClick={handleSave}
         className="mr-0"
      >
        {loading ? (
          <span>{t("listPrice.saving")}</span>
        ) : (
          <>
            <FaRegSave />
            <span>{t("listPrice.save")}</span>
          </>
        )}
      </MwButton>

     
    </>
  ) : (
    <>
      <MwButton
        actionType="button"
        size="sm"
        inGroup={true}
        type="original-left"
        onClick={handleSave}
       >
        {loading ? (
          <span>{t("listPrice.saving")}</span>
        ) : (
          <>
            <FaRegSave />
            <span>{t("listPrice.save")}</span>
          </>
        )}
      </MwButton>

      <MwButton
        actionType="button"
        size="sm"
        inGroup={true}
        type="deleteBtn"
        onClick={handleCancel}
      >
        <AiOutlineDelete className="ml-1" />
        {t("listPrice.cancel")}
      </MwButton>
    </>
  )}
</div>
      <label htmlFor="templateName" className="block text-sm text-slate-600 mb-1">
        {t("listPrice.listName")}
      </label>
      <div className="gap-4 flex justify-between mb-4">
        <div className="block w-[50%]">
          <input
            id="templateName"
            type="text"
            value={templateName1}
            onChange={(e) => setTemplateName1(e.target.value)}
            className="w-full p-2 border text-slate-500 border-gray-300 rounded-md text-center"
          />
          {errorMessages.templateName1 && <p className="text-red-500 text-sm">{errorMessages.templateName1}</p>}
        </div>
        <ToggleSwitch
          id="isActive"
          label={t("listPrice.active") || "نشط"}
          checked={isActive}
          onChange={(e) => setIsActive(e.target.checked)}
        />
      </div>
    </div>
    </div>
  );
};

export default OsCreateListPrice;
