import React from 'react'
import {QRCodeSVG} from 'qrcode.react';
import {  useTranslation } from 'react-i18next';

const InvoiceHeader = ({
    logo,
    companyName,
    companyCR,
    companyDesc,
    vatNo,
    inv_no,
    inv_date,
    inv_date_due,
    companyAddress,
    qrText,
    invoiceSettings
}) => {

    const [t] = useTranslation('global')
    console.log('invoiceSettings',invoiceSettings);
    return (
        <div className='header'>
            <div className='flex items-center justify-between py-10'>
            {invoiceSettings?.SHOW_LOGO_ON_PRINT === 'true' && <div><img src={logo} className='w-64' /></div> }

                <div className={`flex w-96`}>
                    <div className='ps-2 '>
                        <div className='font-bold py-2 text-lg' >{companyName}</div>
                        <div className='flex flex-col gap-1 text-sm text-gray-500'>
                            <div>{companyDesc}</div>
                            <div className='flex items-center justify-between gap-3'>
                                <span>   سجل تجاري:</span>
                                <span className='font-bold'>{companyCR}</span>
                                <span>:.C.R </span>
                            </div>
                            <div className='flex items-center justify-between gap-3'>
                                <span>  الرقم الضريبي:</span>
                                <span className='font-bold'>{vatNo}</span>
                                <span>:VAT NO</span>
                            </div>
                            <div >{companyAddress}</div>
                        </div>
                    </div>
                </div>
                <div className='flex'>
                    <QRCodeSVG size={120}  value={qrText}  />
                    </div>
            </div>
                   
        </div>
    )
}

export default InvoiceHeader