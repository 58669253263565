import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { MwSpinnerButton, NoDataFound } from '../ui'
import { useDelete, useInvoice } from '../../hooks';
import parse from 'html-react-parser';
import ActionsDropdown from '../Common/ActionsDropdown';
import { FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import DeleteConfirmationModal from '../Common/OsDeleteConfirmationModal ';
import toast, { Toaster } from 'react-hot-toast';

const InvListBasic = ({
    data,
    loading=true,links=[],
    paginateHandler = (url)=>{} ,
    withBorder=true,
    withSummary=false,
}) => {
    const params = useParams();
        const [deleteModalOpen, setDeleteModalOpen] = useState(false);
        const [selectedItemId, setSelectedItemId] = useState(null);
        const { deleteItem,data: deleteResponse,loading: isDeleting } = useDelete();
            const [localData, setLocalData] = useState(data);
        

    
    const {formatter} = useInvoice() 
       const navigate = useNavigate();
       const [t,i18next] = useTranslation('global')
       const isRTL = i18next.dir() === 'rtl';

       const handleDeleteClick = (id) => {
        console.log('تم استدعاء handleDeleteClick مع المعرف:', id);
        setSelectedItemId(id);
        setDeleteModalOpen(true);
    };

    useEffect(() => {
            if (data) {
              setLocalData(data);
            }
          }, [data]);
    
        useEffect(() => {
            if (deleteResponse && selectedItemId) {
               setLocalData(prevData => prevData.filter(item => item.id !== selectedItemId));
              setSelectedItemId(null);
            }
          }, [deleteResponse, selectedItemId]);

    const deleteHandeler = (id) => {
        console.log('تم استدعاء deleteHandeler مع المعرف:', id);
        const url = `${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/delete/${id}`;
        console.log('عنوان URL للحذف:', url);
        toast.loading('جاري الحذف...', { id: 'deleteToast' });
        deleteItem(url)
            .then((response) => {
                console.log('استجابة الحذف:', response);
                toast.success('تم حذف عرض السعر بنجاح', { id: 'deleteToast' });
            })
            .catch((error) => {
                console.log('خطأ في الحذف:', error);
                toast.error('حدث خطأ أثناء الحذف', { id: 'deleteToast' });
            });
    };

    
    return (
        <>
            <Toaster
          position="top-center"
          containerStyle={{
            top: 60, 
          }}
          toastOptions={{
            duration: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            pauseOnHover: true,
            style: {
              direction: "rtl"
            }
          }}
        />
            {!loading ? 
            <div className={`flex-1 ${ withBorder && ' border rounded-xl  ' }` }>
                {localData && localData?.length > 0 ? <ul>
                    <li className={`flex justify-between p-3 text-xs border-b bg-slate-100 `}>
                        { !withSummary && <div className='w-8 px-2 text-center'>#</div>}
                        <div className='w-20  text-center'>رقم الفاتورة</div>
                        <div className='flex-1'>اسم المورد</div>
                        <div className='w-24   text-center'>مبلغ الفاتورة</div>
                        { !withSummary && <div className='w-32 px-1  text-center'>تاريخ الفاتورة</div> }
                        { <div className='w-24 px-1  text-center'> الحالة </div>}
                        { <div className='w-12 px-1  text-center'> الاجراءات </div>}
                    </li>
                    <div className={ `${ !withSummary && 'h-[calc(100vh-225px)]'} overflow-auto pb-3`}>
                    {localData.map((result, index) => (
    <div key={result.id} className="flex justify-between items-center border-b hover:bg-indigo-50 hover:font-bold p-2 text-xs">
        <Link to={`/${params.tenant}/invoices/show/${result.id}/${result.company_id}/${result.branch_id}`} className="flex-1">
            <div className="flex justify-between">
                { !withSummary &&  <div className='w-8 px-2 text-center'>{index+1}</div> }
                <div className='w-20 text-center'>{result.inv_no}</div>
                <div className='flex-1 flex justify-between items-center'>
                    <span>{result.customer_name || 'مورد عام'}</span>
                </div>
                <div className='w-24 flex items-center text-center'>{formatter.format(result.net_amount)}</div>
                { !withSummary && <div className='w-32 px-1 flex items-center text-center'>{result.inv_date.substring(0,16)}</div> }
                <div className='w-24 flex flex-col gap-1 items-center justify-center'>
                    {(!result.is_returned && result.is_paid) && <span className='w-full text-center px-2 pb-1 rounded-lg bg-green-200'>مدفوعة</span>}
                    {(!result.is_returned && !result.is_paid) && <span className='w-full bg-slate-200 text-center px-2 pb-1 rounded-lg'>غير مدفوعة</span>}
                    {result.is_returned && (
                        <div className='flex gap-2 px-3'>
                            <span className='text-center px-2 pb-1 rounded-lg bg-orange-100'>مرتجع</span>
                            <Link to={`/${params.tenant}/invoices-returns/show/${result.id}/${result.company_id}/${result.branch_id}`}>
                                <span className='text-center px-2 pb-1 rounded-lg bg-indigo-100'>عرض</span>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </Link>
        <div className='w-12 px-1 text-center'>
            <ActionsDropdown
                isRTL={isRTL}
                itemId={result.id}
                onView={() => navigate(`/${params.tenant}/invoices/show/${result.id}/${result.company_id}/${result.branch_id}`)}
                onEdit={() => navigate(`/${params.tenant}/invoices/edit/${result.id}`)}
                onDelete={() => handleDeleteClick(result.id)}
                viewText={t("common.view")}
                editText={t("common.edit")}
                deleteText={t("common.delete")}
                viewIcon={<FaEye className="inline-block mr-1" />}
                editIcon={<FaEdit className="inline-block mr-1" />}
                deleteIcon={<FaTrash className="inline-block mr-1" />}
            />
        </div>
    </div>
))}

                    </div>
                    { links.length >0 &&
                        <div id="dataLinks" className="list-none  flex gap-3 border-t pt-3 px-5 cursor-default">
                        {
                            links.length > 3 && 
                            links.map((item,i) => <li key={i} className='hover:font-bold' onClick={()=>item?.url && paginateHandler(item?.url.replace('http','https'))} >{ parse(item.label)}</li>)
                        }
                        </div> 
                    }
                </ul> : <NoDataFound msg='لا توجد فواتير ' />  }
            </div> : <div className='flex-1'>
                        <MwSpinnerButton withLabel={false} isFullCenter={true} />
                    </div> }

                    <DeleteConfirmationModal
    isOpen={deleteModalOpen}
    onClose={() => setDeleteModalOpen(false)}
    onConfirm={() => {
        deleteHandeler(selectedItemId);
        setDeleteModalOpen(false);
    }}
    loading={isDeleting}
/>
        </>
    )
}

export default InvListBasic