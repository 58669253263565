import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const token = localStorage.getItem('token');

export const getListPrice = createAsyncThunk(
    'listprice/getallLists',
    async (url, { rejectWithValue }) => {
      try {
        console.log('tenantId,company_id,branch_id',url)
        const response = await axios.get(url,{
          headers:{
              'Authorization': `Bearer ${token}` 
          }});
        console.log('response price:', response );
        return response.data.data; // استخدام data من الاستجابة مباشرة
      } catch (error) {
        return rejectWithValue(error.response.data); // لو حصل خطأ
      }
    }
  );

const ListPriceSlice = createSlice({
  name: 'listprice',
  initialState: {
    loading: false,
    ListData: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
       .addCase(getListPrice.pending, (state) => {
        state.loading = true;
      })
      .addCase(getListPrice.fulfilled, (state, action) => {
        state.loading = false;
        state.ListData = action.payload;
      })
      .addCase(getListPrice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
  },
});

// 5. تصدير الـ Reducer
export default ListPriceSlice.reducer;