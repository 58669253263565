import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import {BiSolidMessageSquareAdd} from 'react-icons/bi'
import withGuard from "../../utils/withGuard";
import InvListBasic from "../../components/quote/InvListBasic";
import { useCurrent, useFetch } from "../../hooks";
import { MwButton } from "../../components/ui";
import SearchQuote from "../../components/quote/SearchQuote";
import {RiQuoteText} from 'react-icons/ri'
import { useTranslation } from "react-i18next";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const ManageQuotes = () => {
    const params = useParams();
    const [invoices,setInvoices] = useState();
    const [loading,setLoading] = useState(true);
    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
     const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(13);
    const { t, i18n } = useTranslation('global');
     const baseUrl = `${process.env.REACT_APP_INV_API_SALES_SERVER_BASE_URL}/taxes/${currentTenantId}/${currentCompanyId}/${currentBranchId}`;
    const { data: taxesData, loading: taxesLoading } = useFetch(baseUrl);
    console.log("localstoragetaxesDataot",taxesData)


    const totalPages = invoices ? Math.ceil(invoices.length / itemsPerPage) : 0;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = invoices ? invoices.slice(indexOfFirstItem, indexOfLastItem) : [];



    const invoicesHandler = async () => {
        const response = await axios.get(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/quotes/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
        setInvoices(response?.data.data);
        setLoading(false);
        
    }

    const onChangeSearch = (newValue) => {
        console.log(newValue);
        if (newValue && newValue.length > 0) {
            setInvoices(newValue);
        } else {
             invoicesHandler();
        }
    }
    useEffect(()=>{
        invoicesHandler();
    },[])

    useEffect(() => {
      if (taxesData) {
        console.log("localstoragetaxesData",taxesData)
        localStorage.setItem("taxesData", JSON.stringify(taxesData));
      }
    }, []);
    
    
    return (
        <>
            <div className='flex justify-between items-center pb-3'>
            
                <div className=' flex gap-1 text-sm text-gray-400 '>
                    <RiQuoteText size={18} />
                    {t("quotes.manageQuotes")}</div>
                <Link to={`/${params.tenant}/quotes/create`}>
                    <MwButton inGroup={true} type='saveBtn'  >
                        <BiSolidMessageSquareAdd/>
                        {t("quotes.createNewQuote")}
                        </MwButton>
                </Link>
            </div>
            <hr />
            <div className="bg-slate-50 text-slate-500 pt-3 rounded-lg text-xs h-full">
  <div className="flex items-center justify-center">
    <div className="flex-1 px-3 font-bold">{t("quotes.quotesList")}</div>
    <SearchQuote className="bg-white" onChangeSearch={onChangeSearch} isOpen={false} />
  </div>
  <div className="my-3 ">
    <InvListBasic loading={loading} withBorder={false} data={currentItems} />
  </div>
  {totalPages > 1 && (
    <div className="flex justify-center gap-2 mt-4 pb-4">
      <button
        onClick={() => {
          setCurrentPage(1);
          setItemsPerPage(invoices?.length || itemsPerPage);
        }}
        className={`px-3 py-1 rounded ${
          itemsPerPage !== 13 ? "bg-indigo-600 text-white" : "bg-slate-200 hover:bg-slate-300"
        }`}
      >
        {t("common.all") || "الكل"}
      </button>

      {totalPages > 5 && currentPage > 3 && (
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          className="px-3 py-1 rounded bg-slate-200 hover:bg-slate-300"
        >
          {i18n.language === "ar" ? (
            <MdKeyboardArrowRight size={20} />
          ) : (
            <MdKeyboardArrowLeft size={20} />
          )}
        </button>
      )}

      {Array.from({ length: totalPages }, (_, i) => i + 1)
        .filter((number) => {
          if (totalPages <= 5) return true;
          if (number === 1 || number === totalPages) return true;
          return Math.abs(currentPage - number) < 3;
        })
        .map((number) => (
          <button
            key={number}
            onClick={() => {
              setCurrentPage(number);
              setItemsPerPage(13);
            }}
            className={`px-3 py-1 rounded ${
              currentPage === number && itemsPerPage === 13
                ? "bg-indigo-600 text-white"
                : "bg-slate-200 hover:bg-slate-300"
            }`}
          >
            {number}
          </button>
        ))}

      {totalPages > 5 && currentPage < totalPages - 2 && (
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          className="px-3 py-1 rounded bg-slate-200 hover:bg-slate-300"
        >
          {i18n.language === "ar" ? (
            <MdKeyboardArrowLeft size={20} />
          ) : (
            <MdKeyboardArrowRight size={20} />
          )}
        </button>
      )}
    </div>
  )}
</div>

        </>
    )
}

export default withGuard(ManageQuotes)