import React, { useEffect, useState } from 'react'
import {BiSearch} from 'react-icons/bi'
import { MwButton, MwInputText, MwSelector, MwSpinnerButton, TagList } from './ui';
import axios from 'axios';
import { useCurrent, useFetch, usePost } from '../hooks';
import { Link, useParams } from 'react-router-dom';
import InvListBasic from './invoice/InvListBasic';
import { CiFilter } from "react-icons/ci";
import {getAllSalesman} from '../services/salesmanService';
import {getAllVendors} from '../services/vendorService';
import { useTranslation } from 'react-i18next';

const SearchInvoice = ({
    className,
    isOpen=true,
    withFilter=false,
    withFullResults=false,
    onChangeSearch=()=>{},
    setLoadingCallback=()=>{}
}) => {
    const params = useParams();
    const {currentTenantId,currentCompanyId,currentBranchId,currentLangId} = useCurrent();
    const [term,setTerm] = useState();
    const [dataResult,setDataResult] = useState([]);
    const [show,setShow] = useState(false);
    const [loading,setLoading] = useState(false);
    const [t] = useTranslation('global')


    // Filter states
    const {data:filterResult,loading:filterLoading,postData:getFilterResult} = usePost();
    const {data:usersResult,loading:usersLoading} = useFetch(`${process.env.REACT_APP_Auth_API_BASE_URL}/users/tenant/${currentTenantId}/${currentCompanyId}`);
    const [filterState,setFilterState] = useState({
        invStartDate:'',
        invEndDate:'',
    });
    const [tagsList,setTagsList] = useState([]);
    const _listReturnStatus = [
        {
            label:'الكل',
            value:'all',
            lang: 'ar'
        },
        {
            label:'الفواتير المرتجعة',
            value:'true',
            lang: 'ar'
        },
        {
            label:'الفواتير المكتملة',
            value:'false',
            lang: 'ar'
        },
    ];
    const [retrunStatusSelected,setReturnStatusSelected] = useState();
    const _listPaidStatus = [
        {
            label:'الكل',
            value:'all',
            lang: 'ar'
        },
        {
            label:'المدفوعة',
            value:'true',
            lang: 'ar'
        },
        {
            label:'غير المدفوعة',
            value:'false',
            lang: 'ar'
        },
    ];
    const [paidStatusSelected,setPaidStatusSelected] = useState();
    const [customers,setCustomers] = useState([]); 
    const [salesmans,setSalesmans] = useState([]); 
    const [users,setUsers] = useState([]);

    const [customerSelected,setCustomerSelected] = useState();
    const [salesmanSelected,setSalesmanSelected] = useState();
    const [userSelected,setUserSelected] = useState();
    //----------------------------------------------------------------

    const onChangeHandler = (e) => {
        setTerm(e.target.value);
    };

    const getResult = async () => {
        if(!withFullResults){
            isOpen && setShow(true);
        }
        setLoading(true);
        setLoadingCallback(true);
        const response = await axios.get(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${term}`);
        const result = response.data;
        setLoading(false);
        console.log('dataResult',result);
        setDataResult(result)
        onChangeSearch(result)

    }
    // Filter Handler
    const getcustomerHandler = async () => {
        const res = await getAllVendors(currentTenantId,currentCompanyId,currentBranchId)
        setCustomers([{
            _id: 'all',
            fullname: [
                {
                    text: 'الكل',
                    lang: 'ar'
                },
                {
                    text: 'ALL',
                    lang: 'en'
                },
            ]
        },...res]);
    }

    const getSalesmanHandler = async () => {
        const res = await getAllSalesman(currentTenantId,currentCompanyId,currentBranchId);
        setSalesmans([{
            _id: 'all',
            fullname: [
                {
                    text: 'الكل',
                    lang: 'ar'
                },
                {
                    text: 'ALL',
                    lang: 'en'
                },
            ]
        },...res]);
    }
    const filterHandler = () =>{
        setLoadingCallback(true);
        const _data = {
            tenant_id : currentTenantId,
            company_id : currentCompanyId,
            branch_id : currentBranchId,
            customer_id: customerSelected?.value === 'all' ? null : (customerSelected?.value || null),
            salesman_id: salesmanSelected?.value  === 'all' ? null : (salesmanSelected?.value || null),
            created_by: userSelected?.value  === 'all' ? null : (userSelected?.value || null),
            inv_start_date: filterState.invStartDate,
            inv_end_date: filterState.invEndDate,
            is_paid: paidStatusSelected?.value === 'all' ? null : paidStatusSelected?.value ,
            is_returned: retrunStatusSelected?.value === 'all' ? null : retrunStatusSelected?.value ,
            inv_tags: JSON.stringify(tagsList)
        }
        getFilterResult(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/filterBy`,_data);
    } 
    useEffect(() => {
        if(filterResult){
            onChangeSearch(filterResult);
            setLoadingCallback(false);
        }
    },[filterResult])

    useEffect(() => {
        if(usersResult){
            const _list = usersResult?.data.map(user => ({
                label: user.name,
                value: user._id,
                lang: currentLangId
            }));
            setUsers(_list);
            console.log('users',users)
        }
    },[usersResult]);

    useEffect(() => {
        if(withFilter){
            getcustomerHandler();
            getSalesmanHandler();
        }
    },[])
    //----------------------------------------------------------------

    useEffect(() => {
        if(!term){
            setShow(false)
        }
        const timeout = setTimeout(() => {
            if(term){
                getResult();
            }
        }, 1000);
        
        return () =>{
            clearTimeout(timeout);
        }
    },[term])

    return (
            <div className='flex flex-1'>
                <div className={`relative flex-1 flex flex-col gap-1 justify-between`}>
                <div className="border rounded-lg p-3 mb-3">

                <div className="flex gap-1 mb-3 ">
    <BiSearch size={16}/>
    <span>{t("invoices.searchInvoice")}</span>
  </div>
                    <div onClick={() => term && setShow(!show)}  className={` relative ${show && ' border-t border-s border-e rounded-lg'}`}>
                        <BiSearch className='absolute top-2 start-3 z-10' size={16}/>
                        <MwInputText 
                            classNameInput={`bg-slate-50 rounded-lg ${className}`}
                            placeholder = 'ادخل رقم الفاتورة...'
                            value = {term}
                            onChange = {onChangeHandler}
                            
                            />
                        {/* <MwButton type='secondary' size='sm' classNameCustom=' absolute top-1 end-1'><BiSearch size={16}/></MwButton> */}
                    </div>
                    </div>
                    {
                        withFilter &&
                        <div className="border rounded-lg p-3 mb-5">

                        <div className=' flex-1 flex  flex-col gap-3  items-start '>
                            <div className='flex gap-1'>
                                <CiFilter size={16}/>
                                <span>التصفية</span>
                            </div>
                            <div className='w-full'>
                                <MwInputText
                                    inputType='date'
                                    label='من تاريخ'
                                    value={filterState.invStartDate}
                                    onChange={(e)=>setFilterState({...filterState,invStartDate:e.target.value})}
                                    />
                            </div>
                            <div className='w-full'>
                                <MwInputText
                                    inputType='date'
                                    label='الي تاريخ'
                                    value={filterState.invEndDate}
                                    onChange={(e)=>setFilterState({...filterState,invEndDate:e.target.value})}
                                    />
                            </div>
                            <div className='w-full'>
                                <TagList
                                    label={`العلامات`}
                                    withLabel={true}
                                    tagList={tagsList}
                                    setTagList={(value)=>setTagsList(value)}
                                    />
                            </div>
                            <div className='w-full relative z-50'>
                                <MwSelector
                                    label={`المرتجعة / غير المرتجعة`}
                                    initalValue={`عرض الجميع`}
                                    _data={_listReturnStatus}
                                    selectedItem={retrunStatusSelected}
                                    setSelectedItem={setReturnStatusSelected}
                                    dataType={`labelValue`}
                                    withSearch={false}
                                    withAddNew={false}
                                    />
                            </div>
                            <div className='w-full  relative z-40'>
                                <MwSelector
                                    label={`المدفوعة / غيرالمدفوعة`}
                                    initalValue={`عرض الجميع`}
                                    _data={_listPaidStatus}
                                    selectedItem={paidStatusSelected}
                                    setSelectedItem={setPaidStatusSelected}
                                    dataType={`labelValue`}
                                    withSearch={false}
                                    withAddNew={false}
                                    />
                            </div>
                            <div className='w-full relative z-30'>
                                <MwSelector
                                    label={`العميل`}
                                    initalValue={`عرض الجميع`}
                                    _data={customers}
                                    selectedItem={customerSelected}
                                    setSelectedItem={setCustomerSelected}
                                    dataType={`customer`}
                                    withSearch={false}
                                    withAddNew={false}
                                    />
                            </div>
                            <div className='w-full relative z-20'>
                                <MwSelector
                                    label={`المندوب`}
                                    initalValue={`عرض الجميع`}
                                    _data={salesmans}
                                    selectedItem={salesmanSelected}
                                    setSelectedItem={setSalesmanSelected}
                                    dataType={`salesman`}
                                    withSearch={false}
                                    withAddNew={false}
                                    />
                            </div>
                            <div className='w-full relative z-10'>
                                { !usersLoading ? <MwSelector
                                    label={`المستخدم / الكاشير`}
                                    initalValue={`عرض الجميع`}
                                    _data={users}
                                    selectedItem={userSelected}
                                    setSelectedItem={setUserSelected}
                                    dataType={`labelValue`}
                                    withSearch={false}
                                    withAddNew={false}
                                    /> : <MwSpinnerButton withLabel={false}/>
                                }
                            </div>
                            <div className='w-full pt-5'>
                                <MwButton type='secondary' onClick={filterHandler} classNameCustom='w-full' >عرض النتائج</MwButton>
                            </div>
                        </div>
                        </div>
                    }
                    
                {  show && !withFullResults &&
                    <div className={`absolute z-50  top-9 w-3/6 overflow-y-auto h-48 border-b border-s border-e  bg-slate-50 border-0 rounded-b-lg opacity-95 ${'block'}`}>
                        <InvListBasic withSummary={true} withBorder={false} loading={loading} data={dataResult.data} />
                    </div> 
                }
                </div>
                
            </div>
    )
}

export default SearchInvoice