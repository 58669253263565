import  { useEffect, useState } from 'react'
import {MwButton} from '../../components/ui'
import {langObjType,objLangTextHandler} from '../../utils/global'
import {AiOutlineSave} from 'react-icons/ai'
import BaiscInfo from './forms/BaiscInfo'
 import SEO from './forms/SEO'
import ProductLinks from './forms/ProductLinks'
import { useCurrent, usePost } from '../../hooks'
import CardHeadeWithActions from '../ui/CardHeadeWithActions'
import ProductImages from './forms/ProductImages'
import {allowImgTypes} from '../../pages/products/constants'
import { useTranslation } from 'react-i18next'
import StockAndPrice from './forms/StockAndPrice'
import useUploadFile from './../../hooks/useUploadFile';

const CreateProduct = ({onHide,onProductCreated,setReloadProductList,setMode}) => {
    const [t,i18n ] = useTranslation('global')

    const [tabActive,setTabActive] = useState('TabOne'); // tabs [ TabOne , TabTwo ]
    const [errors,setErrors] = useState({});
    const {currentTenantId,currentCompanyId,currentLangId} = useCurrent();
    const {data:resultPost,postData} = usePost();
    // Start Form state //////
    const [productCode,setProductCode] = useState('');
    const [productBarcode,setProductBarcode] = useState('');
    const [productFactoryCode,setProductFactoryCode] = useState('');
    const [productName,setProductName] = useState(langObjType());
    const [productDesc,setProductDesc] = useState(langObjType());
    const [productPrice,setProductPrice] = useState('');
    const [productTypeSelected,setProductTypeSelected] = useState();
    // const [productMetadata,setProductMetadata] = useState('');
    const [isActive,setIsActive] = useState(true);
    const [isShowOnWebsite,setIsShowOnWebsite] = useState(false);
    const [qty,setQty] = useState(0);
    const [cost,setCost] = useState();
    const [productMetaKeywords,setProductMetaKeywords] = useState(langObjType());
    const [productMetaDesc,setProductMetaDesc] = useState(langObjType());
    const [isStock,setIsStock] = useState(false);
    const [codeInStock,setCodeInStock] = useState();
    const [weightProduct,setWeightProduct] = useState();
    const [sizeProduct,setSizeProduct] = useState();
    const [uomTemplateSelected,setTemplateUomSelected] = useState();
    const [uomSaleSelected,setUomSaleSelected] = useState();
    const [uomPurchaseSelected,setUomPurchesSelected] = useState();

    // Product Links state
    const [genProductCode,setGenProductCode] = useState();
    const [productCategorySelected,setProductCategorySelected] = useState([]);
    const [productBrandSelected,setProductBrandSelected] = useState();
    const [productTags,setProductTags] = useState([]);
    // Product Images
    const [productImages,setProductImages] = useState([]);
    const {location:productHomePic,loading:loadingUploadHomePic,handleUpload:handleHomePicUpload} = useUploadFile(allowImgTypes,`products-${currentTenantId}`,'image')
    const {location:productImagesItem,loading:loadingUploadProductImages,handleMultiUploads:handleImagesItemUpload} = useUploadFile(allowImgTypes,`products-${currentTenantId}`,'image')
    const {resultdelete,loadingDeleting,handleDelete} = useUploadFile()

    useEffect(()=>{
        if(productImagesItem){
            setProductImages((state) => [...state,productImagesItem]);
            console.log('productImages=>',productImages);
        }
    },[productImagesItem])

    const cancelHandle = () => {
        onHide();
    }

    const saveHandler = async (e) => {
        e.preventDefault(true);
        let errorObj = {};
        if(!productName[currentLangId]){
            errorObj = { ...errorObj, [`productName.${currentLangId}`] : 'You must specify a product name'};
            //setCurrentLang(defaultLang)
        }
        if(!productTypeSelected){
            errorObj = {...errorObj, productType:' Product type is required  '}
            productName[currentLangId] && setTabActive('TabOne');
        }
        if(!productPrice){
            errorObj = {...errorObj, price:'You must specify a product price'}
            productName[currentLangId] && setTabActive('TabTwo');
        }else{
            if( isNaN(parseFloat(productPrice))){
                errorObj = {...errorObj, price:' Price is not a number  '}
                productName[currentLangId] && setTabActive('TabTwo');
            }
        }
        if(!cost){
            errorObj = {...errorObj, cost:'You must specify a product cost'}
            productName[currentLangId] && setTabActive('TabTwo');
        }else{
            if( isNaN(parseFloat(cost))){
                errorObj = {...errorObj, cost:' cost is not a number  '}
                productName[currentLangId] && setTabActive('TabTwo');
            }
        }
        
        if(Object.keys(errorObj).length !== 0 ){
            setErrors(errorObj)
            console.log('errors',errors)
            return
        }
        onHide && setReloadProductList(false)
        const _data = {
            productName : objLangTextHandler(productName) ,
            productDesc :objLangTextHandler(productDesc),
            productMetaKeywords :objLangTextHandler(productMetaKeywords),
            productMetaDesc :objLangTextHandler(productMetaDesc),
            productTags: JSON.stringify(productTags),
            productCategory: productCategorySelected?.map((item) => item['value'] ),
            productBrand:productBrandSelected?.value || null,
            productType: productTypeSelected?.value ,
            productHomePic: productHomePic || null,
            productImages: productImages,
            productCode,
            productBarcode,
            productFactoryCode,
            codeInStock,
            weightProduct,
            sizeProduct,
            price:productPrice,
            qty:qty,
            cost: cost,
            uomTemplate:{
                id : uomTemplateSelected?.data.id,
                name : uomTemplateSelected?.data.name,
            },
            uomSale:{
                id: uomSaleSelected?.data.id,
                name: uomSaleSelected?.data.name,
                short_name: uomSaleSelected?.data.short_name,
                rate: uomSaleSelected?.data.rate,
            },
            uomPurchase:{
                id: uomPurchaseSelected?.data.id,
                name: uomPurchaseSelected?.data.name,
                short_name: uomPurchaseSelected?.data.short_name,
                rate: uomPurchaseSelected?.data.rate,
            },
            isStock,
            isShowOnWebsite,
            isActive,
            tenantId:currentTenantId,
            companyId : currentCompanyId
        };
        console.log(_data);
        postData(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}`, _data);
        // const addprod = await addProduct(_data);
    }

    useEffect(()=>{
        if(resultPost){
            if(resultPost.status === 1){
                localStorage.setItem('isProductsUpdated','true')
                onHide && cancelHandle();
                onHide ? onProductCreated() : setMode('List') ;
            }else if(resultPost.status === 0){
                setErrors( { ...errors, [`productName.${currentLangId}`] : resultPost.message });
                productName[currentLangId] && setTabActive('TabOne');
                console.log('Error:',resultPost)
            }else{
            }
        }
    },[resultPost]);

    return (
        <div className='p-2' >
            
            <CardHeadeWithActions title={t('product.addNewProduct')}>
    { onHide && <MwButton inGroup={true} type='cancelBtn' onClick={cancelHandle}>{t('common.cancel')}</MwButton> }
    <MwButton type="cancelBtn" inGroup={true} onClick={() => setMode('List')}>{t('product.manageProducts')}</MwButton>
    <MwButton inGroup={true} type='saveBtn' onClick={saveHandler}>{t('product.save')} <AiOutlineSave size={18}/></MwButton>
</CardHeadeWithActions>

<form>
    <div className='bg-slate-50 rounded-lg text-sm flex flex-col justify-between items-between p-2'>
        <ul className='flex sticky top-8 text-xs'>
            <li 
                onClick={() => setTabActive('TabOne')} 
                className={`cursor-default px-2 py-1 m-1 text-gray-500-500 hover:text-sky-700 ${tabActive === 'TabOne' && 'text-sky-900 border-sky-900 border-b'}`}>
                {t('product.basicInfo')}
            </li>
            <li 
                onClick={() => setTabActive('TabTwo')}  
                className={`cursor-default px-2 py-1 m-1 text-gray-500 hover:text-sky-700 ${tabActive === 'TabTwo' && 'text-sky-900 border-sky-900 border-b'}`}>
                {t('product.warehousePricing')}
            </li>
            <li 
                onClick={() => setTabActive('TabThree')}  
                className={`cursor-default px-2 py-1 m-1 text-gray-500 hover:text-sky-700 ${tabActive === 'TabThree' && 'text-sky-900 border-sky-900 border-b'}`}>
                {t('product.productLinks')}
            </li>
            <li 
                onClick={() => setTabActive('TabFour')}  
                className={`cursor-default px-2 py-1 m-1 text-gray-500 hover:text-sky-700 ${tabActive === 'TabFour' && 'text-sky-900 border-sky-900 border-b'}`}>
                {t('product.seo')}
            </li>
            <li 
                onClick={() => setTabActive('TabFive')}  
                className={`cursor-default px-2 py-1 m-1 text-gray-500 hover:text-sky-700 ${tabActive === 'TabFive' && 'text-sky-900 border-sky-900 border-b'}`}>
                {t('product.productImages')}
            </li>
        </ul>
                    <div id='content-body' >
                        {
                            tabActive === 'TabOne' && <BaiscInfo     
                                                            productName = {productName}
                                                            setProductName = {setProductName}
                                                            productDesc = {productDesc}
                                                            setProductDesc = {setProductDesc}
                                                            isShowOnWebsite = {isShowOnWebsite}
                                                            setIsShowOnWebsite = {setIsShowOnWebsite}
                                                            isActive = {isActive}
                                                            setIsActive = {setIsActive}
                                                            errors = {errors}
                                                            productTypeSelected = {productTypeSelected}
                                                            setProductTypeSelected = {setProductTypeSelected}
                                                            />
                            
                        }
                        {
                            tabActive === 'TabTwo' && <StockAndPrice
                                                            productCode = {productCode}
                                                            setProductCode = {setProductCode}
                                                            productPrice = {productPrice}
                                                            setProductPrice = {setProductPrice}
                                                            productBarcode = {productBarcode}
                                                            setProductBarcode = {setProductBarcode}
                                                            productFactoryCode = {productFactoryCode}
                                                            setProductFactoryCode = {setProductFactoryCode}
                                                            isStock = {isStock}
                                                            setIsStock = {setIsStock}
                                                            codeInStock = {codeInStock}
                                                            setCodeInStock = {setCodeInStock}
                                                            qty = {qty}
                                                            setQty = {setQty}
                                                            cost = {cost}
                                                            setCost = {setCost}
                                                            weightProduct = {weightProduct}
                                                            setWeightProduct = {setWeightProduct}
                                                            sizeProduct = {sizeProduct}
                                                            setSizeProduct  = {setSizeProduct}
                                                            errors = {errors}
                                                            uomSaleSelected = {uomSaleSelected}
                                                            setUomSaleSelected = {setUomSaleSelected}
                                                            uomPurchaseSelected = {uomPurchaseSelected}
                                                            setUomPurchesSelected = {setUomPurchesSelected}
                                                            uomTemplateSelected = {uomTemplateSelected}
                                                            setTemplateUomSelected = {setTemplateUomSelected}
                                                            />
                            
                        }
                        { tabActive === 'TabThree' && 
                        <ProductLinks
                            productCategorySelected = {productCategorySelected}
                            setProductCategorySelected = {setProductCategorySelected}
                            productBrandSelected = {productBrandSelected}
                            setProductBrandSelected = {setProductBrandSelected}
                            productTags = {productTags}
                            setProductTags = {setProductTags}
                            genProductCode = {genProductCode}
                            setGenProductCode = {setGenProductCode}
                            setProductCode = {setProductCode}
                            productCode = {productCode}
                        /> }
                        {
                            tabActive === 'TabFour' && 
                            <SEO
                                productMetaKeywords = {productMetaKeywords}
                                setProductMetaKeywords = {setProductMetaKeywords}
                                productMetaDesc = {productMetaDesc}
                                setProductMetaDesc = {setProductMetaDesc}
                            />
                            
                        }
                        {
                            tabActive === 'TabFive' && 
                            <ProductImages
                                productHomePic = {productHomePic} 
                                loadingUploadHomePic = {loadingUploadHomePic}
                                handleHomePicUpload = {handleHomePicUpload}
                                productImages={productImages}
                                setProductImages={setProductImages}
                                productImagesItem = {productImagesItem}
                                loadingUploadProductImages = {loadingUploadProductImages}
                                handleImagesItemUpload = {handleImagesItemUpload}
                                resultdelete = {resultdelete}
                                loadingDeleting = {loadingDeleting}
                                handleDelete = {handleDelete}
                                />
                            
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CreateProduct