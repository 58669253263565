import React from 'react'
import {TbCategory2} from 'react-icons/tb'
import {SiBrandfolder} from 'react-icons/si'
import {BsFillTagsFill} from 'react-icons/bs'
import {LuLayoutDashboard,LuDollarSign} from 'react-icons/lu'
import { useTranslation } from 'react-i18next'
import { HiOutlineDocumentReport } from 'react-icons/hi'

const ProductsNavbar = ({tabActive,setTabActiive}) => {
    const [t,i18n ] = useTranslation('global')
  const isRTL = i18n.dir() === 'rtl';

    return (
        <>
            <div className='w-full flex mt-3 gap-1  border-b'>
                <div onClick={()=>setTabActiive('Products')} className={`flex-1 flex   gap-1 items-center justify-center rounded-t-lg text-slate-500  p-3 hover:bg-indigo-50 hover:font-bold cursor-default ${tabActive === 'Products' ? ' bg-slate-100 text-indigo-800 font-bold' : 'bg-slate-50'}`}>
                    <LuLayoutDashboard  size={24}/>
                    <hr />
                    <span className='text-sm '> {t('product.productsServices')}  </span>
                </div>
                <div onClick={()=>setTabActiive('Prices')} className={`flex-1 flex   gap-1 items-center justify-center rounded-t-lg text-slate-500  p-3 hover:bg-indigo-50 hover:font-bold cursor-default ${tabActive === 'Prices' ? ' bg-slate-100 text-indigo-800 font-bold' : 'bg-slate-50'}`}>
                    <LuDollarSign   size={24}/>
                    <hr />
                    <span className='text-sm '> {t('product.productsPrices')}  </span>
                </div>
                <div onClick={()=>setTabActiive('Categories')} className={`flex-1 flex   gap-1 items-center justify-center rounded-t-lg text-slate-500  p-3 hover:bg-indigo-50 hover:font-bold cursor-default ${tabActive === 'Categories' ? 'bg-slate-100 text-indigo-800 font-bold' : 'bg-slate-50'}`}>
                    <TbCategory2  size={24}/>
                    <hr />
                    <span className='text-sm  '>{t('product.categories')}</span>
                </div>
                <div onClick={()=>setTabActiive('Brands')} className={`flex-1 flex   gap-1 items-center justify-center rounded-t-lg text-slate-500  p-3 hover:bg-indigo-50 hover:font-bold cursor-default ${tabActive === 'Brands' ? 'bg-slate-100 text-indigo-800 font-bold' : 'bg-slate-50'}`}>
                    <SiBrandfolder  size={24}/>
                    <hr />
                    <span className='text-sm '>{t('product.brands')}</span>
                </div>
                <div onClick={()=>setTabActiive('Tags')} className={`flex-1 flex   gap-1 items-center justify-center rounded-t-lg text-slate-500  py-3 p-3 hover:bg-indigo-50 hover:font-bold cursor-default ${tabActive==='Tags' ? 'bg-slate-100 text-indigo-800 font-bold' :  'bg-slate-50'}`}>
                    <BsFillTagsFill size={24}/>
                    <hr />
                    <span className='text-sm '>   {t('product.tags')} </span>
                </div>
            </div>
        </>
    )
}

export default ProductsNavbar